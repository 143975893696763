import React from "react";

import speciesImage from "./ruspolia_nitidula.jpg";
import audioFile from "./ruspolia_nitidula.mp3";
import audioOscillogram from "./ruspolia_nitidula.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 31.147,
    audioDescription: <>Für sensible Ohren kann das hochfrequente Sirren der Art sehr unangenehm sein. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
  },
  audible: "A",
  daytime: "Nacht/Tag",
  months: "Juli – September",
  to: "ruspolia_nitidula",
  german: "Große Schiefkopfschrecke",
  latin: "Ruspolia nitidula",
  family: "Laubheuschrecken (Tettigoniidae)",
  first: "26. 9. 1954, Hetzendorf",
  districts: "20 (1. – 4., 7., 9. – 23.)",
  description: "Mit rasender Geschwindigkeit hat sich die Große Schiefkopfschrecke innerhalb der letzten Jahre über weite Teile Wiens ausgebreitet, wo sie mittlerweile in nahezu jeder Ruderalbrache ihren lauten, sirrenden Gesang vorträgt und die Geräuschkulisse lauer Sommernächte mitprägt.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
