import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./gryllotalpa_gryllotalpa.jpg";
import audioFile from "./gryllotalpa_gryllotalpa_003_APA.mp3";
import audioOscillogram from "./gryllotalpa_gryllotalpa_003_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 25.431,
    audioDescription: (
      <>
        Der markante, oft minutenlang vorgetragene, mechanische Gesang erinnert am ehesten an einen Ziegenmelker oder mehrere (entfernte) Wechselkröten. Hintergrundstimme: <SpeciesLink to="gryllus_campestris" text="Feldgrille" after="." /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Nacht",
  months: "Mai – Juni",
  to: "gryllotalpa_gryllotalpa",
  german: "Maulwurfsgrille",
  latin: "Gryllotalpa gryllotalpa",
  family: "Maulwurfsgrillen (Gryllotalpidae)",
  first: "28.4.1892, Meidling ",
  districts: "8 (12.–14., 18., 19., 21.–23.)",
  description: "Die Maulwurfsgrille ist der Allrounder unter den Heuschrecken – sie kann ausgezeichnet laufen, schwimmen, fliegen und graben. In Wien sind die Nachweise dünn gesät. Die meisten stammen aus den Wienerwald-Bezirken, wobei die Maulwurfsgrille aufgrund ihrer versteckten Lebensweise vermutlich oft übersehen wird.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Manuel Denner",
};

export default data;
