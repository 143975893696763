import React from "react";

import speciesImage from "./diestrammena_asynamora.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "E",
  daytime: "",
  months: "ganzjährig",
  to: "diestrammena_asynamora",
  german: "Gewächshausschrecke",
  latin: "Diestrammena asynamora",
  family: "Buckelschrecken (Rhaphidophoridae)",
  first: "1909, „Städtischer Reservegarten Kagran“",
  districts: "6 (3., 6., 13., 18., 19., 22.)",
  description: <>Wie ihr Name besagt, bewohnt die Gewächshausschrecke hauptsächlich (sub-)tropische Warmhäuser – und zwar weltweit. Auch in Wien gibt es vereinzelte Nachweise aus diversen Gewächshäusern, wo die Art bzw. die Eier z.&nbsp;B. mit Blumenerde eingeschleppt werden.</>,
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Matthias Kropf",
};

export default data;
