import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./polysarcus_denticauda.jpg";
import audioFile from "./polysarcus_denticauda_005_APA.mp3";
import audioOscillogram from "./polysarcus_denticauda_005_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 50.258728,
    audioDescription: (
      <>
        Der Gesang ist ein langanhaltens hohes Schwirren und unverkennbar dreigeteilt. Die Männchen tragen ihn auch regelmäßig während des Herumspazierens im Gras vor. Hintergrundstimmen: <SpeciesLink to="gryllus_campestris" text="Feldgrille" after=";" /> Mönchsgrasmücke, Amsel. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Tag",
  months: "Mai – Juli",
  to: "polysarcus_denticauda",
  german: "Wanstschrecke",
  latin: "Polysarcus denticauda",
  family: "Sichelschrecken (Phaneropteridae)",
  first: "um 1820, „Kahlengebirge“",
  districts: "2 (19., 23.)",
  description: "Die Wanstschrecke ist in Wien eine seltene Langfühlerschrecke, die aktuell nur für extensiv bewirtschaftete Wienerwaldwiesen im 23. Bezirk bestätigt ist.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
