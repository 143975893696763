import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./pteronemobius_heydenii.jpg";
import audioFile from "./pteronemobius_heydenii_002_APA.mp3";
import audioOscillogram from "./pteronemobius_heydenii_002_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 32.581,
    audioDescription: (
      <>
        Die Art produziert leise, hohe und leicht anschwellende Sirrlaute mit abruptem Ende (ähnlich einem weiter entfernten <SpeciesLink to="tettigonia_caudata" text="Östlichen Heupferd" /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok ). Sie sind in Ostösterreich typische Klangelemente von Feuchtgebieten. Hintergrundstimme: Aaskrähe. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "B",
  daytime: "Tag/Nacht",
  months: "Mai – Juli",
  to: "pteronemobius_heydenii",
  german: "Sumpfgrille",
  latin: "Pteronemobius heydenii",
  family: "Käfergrillen (Trigonidiidae)",
  first: "17.8.1994, Großenzersdorfer Arm/Obere Lobau",
  districts: "5 (11., 13., 14., 17., 22.)",
  description: "Die auf Feuchtlebensräume angewiesene Sumpfgrille hat ihre stärksten Wiener Bestände in der Lobau. Abseits davon sind vor allem Nachweise im Wienerwald bekannt geworden.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Florin Rutschmann",
};

export default data;
