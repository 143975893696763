import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./tettigonia_cantans.jpg";
import audioFile from "./tettigonia_cantans_006_APA.mp3";
import audioOscillogram from "./tettigonia_cantans_006_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 23.655,
    audioDescription: (
      <>
        Nachtgesang. Der laute, anschwellende Gesang wird ab Nachmittag (im Spätsommer und bei kühlerer Witterung auch schon früher) vorgetragen. In den Abend- und Nachtstunden wird in den „Nachtgesang“ gewechselt, ein oft minutenlang durchgehendes Schwirren. Hintergrundstimme: <SpeciesLink to="tettigonia_viridissima" text="Grünes Heupferd" after="." /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Nacht/Tag",
  months: "Juni – September",
  to: "tettigonia_cantans",
  german: "Zwitscher-Heupferd",
  latin: "Tettigonia cantans",
  family: "Laubheuschrecken (Tettigoniidae)",
  first: "um 1820, „Kahlengebirge“",
  districts: "7 (13., 14., 16.–19., 23.)",
  description: "Das Zwitscher-Heupferd kommt in Wien ausschließlich im Wienerwald vor und ist hier eine der häufigsten Langfühlerschrecken-Arten.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
