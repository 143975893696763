import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./gomphocerippus_rufus.jpg";
import audioFile from "./gomphocerippus_rufus_002_APA.mp3";
import audioOscillogram from "./gomphocerippus_rufus_002_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 10.461,
    audioDescription: (
      <>
        Von der knisternden Klangfarbe her erinnert der markante Gesang an jenen des <SpeciesLink to="chorthippus_biguttulus" text="Nachtigall-Grashüpfers" after="," /> unterscheidet sich jedoch durch regelmäßige „Anstöße“ während der Gesangsstrophe. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Tag",
  months: "August – September",
  to: "gomphocerippus_rufus",
  german: "Rote Keulenschrecke",
  latin: "Gomphocerippus rufus",
  family: "Feldheuschrecken (Acrididae)",
  first: "um 1830, „Kahlengebirge“",
  districts: "8 (13., 14., 16., 17., 19., 21.–23.)",
  description: "Zumeist wird die Rote Keulenschrecke in Waldnähe in krautreichen Habitaten angetroffen und ist daher besonders in den Randlagen des Wienerwaldes häufig.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
