import speciesImage from "./tetrix_undulata.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "E",
  daytime: "",
  months: "Februar – Oktober",
  to: "tetrix_undulata",
  german: "Gemeine Dornschrecke",
  latin: "Tetrix undulata",
  family: "Dornschrecken (Tetrigidae)",
  first: "um 1920, Hainbach",
  districts: "4 (13., 14., 17., 19.)",
  description: "Die in Wien nur im Wienerwald vorkommende Gemeine Dornschrecke lebt vor allem auf Waldlichtungen und Kahlschlägen, wo sie bei gezielter Nachsuche nicht selten angetroffen wird.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Markus Sehnal",
};

export default data;
