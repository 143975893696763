import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./pseudochorthippus_parallelus.jpg";
import audioFile from "./pseudochorthippus_parallelus_006_Tag_APA.mp3";
import audioOscillogram from "./pseudochorthippus_parallelus_006_Tag_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 15.728,
    audioDescription: (
      <>
        Im Juni und Juli werden manche Wiesen vom Gesang des häufigen Gemeinen Grashüpfers geradezu „überschwemmt“. Der Gesang besteht aus meist 10–15 gleichmäßig aneinandergereihten Kratztönen, deren Tempo stark temperaturabhängig ist. Dadurch ist er bei kühlem Wetter nicht immer leicht und einwandfrei vom <SpeciesLink to="pseudochorthippus_montanus" text="Sumpfgrashüpfer" /> zu unterscheiden. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Tag/Nacht",
  months: "Juni – August",
  to: "pseudochorthippus_parallelus",
  german: "Gemeiner Grashüpfer",
  latin: "Pseudochorthippus parallelus",
  family: "Feldheuschrecken (Acrididae)",
  first: "29.8.1891, Mauer",
  districts: "20 (1.–3., 5., 8.–23.)",
  description: "Der Gemeine Grashüpfer ist ein „Parade-Ubiquist“, der von Trockenrasen bis hin zu Sumpfwiesen in sämtlichen Lebensräumen des Grünlandes anzutreffen ist. Demgemäß ist er auch in Wien eine der verbreitetsten und häufigsten Heuschreckenarten.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Markus Sehnal",
};

export default data;
