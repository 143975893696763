import React from "react";
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom";

import ScrollToTop from "./ScrollToTop";

import Frontpage from "./Frontpage";
import Order from "./Order";
import SpeciesListIntro from "./SpeciesListIntro";
import Species from "./Species";
import HikeList from "./HikeList";
import Hike from "./Hike";
import Heuschrecken from "./Heuschrecken";
import Tagfalter from "./Tagfalter";
import Impressum from "./Impressum";
import Error404 from "./Error404";

import { speciesData, hikesWithoutLobau } from "../data/data.js";

class App extends React.Component {
  render() {
    return (
      <Router>
        <ScrollToTop>
          <Switch>
            <Route
              exact
              path="/"
            >
              <Frontpage />
            </Route>

            <Route path="/bestellung">
              <Order />
            </Route>
            <Route path="/heuschrecken/rundwege/:id">
              <Hike hikes={hikesWithoutLobau} />
            </Route>
            <Route path="/heuschrecken/rundwege">
              <HikeList hikes={hikesWithoutLobau} />
            </Route>
            <Route path="/heuschrecken/liste">
              <SpeciesListIntro />
            </Route>
            <Route path="/heuschrecken/:species">
              <Species speciesData={speciesData} />
            </Route>
            <Route path="/heuschrecken">
              <Heuschrecken />
            </Route>
            <Route path="/tagfalter">
              <Tagfalter />
            </Route>
            <Route path="/impressum">
              <Impressum />
            </Route>

            <Redirect
              from="/r1"
              to="/heuschrecken/rundwege/1"
            />
            <Redirect
              from="/r2"
              to="/heuschrecken/rundwege/2"
            />
            <Redirect
              from="/r3"
              to="/heuschrecken/rundwege/3"
            />
            <Redirect
              from="/r4"
              to="/heuschrecken/rundwege/4"
            />
            <Redirect
              from="/heuschrecken/rundwege/4"
              to="/heuschrecken/rundwege"
            />

            <Redirect
              from="/h1"
              to="/heuschrecken/tettigonia_viridissima"
            />
            <Redirect
              from="/h2"
              to="/heuschrecken/ruspolia_nitidula"
            />
            <Redirect
              from="/h3"
              to="/heuschrecken/oecanthus_pellucens"
            />
            <Redirect
              from="/h4"
              to="/heuschrecken/gryllus_campestris"
            />
            <Redirect
              from="/h5"
              to="/heuschrecken/chorthippus_mollis"
            />
            <Redirect
              from="/h6"
              to="/heuschrecken/chorthippus_biguttulus"
            />

            <Redirect
              from="//"
              to="/"
            />

            <Route path="/*">
              <Error404 />
            </Route>
            <Redirect
              from="*"
              to="/"
            />
            <Redirect
              from=""
              to="/"
            />
          </Switch>
        </ScrollToTop>
      </Router>
    );
  }
}

export default App;
