import speciesImage from "./mecostethus_parapleurus.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "E",
  daytime: "",
  months: "Juli – September",
  to: "mecostethus_parapleurus",
  german: "Lauchschrecke",
  latin: "Mecostethus parapleurus",
  family: "Feldheuschrecken (Acrididae)",
  first: "1999, Untere Lobau",
  districts: "7 (10., 13., 14., 17., 19., 22., 23.)",
  description: "War die Lauchschrecke bis in die frühen 2010er-Jahre eine in Wien überaus seltene Heuschreckenart, so kam es danach zu einem deutlichen Anstieg an Meldungen. Dieser betraf vor allem die Wienerwaldwiesen und die Lobau und ist nicht nur auf die verstärkte Kartierungstätigkeit zurückzuführen, sondern auf eine überregionale Ausbreitung.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
