import React from "react";

import speciesImage from "./barbitistes_serricauda.jpg";
import audioFile from "./barbitistes_serricauda_005_USD_Tag_mehrere_Ind_APA.mp3";
import audioOscillogram from "./barbitistes_serricauda_005_USD_Tag_mehrere_Ind_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 37.903022,
    audioDescription: <>Ultraschalldetektor-Aufnahme. Der extrem leise Gesang ist nur unter allerbesten Voraussetzungen hörbar (jedoch mittels Ultraschall-Detektor gut wahrnehmbar) Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
  },
  audible: "C",
  daytime: "Tag/Nacht",
  months: "Juli – September",
  to: "barbitistes_serricauda",
  german: "Laubholz-Säbelschrecke",
  latin: "Barbitistes serricauda",
  family: "Sichelschrecken (Phaneropteridae)",
  first: "um 1820, „Kahlengebirge“",
  districts: "12 (4., 8., 10., 11., 13., 14., 16.–19., 21., 23.)",
  description: "Diese bunte Langfühlerschrecke lebt vor allem in den Wienerwald-Bezirken. Dort ist sie durchaus häufig, aufgrund ihrer versteckten Lebensweise in Büschen und Bäumen bekommt man sie aber nur selten zu Gesicht.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Werner Reitmeier",
};

export default data;
