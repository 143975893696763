import "core-js/es/map";
import "core-js/es/set";

import React from "react";
import { hydrate, render } from "react-dom"; // for hydrating static html (generated with react-snap)
import App from "./components/App";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(<App />, rootElement);
} else {
  render(<App />, rootElement);
}
