import React from "react";

import speciesImage from "./leptophyes_punctatissima.jpg";
import audioFile from "./leptophyes_punctatissima_002_APA.mp3";
import audioOscillogram from "./leptophyes_punctatissima_002_APA.png";
import audioFile2 from "./leptophyes_punctatissima_BD_001_APA.mp3";
import audioOscillogram2 from "./leptophyes_punctatissima_BD_001_APA.png";

const data = {
  released: true,
  audioData: [
    {
      audioFile: audioFile,
      audioOscillogram: audioOscillogram,
      audioDuration: 10.030848,
      audioDescription: <>Die kurzen, unauffälligen und extrem leisen Einzeltöne sind nur unter allerbesten Voraussetzungen hörbar (selbst mit Ultraschall-Detektor schwierig). Hintergrundgeräusche: Stieglitz, Motorsäge. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
    },
    {
      audioFile: audioFile2,
      audioOscillogram: audioOscillogram2,
      audioDuration: 7.288038,
      audioDescription: <>Ultraschalldetektor-Aufnahme. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
    },
  ],
  audible: "D",
  daytime: "Tag/Nacht",
  months: "Juli – November",
  to: "leptophyes_punctatissima",
  german: "Punktierte Zartschrecke",
  latin: "Leptophyes punctatissima",
  family: "Sichelschrecken (Phaneropteridae)",
  first: "8.8.1956, 19. Bezirk, Hartäckerstraße",
  districts: "17 (1.–3., 9.–19., 21.–23.)",
  description: "Vermutlich war die Punktierte Zartschrecke in Österreich nicht heimisch und wurde mit Gartenpflanzen eingeschleppt. Mittlerweile ist sie eine der häufigsten Heuschreckenarten in der Wiener Innenstadt, wobei sie aber sehr unauffällig bleibt. Ihr österreichweiter Verbreitungsschwerpunkt liegt in und um Wien, wo sie in Ziersträuchern, Büschen und Hecken sitzt.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
