import React from "react";

import speciesImage from "./psophus_stridulus.jpg";
import audioFile from "./psophus_stridulus_001_APA.mp3";
import audioOscillogram from "./psophus_stridulus_001_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 2.583,
    audioDescription: <>Das auffallend laute Schnarrgeräusch wird im Fluge erzeugt und optisch durch die leuchtend rot gefärbten Hinterflügel untermalt. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
  },
  audible: "A",
  daytime: "Tag",
  months: "Juli – September",
  to: "psophus_stridulus",
  german: "Rotflügelige Schnarrschrecke",
  latin: "Psophus stridulus",
  family: "Feldheuschrecken (Acrididae)",
  first: "um 1870, Ober-St.-Veit",
  districts: "5 (13., 14., 17., 19., 23.)",
  description: "Die aufgrund ihres lauten Flügelschnarrens sehr auffällige Rotflügelige Schnarrschrecke ist in Ostösterreich eine sehr seltene Bewohnerin von Trockenlebensräumen. In Wien existiert von der früher im Westen Wiens noch weiter verbreiteten Art nur noch ein aktuelles Kleinvorkommen im Bereich der „Himmelswiese“ im 23. Bezirk.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
