import React from "react";

import "../../../css/content.scss";
import "../../../css/symbols.scss";

import image2 from "./image2.jpg";

const description = (
  <>
    <figure>
      <img className="content__img content__img--labeled" src={image2} alt="" />
      <figcaption className="content__img-text">Foto:&nbsp;Lorin&nbsp;Timaeus</figcaption>
    </figure>
    <h1>Durch die Obere Lobau</h1>
    <p>
      <i>Der Rundweg durch die Obere Lobau ist derzeit in Bearbeitung und erscheint in Kürze.</i>
    </p>
  </>
);

export default description;
