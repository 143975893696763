import speciesImage from "./tetrix_ceperoi.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "E",
  daytime: "",
  months: "März – September",
  to: "tetrix_ceperoi",
  german: "Westliche Dornschrecke",
  latin: "Tetrix ceperoi",
  family: "Dornschrecken (Tetrigidae)",
  only: "um 1930, „Prater“",
  districts: "1 (2.)",
  description: "Die Westliche Dornschrecke konnte aktuell nicht in Wien nachgewiesen werden. Der einzige Fundpunkt dieser österreichweit sehr seltenen Art im Prater beruht auf einer Literaturangabe. Das zugehörige Belegexemplar gilt als verschollen.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
