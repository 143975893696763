import speciesImage from "./acrida_ungarica.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "keine Lautäußerungen (nur gelegentlich leises Flügelschnarren hörbar)",
  daytime: "",
  months: "August – Oktober",
  to: "acrida_ungarica",
  german: "Gewöhnliche Nasenschrecke",
  latin: "Acrida ungarica",
  family: "Feldheuschrecken (Acrididae)",
  only: "um 1880, „k.k. Militär-Schießstätte im Prater“",
  districts: "1 (22.)",
  description: "Aus Wien existiert nur ein einziger Nachweis dieser skurrilen Heuschreckenart, die in Österreich etwa 25 Jahre lang als ausgestorben galt und erst unlängst im burgenländischen Seewinkel wiederbestätigt werden konnte.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
