import React, { useState, useRef, useEffect } from "react";

import { AudioTicks, generateTicks } from "./AudioTicks";

import "../css/AudioPlayer.scss";

function AudioPlayer(props) {
  const [trackPosition, setTrackPosition] = useState(0);
  const [dragging, setDragging] = useState(false);
  const [paused, setPaused] = useState(true);
  const audioTicks = generateTicks(props.audioData.audioDuration);

  const oscillogram = useRef(null);
  const audio = useRef(null);

  useEffect(() => {
    // allow dragging tracking slider while outside its div
    const body = document.getElementsByTagName("BODY")[0];
    body.addEventListener("mousemove", trackMove);
    body.addEventListener("touchmove", trackMove);
    body.addEventListener("mouseup", trackEnd);
    body.addEventListener("mouseenter", trackEnd);

    // this shouldn't be neccessary but is
    // because React's "Synthetic Events" don't seem to handle this right on iOS Safari (?)
    oscillogram.current.addEventListener("touchstart", trackStart);
    oscillogram.current.addEventListener("touchmove", trackMove);
    oscillogram.current.addEventListener("touchend", trackEnd);

    return () => {
      body.removeEventListener("onscroll", trackMove);
      body.removeEventListener("touchmove", trackMove);
      body.removeEventListener("mouseup", trackEnd);
      body.removeEventListener("mouseenter", trackEnd);
    };
  }, []);

  function trackStart(e) {
    e.preventDefault();
    setDragging(true);
    trackGetPosition(e);
  }

  function trackMove(e) {
    e.preventDefault();
    if (dragging) {
      trackGetPosition(e);
    }
  }

  function trackGetPosition(e) {
    // get length of tracking area and relative cursor/touch position to it
    const rect = oscillogram.current.getBoundingClientRect();
    const position = e.changedTouches ? e.changedTouches[0].pageX : e.clientX;
    let relativePosition = Math.min(position - rect.left, rect.width);
    relativePosition = relativePosition < 0 ? 0 : relativePosition / rect.width;

    audio.current.currentTime = relativePosition * audio.current.duration;
    setTrackPosition(relativePosition);
  }

  function trackEnd(e) {
    e.preventDefault();
    setDragging(false);
  }

  function audioButton(e) {
    if (audio.current.paused) {
      audio.current.play();
      setPaused(false);
    } else {
      audio.current.pause();
      setPaused(true);
    }
  }

  function audioLoaded(e) {
    // console.log(`currently loaded audio has duration: ${audio.current.duration}`);
    // console.log(`prop.audioData.audioDuration = ${props.audioData.audioDuration}`);
  }

  function audioUpdated(e) {
    setTrackPosition(audio.current.currentTime / audio.current.duration);
  }

  function audioEndedPlaying(e) {
    setPaused(true);
    setTrackPosition(0);
  }

  return (
    <>
      <div className="audioplayer">
        <audio ref={audio} src={props.audioData.audioFile} onLoadedMetadata={audioLoaded} onTimeUpdate={audioUpdated} onEnded={audioEndedPlaying} />
        <div className="audioplayer__playbutton-container">{paused ? <button className="audioplayer__playbutton audioplayer__playbutton--paused" onClick={audioButton} /> : <button className="audioplayer__playbutton" onClick={audioButton} />}</div>
        <div className="audioplayer__track-container">
          <div className="audioplayer__oscillogram-container">
            <div className="audioplayer__oscillogram-wrapper" ref={oscillogram} onMouseDown={trackStart} onTouchStart={trackStart} onMouseMove={trackMove} onTouchMove={trackMove} onMouseUp={trackEnd} onTouchEnd={trackEnd}>
              <div
                className="audioplayer__oscillogram"
                style={{
                  backgroundImage: "url(" + props.audioData.audioOscillogram + ")",
                }}
              ></div>
              <div className="audioplayer__oscillogram-graying" style={{ width: trackPosition * 100 + "%" }}></div>
            </div>
          </div>
          <AudioTicks audioTicks={audioTicks} />
        </div>
      </div>
      <p className="audioplayer__description">{props.audioData.audioDescription}</p>
    </>
  );
}

export default AudioPlayer;
