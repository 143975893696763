import React from "react";

import speciesImage from "./omocestus_viridulus.jpg";
import audioFile from "./omocestus_viridulus_003_APA.mp3";
import audioOscillogram from "./omocestus_viridulus_003_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 7.535,
    audioDescription: <>Vor allem auf Almwiesen ist der tickend-klappernde Gesang des Bunten Grashüpfers allgegenwärtig. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
  },
  audible: "A",
  daytime: "",
  months: "",
  to: "omocestus_viridulus",
  german: "Bunter Grashüpfer",
  latin: "Omocestus viridulus",
  family: "Feldheuschrecken (Acrididae)",
  only: "22.7.1989, Johannser Wiese/Lainzer Tiergarten",
  districts: "1 (13.)",
  description: "Mit nur einem einzigen, älteren Nachweis im Lainzer Tiergarten gilt der Bunte Grashüpfer als aktuell nicht mehr in Wien vorkommend.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
