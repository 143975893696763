import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./omocestus_haemorrhoidalis.jpg";
import audioFile from "./omocestus_haemorrhoidalis_002_APA.mp3";
import audioOscillogram from "./omocestus_haemorrhoidalis_002_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 5.074,
    audioDescription: (
      <>
        Der Gesang erinnert an den des <SpeciesLink to="omocestus_rufipes" text="Buntbäuchigen Grashüpfers" after="." /> Die Schwirrverse sind beim Rotleibigen Grashüpfer allerdings kürzer (3–4 Sekunden) und etwas leiser. Eine noch größere Ähnlichkeit besteht zum Gesang des <SpeciesLink to="stenobothrus_stigmaticus" text="Kleinen Heidegrashüpfers" after="." /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "B",
  daytime: "Tag",
  months: "Juli – August",
  to: "omocestus_haemorrhoidalis",
  german: "Rotleibiger Grashüpfer",
  latin: "Omocestus haemorrhoidalis",
  family: "Feldheuschrecken (Acrididae)",
  first: "um 1880, „Liesing und Ottakring“",
  districts: "7 (10., 12., 13., 16., 21.–23.)",
  description: "Als ausgewiesener Spezialist für Trockenrasen und trockene Brachen ist der Rotleibige Grashüpfer im heutigen Stadtgebiet kaum mehr zu finden. Die einzigen größeren Populationen bildet er auf den Heißländen der Lobau aus.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Markus Sehnal",
};

export default data;
