import speciesImage from "./miramella_alpina.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "D",
  daytime: "",
  months: "Juli – September",
  to: "miramella_alpina",
  german: "Alpine Gebirgsschrecke",
  latin: "Miramella alpina",
  family: "Feldheuschrecken (Acrididae)",
  first: "um 1850, „Liesing“",
  districts: "3 (13., 14., 23.)",
  description: "Während aus historischer Zeit mehrere Funde der Alpinen Gebirgsschrecke aus den westlichen Bezirken vorliegen, die in den damals wohl noch nicht verbauten Bereichen erbracht wurden, existiert heute nur ein einziges bekanntes Vorkommen bei der Eichwiese nahe Kalksburg.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
