import React from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

import "../css/content.scss";
import "../css/navigation.scss";

import tetrix_kraussi from "../data/heuschrecken/tetrix_kraussi.jpg";

import heuschreckenBuch from "../data/buch_iiw_heuschrecken_seitlich.jpg";
import werbeflyerPDF from "../data/heuschrecken/werbeflyer_heuschrecken.pdf";

function Heuschrecken(props) {
  return (
    <>
      <Header
        title="Insekten in Wien – Heuschrecken"
        logoTextSmall="Insekten in Wien"
        logoTextLarge="Heuschrecken"
        logoLink="/"
        links={[
          { to: "/heuschrecken/liste", active: true, text: "Arten" },
          { to: "/heuschrecken/rundwege", active: true, text: "Rundwege" },
        ]}
      />
      <div className="content__container">
        <div className="content">
          <Link to="/" className="nav__button">
            zur Buchreihe
          </Link>
          <img className="content__img-book" src={heuschreckenBuch} alt="" />
          <h1>Insekten in Wien – Heuschrecken</h1>
          <p>
            <b>Günther&nbsp;Wöss, Manuel&nbsp;Denner, Liesbeth&nbsp;Forsthuber, Matthias&nbsp;Kropf, Alexander&nbsp;Panrok, Werner&nbsp;Reitmeier & Thomas&nbsp;Zuna-Kratky</b>
            <br />
            2020 • 288&nbsp;Seiten • Hardcover, 21&nbsp;×&nbsp;27&nbsp;cm
            <br />
            ISBN 978-3-9503548-1-2
            <br />
            Preis: 29 € (22 € für ÖGEF-Mitglieder)
            <br />
            <a href={werbeflyerPDF} target="_blank" rel="noopener noreferrer">
              Werbeflyer herunterladen
            </a>
          </p>
          <div className="nav__orderbutton-wrapper">
            <Link className="nav__orderbutton" to="/bestellung">
              Buch bestellen
            </Link>
          </div>

          <p>Basierend auf über 29.000 Beobachtungsdaten stellt der zweite Band der Buchreihe „Insekten in Wien“ die 85 in der Bundeshauptstadt nachgewiesenen Heuschreckenarten und die Gottesanbeterin in reich bebilderten Artportraits vor. Die Steckbriefe beinhalten u.&nbsp;a. Angaben zu Erkennungsmerkmalen, Verbreitung, Lebensräumen, Phänologie und Gefährdung der Arten.</p>
          <figure>
            <img className="content__img content__img--labeled" src={tetrix_kraussi} alt="Segelfalter" />
            <figcaption className="content__img-text">
              Nur selten ist die Kurzflügel-Dornschrecke (<i>Tetrix kraussi</i>) in Wien anzutreffen. Foto:&nbsp;Günther&nbsp;Wöss
            </figcaption>
          </figure>
          <p>Mehrere allgemeine Kapitel beleuchten diese musikalische Insektengruppe und ihr Vorkommen in Wien aus unterschiedlichen Blickwinkeln. Erstmals wurde eine Rote Liste der Heuschrecken Wiens erarbeitet und Rundwege ermöglichen mithilfe von Wanderkarten und Artenlisten das Erkunden der Wiener Heuschreckenfauna in verschiedenen Landschaftsräumen. QR-Codes, die auf diese Website leiten, machen die Wanderungen auch digital verfügbar und erlauben das Abspielen ausgewählter Gesänge.</p>
          <Link className="nav__button-large" to="/heuschrecken/liste/">
            Artenliste
          </Link>
          <Link className="nav__button-large" to="/heuschrecken/rundwege/">
            Rundwege
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Heuschrecken;
