import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./acheta_domesticus.jpg";
import audioFile from "./acheta_domesticus_001_APA.mp3";
import audioOscillogram from "./acheta_domesticus_001_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 15.78,
    audioDescription: <>Oft hört man nachts im bebauten Statdtgebiet die „bescheiden“ klingenden Einzeltöne des Heimchens, die nur sporadisch geäußert werden. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
  },
  audible: "A",
  daytime: "Tag/Nacht",
  months: "ganzjährig",
  to: "acheta_domesticus",
  german: "Heimchen",
  latin: "Acheta domesticus",
  family: "Echte Grillen (Gryllidae)",
  first: "um 1945, „Wien“",
  districts: "21 (1.–4., 6.–16., 18.–23.)",
  description: (
    <>
      Die ursprünglich in Österreich nicht heimische Art konnte als Gefangenschaftsflüchtling mehr oder weniger beständige Freiland-Populationen bilden; auch entkommene oder ausgelassene Tiere aus der Terraristik spielen dabei eine vermutlich nicht unwesentliche Rolle. Meist hört man einzelne Tiere in warmen Sommernächten in der Nähe von Gebäuden ihren zaghaften Gesang vortragen, der an jenen der <SpeciesLink to="gryllus_campestris" text="Feldgrille" /> erinnert.
    </>
  ),
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
