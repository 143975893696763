import React from "react";

import speciesImage from "./phaneroptera_falcata.jpg";
import audioFile from "./phaneroptera_falcata_006_APA.mp3";
import audioOscillogram from "./phaneroptera_falcata_006_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 13.899,
    audioDescription: <>Ultraschalldetektor-Aufnahme. Das sehr leise und hochfrequente „srb“ wird meist von umliegenden Arten und Geräuschen übertönt, ist aber mittels Ultraschall-Detektor gut wahrnehmbar. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
  },
  audible: "C",
  daytime: "Nacht/Tag",
  months: "Juli – September",
  to: "phaneroptera_falcata",
  german: "Gemeine Sichelschrecke",
  latin: "Phaneroptera falcata",
  family: "Sichelschrecken (Phaneropteridae)",
  first: "um 1820, „Kahlengebirge“",
  districts: "12 (2., 3., 10., 11., 13., 14., 16., 17., 19., 21.–23.)",
  description: "Die auf verbrachte wiesen-, kraut- und strauchgeprägte Standorte angewiesene Gemeine Sichelschrecke kommt in Wien vor allem in den Außengebieten Wienerwald und Lobau vor. Im Stadtgebiet sind die Fundorte spärlich.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Matthias Kropf",
};

export default data;
