import speciesImage from "./odontopodisma_decipiens.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "D",
  daytime: "",
  months: "Juli – September",
  to: "odontopodisma_decipiens",
  german: "Gewöhnliche Grünschrecke",
  latin: "Odontopodisma decipiens",
  family: "Feldheuschrecken (Acrididae)",
  only: "um 1920, „Kuchelau“",
  districts: "1 (19.)",
  description: "Als nicht völlig abgesichert gilt ein Sammlungsbeleg mit dem Etikett „Kuchelau“ in der Sammlung W. Kühnelt, der einzige Hinweis auf ein Vorkommen der Gewöhnlichen Grünschrecke in Wien.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Markus Sehnal",
};

export default data;
