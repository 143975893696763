import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./omocestus_rufipes.jpg";
import audioFile from "./omocestus_rufipes_003_APA.mp3";
import audioOscillogram from "./omocestus_rufipes_003_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 11.297,
    audioDescription: (
      <>
        Der nur sporadisch vorgetragene Gesang ist ein recht leiser und meist um die zehn Sekunden andauernder Schwirrvers, der ähnlich jenem des <SpeciesLink to="omocestus_haemorrhoidalis" text="Rotleibigen Grashüpfers" /> ist. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "B",
  daytime: "Tag",
  months: "Juni – September",
  to: "omocestus_rufipes",
  german: "Buntbäuchiger Grashüpfer",
  latin: "Omocestus rufipes",
  family: "Feldheuschrecken (Acrididae)",
  first: "27.9.1909, Weidlingau",
  districts: "3 (14., 19., 22.)",
  description: (
    <>
      Der auf der Unterseite sehr farbenfrohe Buntbäuchige Grashüpfer zeigt in Wien eine ähnliche Verbreitung wie der <SpeciesLink to="omocestus_haemorrhoidalis" text="Rotleibige Grashüpfer" after="." /> Fast nur noch auf den Heißländen und am Donaudamm in der Lobau vorkommend, dürfte er im restlichen Stadtgebiet schon immer eine ausgesprochene Rarität gewesen sein.
    </>
  ),
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
