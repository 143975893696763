import React from "react";

import speciesImage from "./chorthippus_biguttulus.jpg";
import audioFile from "./chorthippus_biguttulus.mp3";
import audioOscillogram from "./chorthippus_biguttulus.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 10.856,
    audioDescription: <>Reibt der Nachtigall-Grashüpfer die Hinterschenkel an den Vorderflügeln, entsteht ein Geräusch, das an das Rasseln einer Klapperschlange erinnert. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
  },
  audible: "A",
  daytime: "Tag",
  months: "Juli – September",
  to: "chorthippus_biguttulus",
  german: "Nachtigall-Grashüpfer",
  latin: "Chorthippus biguttulus",
  family: "Feldheuschrecken (Acrididae)",
  first: "1908; Südhang Dreimarkstein und Obersievering",
  districts: "19 (1. – 3., 8. – 23.)",
  description: "Diese überaus häufige Art bewohnt eine Vielzahl von Habitaten und ist in Wien flächendeckend verbreitet.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
