import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./roeseliana_roeselii.jpg";
import audioFile from "./roeseliana_roeselii_004_APA.mp3";
import audioOscillogram from "./roeseliana_roeselii_004_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 33.783,
    audioDescription: (
      <>
        Der Gesang besteht aus langen Serien hoher und gleichbleibender Sirrtöne. Erinnert an die <SpeciesLink to="ruspolia_nitidula" text="Große Schiefkopfschrecke" after="," /> ist aber weniger durchdringend. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Tag",
  months: "Juni – August",
  to: "roeseliana_roeselii",
  german: "Roesels Beißschrecke",
  latin: "Roeseliana roeselii",
  family: "Laubheuschrecken (Tettigoniidae)",
  first: "um 1920, Hainbach",
  districts: "13 (2., 3., 10., 11., 13., 14., 16.–19., 21.–23.)",
  description: "Roesels Beißschrecke ist eine häufige Bewohnerin von Feuchtwiesen, extensiven Mähwiesen und grasreichen „Gstett‘n“, kann jedoch auch in nährstoffreicheren Fettwiesen existieren. Dementsprechend findet man die besten Bestände in der Lobau, im Wienerwald und in den mit reichlich Grünflächen ausgestatteten Südbezirken Favoriten und Simmering.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Anton Koschuh†",
};

export default data;
