import speciesImage from "./tetrix_subulata.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "E",
  daytime: "",
  months: "März – September",
  to: "tetrix_subulata",
  german: "Säbeldornschrecke",
  latin: "Tetrix subulata",
  family: "Dornschrecken (Tetrigidae)",
  first: "21.5.1893, Mauer",
  districts: "11 (2., 10., 11., 13., 14., 17.–19., 21.–23.)",
  description: "Die Säbeldornschrecke ist eine hygrophile Heuschreckenart und kommt in größeren, zusammenhängenden Beständen in der Lobau, im Prater und im Wienerwald vor. Andere Fundorte sind weit verstreut.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Markus Sehnal",
};

export default data;
