import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./stenobothrus_stigmaticus.jpg";
import audioFile from "./stenobothrus_stigmaticus_003_APA.mp3";
import audioOscillogram from "./stenobothrus_stigmaticus_003_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 2.709,
    audioDescription: (
      <>
        Nur sehr leise und unauffällig ist der Gesang des Kleinen Heidegrashüpfers, der aus 2–3 Sekunden dauernden Versen mit oft langen Pausen besteht. Er erinnert stark an den Spontangesang des <SpeciesLink to="omocestus_haemorrhoidalis" text="Rotleibigen Grashüpfers" after="." /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "B",
  daytime: "Tag",
  months: "Juli – August",
  to: "stenobothrus_stigmaticus",
  german: "Kleiner Heidegrashüpfer",
  latin: "Stenobothrus stigmaticus",
  family: "Feldheuschrecken (Acrididae)",
  only: "um 1890, „Mauer“",
  districts: "1 (23.)",
  description: "Das einzige ehemalige Vorkommen des Kleinen Heidegrashüpfers in Wien war in Mauer und ist mittlerweile erloschen. Laut Literatur kam er hier auf spärlich bewachsenen Wiesen und Weiden vor.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
