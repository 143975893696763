import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./stenobothrus_nigromaculatus.jpg";
import audioFile from "./stenobothrus_nigromaculatus_mehrere_s_M_012_APA.mp3";
import audioOscillogram from "./stenobothrus_nigromaculatus_mehrere_s_M_012_APA.png";

const data = {
  released: true,
  audioData: [
    {
      audioFile: audioFile,
      audioOscillogram: audioOscillogram,
      audioDuration: 6.789,
      audioDescription: (
        <>
          Das relativ laute, anschwellende Schwirren mit abruptem Ende lässt das Orthopterologenherz meist höher schlagen. Am ehesten ist der Gesang mit jenem der <SpeciesLink to="myrmeleotettix_maculatus" text="Gefleckten Keulenschrecke" /> zu verwechseln. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
        </>
      ),
    },
  ],
  audible: "A",
  daytime: "Tag",
  months: "Juni – Juli",
  to: "stenobothrus_nigromaculatus",
  german: "Schwarzfleckiger Heidegrashüpfer",
  latin: "Stenobothrus nigromaculatus",
  family: "Feldheuschrecken (Acrididae)",
  first: "um 1880, „Mauer“",
  districts: "3 (21.–23.)",
  description: "Als eine der seltensten Heuschreckenarten Wiens bewohnt der hoch anspruchsvolle und wärmeliebende Schwarzfleckige Heidegrashüpfer gegenwärtig nur sehr wenige Trockenrasen in der Oberen Lobau.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Manuel Denner",
};

export default data;
