import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./pholidoptera_griseoaptera.jpg";
import audioFile from "./pholidoptera_griseoaptera_004_APA.mp3";
import audioOscillogram from "./pholidoptera_griseoaptera_004_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 12.478,
    audioDescription: (
      <>
        Der Gesang ähnelt jenem der <SpeciesLink to="pholidoptera_fallax" text="Südlichen Strauchschrecke" /> („zrrt“), erscheint in Summe allerdings etwas kräftiger und voller. Hintergrundstimmen: <SpeciesLink to="platycleis_grisea" text="Graue Beißschrecke" after="," /> <SpeciesLink to="pholidoptera_aptera" text="Alpen-Strauchschrecke" after="." /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Nacht/Tag",
  months: "Juli – Oktober",
  to: "pholidoptera_griseoaptera",
  german: "Gewöhnliche Strauchschrecke",
  latin: "Pholidoptera griseoaptera",
  family: "Laubheuschrecken (Tettigoniidae)",
  first: "29.8.1891, Mauer",
  districts: "19 (1.–4., 9.–23.)",
  description: "Vor allem gehölz- und krautreiche Standorte sind beliebte Aufenthaltsorte der Gewöhnlichen Strauchschrecke, die in Wien eine der häufigsten Langfühlerschrecken-Arten ist.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
