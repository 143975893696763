import speciesImage from "./mantis_religiosa.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "E",
  daytime: "",
  months: "Juli – Oktober",
  to: "mantis_religiosa",
  german: "Europäische Gottesanbeterin",
  latin: "Mantis religiosa",
  family: "Mantidae",
  first: "um 1820, „Kahlengebirge“",
  districts: "20 (1.– 3., 6., 8.–23.)",
  description: "Jedem naturinteressierten Menschen ist die Gottesanbeterin ein Begriff. Von der zunehmenden Erwärmung des Großklimas dürfte sie profitieren, befindet sie sich doch auf stetigem Vormarsch Richtung Westen. So ist auch Wien mittlerweile zwar lückig, aber flächendeckend besiedelt, wobei sie besonders in Halbtrockenrasen und hochgrasigen Ruderalbrachen zu finden ist.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Markus Sehnal",
};

export default data;
