import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./tettigonia_caudata.jpg";
import audioFile from "./tettigonia_caudata_003_APA.mp3";
import audioOscillogram from "./tettigonia_caudata_003_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 31.362,
    audioDescription: (
      <>
        Der etwa 3 Sekunden andauernde, helle Gesang ist meist aus Getreidefeldern zu vernehmen und ähnelt im Klangbild jenem des <SpeciesLink to="tettigonia_cantans" text="Zwitscher-Heupferdes" after="." /> Hintergrundstimmen: <SpeciesLink to="pseudochorthippus_parallelus" text="Gemeiner Grashüpfer" after=";" /> Feldlerche. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Tag",
  months: "Juni – Juli",
  to: "tettigonia_caudata",
  german: "Östliches Heupferd",
  latin: "Tettigonia caudata",
  family: "Laubheuschrecken (Tettigoniidae)",
  first: "um 1880, Liesing",
  districts: "13 (1., 3., 6.–11., 13., 17., 21.–23.)",
  description: "Das Östliche Heupferd kommt hauptsächlich in Getreidefeldern vor und ist aktuell eine sehr seltene Erscheinung in Wien. Die meisten Funde stammen aus den Agrarlandschaften im Süden und Norden.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Maria Zacherl",
};

export default data;
