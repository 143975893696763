import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./euchorthippus_declivus.jpg";
import audioFile from "./euchorthippus_declivus_008_APA.mp3";
import audioOscillogram from "./euchorthippus_declivus_008_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 22.683,
    audioDescription: (
      <>
        Das unauffällige, relativ leise und weiche „drrt“ kann klanglich am ehesten mit dem <SpeciesLink to="chorthippus_brunneus" text="Braunen Grashüpfer" /> verwechselt werden. Hintergrundstimmen: <SpeciesLink to="bicolorana_bicolor" text="Zweifarbige Beißschrecke" after="," /> <SpeciesLink to="chorthippus_brunneus" text="Brauner Grashüpfer" after=";" /> Kohlmeise. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Tag",
  months: "Juli – September",
  to: "euchorthippus_declivus",
  german: "Dickkopf-Grashüpfer",
  latin: "Euchorthippus declivus",
  family: "Feldheuschrecken (Acrididae)",
  first: "5.7.1961, Laaer Berg",
  districts: "19 (2.–5., 9.–23.)",
  description: "Der sich nach wie vor nach Westen ausbreitende Dickkopf-Grashüpfer bewohnt hauptsächlich trockene Habitate wie Halbtrocken- und Trockenrasen oder vertikal strukturierte Ruderalfluren. Er ist in Wien mittlerweile eine häufige Art geworden.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
