import speciesImage from "./stenobothrus_crassipes.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "C",
  daytime: "Tag",
  months: "Juli – September",
  to: "stenobothrus_crassipes",
  german: "Zwerg-Heidegrashüpfer",
  latin: "Stenobothrus crassipes",
  family: "Feldheuschrecken (Acrididae)",
  first: "um 1880, „Liesing, Hietzing, Ottakring“",
  districts: "4 (13., 16., 21., 23.)",
  description: "Der in Wien sehr seltene Zwerg-Heidegrashüpfer hat in der Vergangenheit Bestandseinbußen erleiden müssen, sodass er aktuell nur noch in kleinen Bereichen des 21. und 23. Bezirks zu finden ist. Hier bewohnt er vorwiegend Trocken- und Halbtrockenrasen.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
