import React from "react";

import "../css/content.scss";
import "../css/symbols.scss";

function P(props) {
  if (props.after) {
    return (
      <span className="nowrap">
        <span className={props.class}>{props.t}</span>
        {props.after}
      </span>
    );
  } else {
    return <span className={props.class}>{props.t}</span>;
  }
}

export function PP(props) {
  return <P class="symbol__pp" t={props.t} after={props.after} />;
}

export function P1(props) {
  return <P class="symbol__p1" t={props.t} after={props.after} />;
}

export function P2(props) {
  return <P class="symbol__p2" t={props.t} after={props.after} />;
}

export function P3(props) {
  return <P class="symbol__p3" t={props.t} after={props.after} />;
}

export function P4(props) {
  return <P class="symbol__p4" t={props.t} after={props.after} />;
}

export function P5(props) {
  return <P class="symbol__p5" t={props.t} after={props.after} />;
}

export function P6(props) {
  return <P class="symbol__p6" t={props.t} after={props.after} />;
}

export function P7(props) {
  return <P class="symbol__p7" t={props.t} after={props.after} />;
}

export function P8(props) {
  return <P class="symbol__p8" t={props.t} after={props.after} />;
}

export function P9(props) {
  return <P class="symbol__p9" t={props.t} after={props.after} />;
}

export function PU(props) {
  return <P class="symbol__pu" t={props.t} after={props.after} />;
}

export function PPs(props) {
  return <P class="symbol__pps" t={props.t} after={props.after} />;
}

export function P1s(props) {
  return <P class="symbol__p1s" t={props.t} after={props.after} />;
}

export function P2s(props) {
  return <P class="symbol__p2s" t={props.t} after={props.after} />;
}

export function P3s(props) {
  return <P class="symbol__p3s" t={props.t} after={props.after} />;
}

export function P4s(props) {
  return <P class="symbol__p4s" t={props.t} after={props.after} />;
}

export function P5s(props) {
  return <P class="symbol__p5s" t={props.t} after={props.after} />;
}

export function P6s(props) {
  return <P class="symbol__p6s" t={props.t} after={props.after} />;
}

export function P7s(props) {
  return <P class="symbol__p7s" t={props.t} after={props.after} />;
}

export function P8s(props) {
  return <P class="symbol__p8s" t={props.t} after={props.after} />;
}

export function P9s(props) {
  return <P class="symbol__p9s" t={props.t} after={props.after} />;
}

export function PUs(props) {
  return <P class="symbol__pus" t={props.t} after={props.after} />;
}
