import speciesImage from "./saga_pedo.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "E",
  daytime: "",
  months: "Juli – September",
  to: "saga_pedo",
  german: "Große Sägeschrecke",
  latin: "Saga pedo",
  family: "Laubheuschrecken (Tettigoniidae)",
  first: "um 1820, „Kahlengebirge“",
  districts: "1 (19.)",
  description: "Das Flaggschiff der ostösterreichischen Heuschreckenforschung, die Große Sägeschrecke, ist eine ausgesprochen anspruchsvolle Art, die in Wien nur in den Trockenrasen an den Südhängen von Kahlenberg und Leopoldsberg zu finden ist.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
