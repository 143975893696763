import React, { useEffect } from "react";
import { Link } from "react-router-dom";

import Hamburger from "./Hamburger";

import "../css/Header.scss";

function Header(props) {
  useEffect(() => {
    document.title = props.title;
  }, [props.title]);

  return (
    <>
      <Hamburger />
      <header className="header__container">
        <nav className="header">
          <Link to={props.logoLink} className="header__logo">
            <div className="header__logo-small">{props.logoTextSmall}</div>
            <div className="header__logo-large">{props.logoTextLarge}</div>
          </Link>
          {props.links.map((link) =>
            link.active ? (
              <Link key={link.to} to={link.to} className="header__link">
                {link.text}
              </Link>
            ) : (
              <Link key={link.to} to={link.to} className="header__link header__link--active">
                {link.text}
              </Link>
            )
          )}
        </nav>
      </header>
    </>
  );
}

export default Header;
