import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./metrioptera_brachyptera.jpg";
import audioFile from "./metrioptera_brachyptera_006_APA.mp3";
import audioOscillogram from "./metrioptera_brachyptera_006_APA.png";
import audioFile2 from "./metrioptera_brachyptera_BD_004_APA.mp3";
import audioOscillogram2 from "./metrioptera_brachyptera_BD_004_APA.png";

const data = {
  released: true,
  audioData: [
    {
      audioFile: audioFile,
      audioOscillogram: audioOscillogram,
      audioDuration: 6.756,
      audioDescription: (
        <>
          Der Spontangesang der Kurzflügeligen Beißschrecke besteht aus leisen, recht gleichmäßigen und meist länger andauernden Serien mit ca. 3–4 Versen/Sekunde (ähnlich <SpeciesLink to="platycleis_grisea" text="Grauer Beißschrecke" after=")." /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
        </>
      ),
    },
    {
      audioFile: audioFile2,
      audioOscillogram: audioOscillogram2,
      audioDuration: 7.397,
      audioDescription: <>Ultraschalldetektor-Aufnahme. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
    },
  ],
  audible: "B",
  daytime: "Tag/Nacht",
  months: "Juli – September",
  to: "metrioptera_brachyptera",
  german: "Kurzflügelige Beißschrecke",
  latin: "Metrioptera brachyptera",
  family: "Laubheuschrecken (Tettigoniidae)",
  first: "um 1908, Südhang Dreimarkstein",
  last: "3.8.1994, Wienerblick/Lainzer Tiergarten",
  districts: "3 (13., 14., 19.)",
  description: "Abgesehen von der Beobachtung eines singenden Männchens im Jahr 1994 gibt es von der Kurzflügeligen Beißschrecke nur vereinzelte historische Nachweise aus dem Wienerwald.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Markus Sehnal",
};

export default data;
