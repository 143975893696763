import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./ephippiger_ephippiger.jpg";
import audioFile from "./ephippiger_ephippiger_006_APA.mp3";
import audioOscillogram from "./ephippiger_ephippiger_006_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 24.243,
    audioDescription: (
      <>
        Der markante Gesang besteht aus einem ein- oder zweisilbigen, scharfen und recht lauten „z-z“. Er wird meist ab dem späten Vormittag vorgetragen, gelegentlich auch nachts. Hintergrundstimmen: <SpeciesLink to="bicolorana_bicolor" text="Zweifarbige Beißschrecke" after="," /> <SpeciesLink to="chorthippus_mollis" text="Verkannter Grashüpfer" after="," /> <SpeciesLink to="chorthippus_biguttulus" text="Nachtigall-Grashüpfer" after="." /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Tag/Nacht",
  months: "August – September",
  to: "ephippiger_ephippiger",
  german: "Steppen-Sattelschrecke",
  latin: "Ephippiger ephippiger",
  family: "Laubheuschrecken (Tettigoniidae)",
  first: "1908, Dreimarkstein",
  districts: "2 (19., 23.)",
  description: "Aktuell ist nur noch ein kleines Vorkommen der Steppen-Sattelschrecke am Südhang des Kahlenberges in einer Trockenrasengesellschaft bekannt. Auch in früherer Zeit war die Art in Wien nur lokal verbreitet und selten.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
