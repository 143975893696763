import React from "react";

import speciesImage from "./phaneroptera_nana.jpg";
import audioFile from "./phaneroptera_nana_015_APA.mp3";
import audioOscillogram from "./phaneroptera_nana_015_APA.png";
import audioFile2 from "./phaneroptera_nana_BD_010_APA.mp3";
import audioOscillogram2 from "./phaneroptera_nana_BD_010_APA.png";

const data = {
  released: true,
  audioData: [
    {
      audioFile: audioFile,
      audioOscillogram: audioOscillogram,
      audioDuration: 9.371,
      audioDescription: <>Das sehr leise und hochfrequente „srb“ wird meist von umliegenden Arten und Geräuschen übertönt, ist aber mittels Ultraschall-Detektor gut wahrnehmbar. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
    },
    {
      audioFile: audioFile2,
      audioOscillogram: audioOscillogram2,
      audioDuration: 21.994724,
      audioDescription: <>Ultraschalldetektor-Aufnahme. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
    },
  ],
  audible: "C",
  daytime: "Nacht/Tag",
  months: "August – Oktober",
  to: "phaneroptera_nana",
  german: "Vierpunktige Sichelschrecke",
  latin: "Phaneroptera nana",
  family: "Sichelschrecken (Phaneropteridae)",
  first: "1989; 9. Bezirk, Biologiezentrum Althanstraße & 11. Bezirk, Rinnböckstraße und Donaukanal",
  districts: "23 (1.–23.)",
  description: "Die Vierpunktige Sichelschrecke hat seit ihrer Entdeckung für Wien im Jahre 1989 nahezu die gesamte Stadt erobert und ist sogar auf Ziersträuchern im innerstädtischen Bereich häufig anzutreffen.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Maria Zacherl",
};

export default data;
