import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./oedipoda_caerulescens.jpg";
import audioFile from "./oedipoda_caerulescens_001_APA.mp3";
import audioOscillogram from "./oedipoda_caerulescens_001_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 20.397,
    audioDescription: <> Balzlaute eines um ein Weibchen werbendes Männchen (Mikrofonentfernung ca. 20&nbsp;cm). Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
  },
  audible: "B",
  daytime: "Tag",
  months: "Juli – Oktober",
  to: "oedipoda_caerulescens",
  german: "Blauflügelige Ödlandschrecke",
  latin: "Oedipoda caerulescens",
  family: "Feldheuschrecken (Acrididae)",
  first: "um 1820, „Donauauen“",
  districts: "20 (1.–3., 5., 7., 9.–23.)",
  description: (
    <>
      Als xerothermophile Art offener Rohböden ist die auffällige Blauflügelige Ödlandschrecke an vielen Ruderalstandorten zu finden, wo sie sich den Lebensraum meist mit der <SpeciesLink to="calliptamus_italicus" text="Italienischen Schönschrecke" /> und gelegentlich mit der <SpeciesLink to="sphingonotus_caerulans" text="Blauflügeligen Sandschrecke" /> teilt.
    </>
  ),
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
