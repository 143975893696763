import React from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

import "../css/content.scss";
import "../css/navigation.scss";

import heuschreckenBuch from "../data/buch_iiw_heuschrecken_seitlich.jpg";
import tagfalterBuch from "../data/buch_iiw_tagfalter_seitlich.jpg";
import gruenesHeupferdFenster from "../data/heuschrecken/gruenes_heupferd_fenster.jpg";

function Frontpage(props) {
  return (
    <>
      <Header title="Insekten in Wien – Die Buchreihe" logoTextSmall="&nbsp;" logoTextLarge="Insekten in Wien" logoLink="/" links={[]} />
      <div className="content__container">
        <div className="content">
          <figure>
            <img className="content__img-frontpage" src={gruenesHeupferdFenster} alt="" />
            <figcaption className="content__img-author">
              Grünes Heupferd (<i>Tettigonia viridissima</i>) Foto:&nbsp;Liesbeth&nbsp;Forsthuber
            </figcaption>
          </figure>

          <h1>Die Buchreihe</h1>
          <p>Wer kennt die Insekten, die es im Garten, am Balkon, im Park ums Eck oder auf einer Wanderung im Wienerwald zu entdecken gibt?</p>
          <p>Wien ist eine außergewöhnliche Großstadt mit einer unvergleichlichen Vielfalt an Lebensräumen, Pflanzen und Tieren. Unsere Buchreihe möchte ihre Insektenwelt beispielhaft – und doch hinsichtlich der untersuchten Gruppen umfassend – porträtieren.</p>
          <p>Mancher Leser wird über die Artenvielfalt staunen, manche Leserin sich über den Artenschwund Gedanken machen. Der Biologe und die Ökologin finden interessante Hinweise zur Stadtökologie, zum Vorkommen und zur Verbreitung der Arten.</p>
          <Link to="/heuschrecken">
            <img className="content__img-book" src={heuschreckenBuch} alt="" />
          </Link>
          <h1>Insekten in Wien – Heuschrecken</h1>
          <p>Singen und Springen – zwei Eigenschaften, für die Heuschrecken wohlbekannt sind. Dass aber Heuschrecke nicht gleich Heuschrecke ist, sondern sie gerade in Wien eine beachtliche Diversität ausgebildet haben, wird in diesem Buch bildreich veranschaulicht. Nicht zuletzt ihre wichtige Rolle im Naturschutz macht sie derzeit zu einer der gefragtesten Insektengruppen.</p>
          <Link className="nav__button-large" to="/heuschrecken">
            Heuschrecken
          </Link>
          <Link to="/tagfalter">
            <img className="content__img-book" src={tagfalterBuch} alt="" />
          </Link>
          <h1>Insekten in Wien – Tagfalter</h1>
          <p>Tagfalter sind in weiten Bevölkerungskreisen nicht zuletzt wegen ihrer Farbenpracht bekannt und beliebt. Wien beherbergt auf seiner kleinen Fläche fast doppelt so viele Tagfalterarten, wie z.&nbsp;B. in ganz Großbritannien vorkommen.</p>
          <p>Dieses Buch stellt diese Vielfalt in beeindruckender Weise vor und zeigt nicht nur die Falter in verschiedenen Variationen sondern auch die Eier, Raupen und Puppen vieler Arten.</p>
          <Link className="nav__button-large" to="/tagfalter">
            Tagfalter
          </Link>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Frontpage;
