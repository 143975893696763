import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";

import "../css/Hamburger.scss";

function Hamburger(props) {
  const [scrollPos, setScrollPos] = useState(0);
  const toTheTopArrow = useRef(null);
  const hamburger = useRef(null);
  const hamburgerInput = useRef(null);

  useEffect(() => {
    const topPosition = window.matchMedia("not all and (min-width: 755px)").matches ? "10px" : "15px";
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const scrollingUp = scrollPos > currentScrollPos || currentScrollPos <= 0;

      if (hamburger.current && !hamburgerInput.current.checked && hamburgerInput.current) {
        hamburger.current.style.opacity = scrollingUp || window.innerWidth >= 755 ? "1" : "0";
        hamburger.current.style.top = scrollingUp || window.innerWidth >= 755 ? topPosition : "-60px";
        hamburgerInput.current.disabled = scrollingUp || window.innerWidth >= 755 ? false : true;
      }

      // hide to-top-arrow unless at least 250px scrolled down
      if (toTheTopArrow.current) {
        toTheTopArrow.current.style.opacity = scrollingUp && currentScrollPos > 155 && document.body.offsetHeight > 1500 ? "1" : "0";
        toTheTopArrow.current.style.top = scrollingUp && currentScrollPos > 155 && document.body.offsetHeight > 1500 ? topPosition : "-60px";
      }

      if (scrollPos !== currentScrollPos) {
        setScrollPos(currentScrollPos);
      }
    };
    window.onscroll = handleScroll;

    // this is necessary to prevent a React error
    // it's inefficient that a new onscroll function is set on every scroll event, but it's the only way
    return () => {
      window.onscroll = undefined;
    };
  }, [scrollPos]);

  return (
    <div id="hamburger__container">
      <nav id="hamburger__wrapper">
        <Link to="#hamburger__container">
          <button className="hamburger__to-top-arrow" ref={toTheTopArrow} />
        </Link>
        <label className="hamburger__button" htmlFor="hamburger__input" ref={hamburger}></label>
        <input type="checkbox" id="hamburger__input" ref={hamburgerInput} />
        <div
          id="hamburger__menu-container"
          onClick={() => {
            hamburgerInput.current.checked = false;
          }}
        >
          <div id="hamburger__menu">
            <Link className="hamburger__menu-item" to="/">
              Startseite
            </Link>
            <Link className="hamburger__menu-item" to="/bestellung">
              Bestellung
            </Link>
            <Link className="hamburger__menu-item" to="/heuschrecken">
              Heuschrecken
            </Link>
            <Link className="hamburger__menu-item hamburger__menu-item--small" to="/heuschrecken/liste">
              Arten
            </Link>
            <Link className="hamburger__menu-item hamburger__menu-item--small" to="/heuschrecken/rundwege">
              Rundwege
            </Link>
            <Link className="hamburger__menu-item" to="/tagfalter">
              Tagfalter
            </Link>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Hamburger;
