import React from "react";

import speciesImage from "./gryllus_campestris.jpg";
import audioFile from "./gryllus_campestris.mp3";
import audioOscillogram from "./gryllus_campestris.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 48.78,
    audioDescription: <>Wie für Langfühlerschrecken typisch, reibt die männliche Feldgrille zur Lautproduktion ihre Vorderflügel aneinander. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
  },
  audible: "A",
  daytime: "Tag/Nacht",
  months: "Mai – Juli",
  to: "gryllus_campestris",
  german: "Feldgrille",
  latin: "Gryllus campestris",
  family: "Echte Grillen (Gryllidae)",
  first: "30. 5. 1905, „Prater“",
  districts: "13 (2., 10., 11., 13., 14., 16. – 23.)",
  description: <>Kaum ein Geräusch prägt die frühsommerliche Klangkulisse im locker bebauten Siedlungsgebiet so stark mit wie der Gesang der Feldgrille. In Wien finden sich größere Vorkommen vor allem auf Extensivwiesen der Peripherie wie z.&nbsp;B. im Wienerwald oder in der Lobau.</>,
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Florin Rutschmann",
};

export default data;
