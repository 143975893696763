import React from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

import kallusweg from "../data/rundwege/2_stammersdorf/image1.jpg";
import fuchshaeufel from "../data/rundwege/4_obere_lobau/image3.jpg";

import "../css/content.scss";
import "../css/navigation.scss";

function HikeList(props) {
  return (
    <>
      <Header
        title="Heuschrecken-Rundwege – Insekten in Wien"
        logoTextSmall="Insekten in Wien"
        logoTextLarge="Heuschrecken"
        logoLink="/heuschrecken"
        links={[
          { to: "/heuschrecken/liste", active: true, text: "Arten" },
          { to: "/heuschrecken/rundwege", active: false, text: "Rundwege" },
        ]}
      />
      <div className="content__container">
        <div className="content">
          <Link
            to="/heuschrecken"
            className="nav__button"
          >
            Buch-Übersicht
          </Link>
          <figure>
            <img
              className="content__img content__img--labeled"
              src={kallusweg}
              alt="Kallusweg"
            />
            <figcaption className="content__img-text">Kallusweg, Stammersdorf. Foto:&nbsp;Günther&nbsp;Wöss</figcaption>
          </figure>
          <h1>Heuschrecken-Rundwege durch Wien</h1>
          <p>
            Erholungssuchenden bietet Wien in seinen Naturlebensräumen ein ausgedehntes Wegenetz, auf welchem man die unterschiedlichsten
            Landschaftsformen durchwandern und erkunden kann. Wir wollen Interessierten vier ausgewählte Rundwege näherbringen, auf denen
            man eine Vielzahl an Heuschreckenarten sehen und hören kann.
          </p>
          {/* <p>Sie führen uns von den Kulturlandschaften des Wienerwaldes und der nördlichen Weinbaugebiete um Stammersdorf und über die zentral gelegene Donauinsel bis in die Lobau, den Wiener Anteil des Nationalparks Donau-Auen.</p> */}
          {props.hikes.map((hike) => (
            <Link
              key={hike.short}
              className="nav__button-large"
              to={"/heuschrecken/rundwege/" + hike.to}
            >
              {hike.short}
            </Link>
          ))}
          <br />
          <figure>
            <img
              className="content__img content__img--labeled"
              src={fuchshaeufel}
              alt="Fuchshäufel"
            />
            <figcaption className="content__img-text">Fuchshäufel, Obere Lobau. Foto:&nbsp;Lorin&nbsp;Timaeus</figcaption>
          </figure>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default HikeList;
