import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./chorthippus_albomarginatus.jpg";
import audioFile from "./chorthippus_albomarginatus_004_APA.mp3";
import audioOscillogram from "./chorthippus_albomarginatus_004_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 5.185,
    audioDescription: (
      <>
        Sehr ähnlich dem Gesang des <SpeciesLink to="chorthippus_brunneus" text="Braunen Grashüpfer" after="," /> jedoch etwas weicher bzw. Ton stets etwas auf- und wieder absteigend. Hintergrundstimme: Fichtenkreuzschnabel. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Tag",
  months: "Juli – September",
  to: "chorthippus_albomarginatus",
  german: "Weißrandiger Grashüpfer",
  latin: "Chorthippus albomarginatus",
  family: "Feldheuschrecken (Acrididae)",
  first: "um 1880, Prater",
  districts: "15 (1.–3., 10.–14., 16.–19., 21.–23.)",
  description: "Am häufigsten ist der Weißrandige Grashüpfer an grasigen Weg- und Feldrainen von Ackerbaugebieten in den südlichen und nördlichen Bezirken anzutreffen.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
