import React, { useEffect, Fragment } from "react";
import { withRouter } from "react-router-dom";

// This Component is just an ugly hack to make Pages scroll to top on load
function ScrollToTop({ history, children }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return <Fragment>{children}</Fragment>;
}

export default withRouter(ScrollToTop);
