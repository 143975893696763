import React from "react";

import SpeciesLink from "../../../components/SpeciesLink";
import { PP, P1, P2, P3, P4, P5, P6, P7, P1s, P3s, P4s, P7s } from "../../../components/P";

import "../../../css/content.scss";
import "../../../css/symbols.scss";

import rundweg from "./rundweg.jpg";
import image1 from "./image1.jpg";
import image2 from "./image2.jpg";
import image3 from "./image3.jpg";
import image4 from "./image4.jpg";
import image5 from "./image5.jpg";
import pdf from "./iiw_heuschrecken_rundweg_stammersdorf.pdf";

const description = (
  <>
    <figure>
      <img className="content__img content__img-442px" src={rundweg} alt="Karte" />
      <figcaption className="content__img-author">Karte: ViennaGIS</figcaption>
    </figure>
    <h1>Durch die Weinberge bei Stammersdorf</h1>
    <p>
      <b>Autor:</b> Günther Wöss
      <br />
      <b>Streckenlänge:</b> kleine Runde 3,1&nbsp;km, große Runde 4,1&nbsp;km
      <br />
      <b>Startpunkt: </b> Parkplatz Senderstraße{" "}
      <a className="symbol__map-marker" href="https://www.google.at/maps/place/Parkplatz+Senderstrasse/@48.3109497,16.398584,17z/data=!3m1!4b1!4m5!3m4!1s0x476d0576f625636d:0x89cdc8023571c907!8m2!3d48.3109462!4d16.4007727" rel="noopener noreferrer" target="_blank">
        Google Maps Link
      </a>
    </p>
    <a className="nav__button-large-download" href={pdf} target="_blank" rel="noopener noreferrer">
      Buchkapitel als PDF herunterladen
    </a>
    <p>Diese Route führt uns durch das traditionelle Weinbaugebiet nordwestlich von Stammersdorf an den Ostabhang des Bisamberges im 21. Bezirk. Bedingt durch die abwechslungsreiche Kulturlandschaft ist hier die Artenvielfalt mit über 30 nachgewiesenen Heuschreckenarten relativ hoch.</p>
    <figure>
      <img className="content__img content__img--labeled" src={image1} alt="" />
      <figcaption className="content__img-text">
        Der <P1s t="Kallusweg" after="," /> einer der vielen Hohlwege im Gebiet. Foto:&nbsp;Günther&nbsp;Wöss
      </figcaption>
    </figure>
    <p>
      Wir starten am Parkplatz <PP t="Senderstraße" /> und gehen zunächst etwa 200&nbsp;m entlang der Stammersdorfer Kellergasse (früher Hagenbrunner Straße) nach Norden. Wer trotz des Verkehrs einen Blick in die krautig-strauchige Straßenrand­vegetation wagt, entdeckt vielleicht auf einem Blatt bereits eine <SpeciesLink to="leptophyes_punctatissima" text="Punktierte Zartschrecke" after="," /> deren Männchen besonders farbenfroh sind. Schließlich biegen wir links in den <P1 t="Kallusweg" /> ein, einen für das Gebiet typischen Hohlweg. Spätestens hier pfeifen uns die ersten Ziesel um die Ohren; sie sind aus nächster Nähe gut zu beobachten. Auf der Hohlwegböschung singen der wie eine Sprinkleranlage klingende <SpeciesLink to="chorthippus_apricarius" text="Feldgrashüpfer" after="," /> der häufige <SpeciesLink to="pseudochorthippus_parallelus" text="Gemeine Grashüpfer" /> und die stotternd-sirrende <SpeciesLink to="bicolorana_bicolor" text="Zweifarbige Beißschrecke" /> – drei Arten, die uns auch auf der weiteren Route begleiten werden.
    </p>
    <p>
      Nach etwa 250&nbsp;m nehmen wir rechterhand einen <P2 t="Feldweg" after="." /> Im krautigen Saum der ersten 150&nbsp;m dieses Weges stellen sich einige weitere Arten wie die <SpeciesLink to="chrysochraon_dispar" text="Große" /> und die <SpeciesLink to="euthystira_brachyptera" text="Kleine Goldschrecke" /> ein. Anschließend durchwandern wir die Weinberge. Im Mai und Juni vernimmt man hier das melodiöse Zirpen der <SpeciesLink to="gryllus_campestris" text="Feldgrille" after="," /> in den Hochsommermonaten findet man außerdem die drei häufigen, sich im Aussehen ähnelnden Arten der sogenannten <i>Chorthippus biguttulus</i>-Gruppe. Sie lassen sich am besten anhand ihres Gesangs unterscheiden: Während der <SpeciesLink to="chorthippus_brunneus" text="Braune Grashüpfer" /> durch kurze Einzeltöne auffällt, erinnert der <SpeciesLink to="chorthippus_biguttulus" text="Nachtigall-Grashüpfer" /> an das Rasseln einer Klapperschlange. Der <SpeciesLink to="chorthippus_mollis" text="Verkannte Grashüpfer" /> schließlich klingt wie eine langsame, sich gegen Ende der Gesangsstrophe einbremsende Dampf­lokomotive. An den grasigen Wegrändern sollte man auch auf den unscheinbaren <SpeciesLink to="chorthippus_albomarginatus" text="Weißrandigen Grashüpfer" /> achten, der in einer braunen und in einer grünen Farbvariante auftritt. Bei einem Abstecher in die Weingärten kann man mit etwas Ausdauer die <SpeciesLink to="phaneroptera_nana" text="Vierpunktige Sichelschrecke" /> finden, wenn sie sich auf den Weinblättern sonnt. Die letzten Meter dieses etwa 400&nbsp;m langen Wegabschnittes weisen vegetationslose Schotterflächen auf, und spätestens hier fällt einem die <SpeciesLink to="calliptamus_italicus" text="Italienische Schönschrecke" /> auf, die bei ihren langen Fluchtsprüngen ihre rötlichen Hinterflügel präsentiert.
    </p>
    <p>Man stößt nun auf eine T-Kreuzung und biegt nach links auf den Wiener Stadtwanderweg 5 ab, der die Grenze zu Niederösterreich darstellt und auf den Falkenberg führt. Wendet man sich zurück, bietet sich ein weiter Blick in das Marchfeld, der bis in die Slowakei zu den Kleinen Karpaten reicht.</p>
    <figure>
      <img className="content__img content__img--labeled" src={image2} alt="" />
      <figcaption className="content__img-text">
        Der verbuschende <P3s t="Halbtrockenrasenhang" /> ist der „Hotspot“ der Route. Foto:&nbsp;Günther&nbsp;Wöss
      </figcaption>
    </figure>
    <p>
      Wo nach ca. 350&nbsp;m die Weingärten enden, erreichen wir den „Hotspot“ unserer <P3 t="Tour" after="." /> Südlich des Stadtwanderweges erstreckt sich ein verbuschender Halbtrockenrasenhang, auf dem uns um die 15 Heuschreckenarten erwarten – neben den beiden Goldschrecken etwa die in Wien hauptsächlich am Stadtrand vorkommende <SpeciesLink to="phaneroptera_falcata" text="Gemeine Sichelschrecke" after="," /> die gerne auf Blüten sitzende <SpeciesLink to="leptophyes_albovittata" text="Gestreifte Zartschrecke" /> und der <SpeciesLink to="chorthippus_dorsatus" text="Wiesengrashüpfer" after="." /> Auch ist man hier im Lebensraum der allseits bekannten <SpeciesLink to="mantis_religiosa" text="Gottesanbeterin" after="." /> Durch den Wiesenhang führt ein Pfad, auf dessen schütteren Stellen die <SpeciesLink to="calliptamus_italicus" text="Italienische Schönschrecke" /> und die <SpeciesLink to="oedipoda_caerulescens" text="Blauflügelige Ödlandschrecke" /> umherspringen, zwei ausgesprochene Rohboden-Spezialisten. In kurzrasigen Bereichen kann man außerdem auf den <SpeciesLink to="stenobothrus_lineatus" text="Großen Heidegrashüpfer" /> treffen, der mit seinem leisen, wellenartig-zischenden Gesang auf sich aufmerksam macht.
    </p>
    <figure>
      <img className="content__img content__img--labeled" src={image3} alt="" />
      <figcaption className="content__img-text">
        Selbst <P4s t="Kinderspielplätze" /> können interessante Heuschrecken­gemeinschaften beherbergen, wenn sie naturbelassen sind. Foto:&nbsp;Günther&nbsp;Wöss
      </figcaption>
    </figure>
    <p>
      Wir begeben uns zurück auf den Stadtwanderweg und folgen ihm hangaufwärts durch Eichenwälder, die nach der anstrengenden Heuschreckensuche angenehmen Schatten spenden. Dieser Wegabschnitt ist relativ artenarm, doch mit Glück findet man auf Blättern die farblich variable <SpeciesLink to="barbitistes_serricauda" text="Laubholz-Säbelschrecke" after="." /> Wer genau hinhört, vernimmt aus dem Wald auch das leise Morsen der <SpeciesLink to="nemobius_sylvestris" text="Waldgrille" after="," /> die hier neben der <SpeciesLink to="pholidoptera_griseoaptera" text="Gewöhnlichen Strauchschrecke" /> die häufigste Art ist. Dem Weg durch den Wald folgen wir für etwa 500&nbsp;m und sehen durch die links liegende Hecke immer wieder Wiesen und Weingärten durchblitzen. Auf der anderen Seite der Hecke finden wir nach einem großen Weingarten eine acker­brachenähnliche Wiese mit einem kleinen Kinderspielplatz <P4 t="vor" after="." /> Neben bereits bekannten Arten hört man hier das hohe Sirren von <SpeciesLink to="roeseliana_roeselii" text="Roesels Beißschrecke" after="," /> und auch der <SpeciesLink to="euchorthippus_declivus" text="Dickkopf-Grashüpfer" after="," /> der durch seinen verhältnismäßig großen, gestreiften Kopf auffällt, hält sich in der Vegetation auf. Sucht man die lückigen Wiesenbereiche genau ab, springt einem vielleicht eine <SpeciesLink to="tetrix_tenuicornis" text="Langfühler-Dornschrecke" /> vor die Füße.
    </p>
    <p>
      Am Südrand der Spielwiese trifft man wieder auf den Kallusweg, und wer ein kühles Getränk bei einem der zahlreichen Heurigen nicht mehr erwarten kann, nimmt diesen Weg nach Osten, um hangabwärts wieder zum Parkplatz Senderstraße zurückzukehren. Kulturinteressierten sei jedoch der <P5 t="Gedenkstein" /> zu Ehren des berühmten schlesischen Lyrikers Joseph von Eichendorff (1788–1857) empfohlen, den man nach kurzem Wegmarsch Richtung Westen erreicht. Die auf dem Gedenkstein eingemeißelten Verse aus dem Gedicht „An der Grenze“ lassen sich gut nachempfinden, wenn man den weiten Ausblick über Wien genießt.
    </p>
    <figure>
      <img className="content__img content__img--labeled" src={image4} alt="" />
      <figcaption className="content__img-text">
        Romantischer Weinkeller in der <P7s t="Senderstraße" after="." /> Foto:&nbsp;Günther&nbsp;Wöss
      </figcaption>
    </figure>
    <p>
      Nun schlagen wir den Weg nach Süden ein und gelangen zum Magdalenenhof, wo einer Einkehr nichts mehr im Wege steht. Hier liegt auch der von Wasser­linsen bedeckte <P6 t="Senderteich" after="," /> an dem Franz Werner in den 1920er-Jahren noch die <SpeciesLink to="gryllotalpa_gryllotalpa" text="Maulwurfsgrille" /> nachweisen konnte. Der weitere Verlauf der Senderstraße führt durch einen breiten Hohlweg mit einer romantischen Kellergasse. Das hohe Alter der Weinlager ist nicht zu übersehen, scheint sich der Hohlweg doch die efeuverhangenen Eingänge bereits vollständig einverleibt zu <P7 t="haben" after="." />
    </p>
    <p>Schließlich gelangen wir wieder zum Parkplatz Senderstraße, dem Ausgangs- und Endpunkt unserer abwechslungsreichen Route.</p>
    <figure>
      <img className="content__img content__img--labeled" src={image5} alt="" />
      <figcaption className="content__img-text">
        Als typische Art verbrachender Halbtrockenrasen ist die <SpeciesLink to="euthystira_brachyptera" text="Kleine Goldschrecke" /> am <P3s t="Standort" /> häufig anzutreffen. Foto:&nbsp;Günther&nbsp;Wöss
      </figcaption>
    </figure>
  </>
);

export default description;
