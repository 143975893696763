import React from "react";

import speciesImage from "./chorthippus_pullus.jpg";
import audioFile from "./chorthippus_pullus_001_APA.mp3";
import audioOscillogram from "./chorthippus_pullus_001_APA.png";
import audioFile2 from "./chorthippus_pullus_Balz_005_APA.mp3";
import audioOscillogram2 from "./chorthippus_pullus_Balz_005_APA.png";

const data = {
  released: true,
  audioData: [
    {
      audioFile: audioFile,
      audioOscillogram: audioOscillogram,
      audioDuration: 3.485,
      audioDescription: <>Der auffällige Gesang des kleinen Grashüpfers war in Wien zuletzt wohl vor über 100 Jahren zu hören. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
    },
    {
      audioFile: audioFile2,
      audioOscillogram: audioOscillogram2,
      audioDuration: 4.541,
      audioDescription: <>Balzgesang. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
    },
  ],
  audible: "A",
  daytime: "Tag",
  months: "Juli – September",
  to: "chorthippus_pullus",
  german: "Kiesbank-Grashüpfer",
  latin: "Chorthippus pullus",
  family: "Feldheuschrecken (Acrididae)",
  only: "Nachweis: 20.8.1904, Zierleiten",
  districts: "1 (19.)",
  description: "Der hoch anspruchsvolle Kiesbank-Grashüpfer wurde in Wien nur historisch von einem einzigen Standort gemeldet. Auch in der weiteren Umgebung der Bundeshauptstadt gibt es keine aktuellen Nachweise.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
