import React from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

import "../css/content.scss";
import "../css/navigation.scss";

import heuschreckenBuch from "../data/buch_iiw_heuschrecken_seitlich.jpg";
import tagfalterBuch from "../data/buch_iiw_tagfalter_seitlich.jpg";

function Order(props) {
  return (
    <>
      <Header
        title="Buchbestellung – Insekten in Wien"
        logoTextSmall="&nbsp;"
        logoTextLarge="Insekten in Wien"
        logoLink="/"
        links={[]}
      />
      <div className="content__container">
        <div className="content">
          <Link
            to="/"
            className="nav__button"
          >
            zur Startseite
          </Link>
          <div>
            <Link to="/heuschrecken">
              <img
                className="content__img-book content__img-book--halfsize"
                src={heuschreckenBuch}
                alt=""
              />
            </Link>
            <Link to="/tagfalter">
              <img
                className="content__img-book content__img-book--halfsize"
                src={tagfalterBuch}
                alt=""
              />
            </Link>
          </div>
          <h1>Buchbestellungen</h1>
          <p>
            Für Bestellungen schicken Sie bitte eine Email mit der Betreffzeile "Buchbestellung" und den folgenden Informationen an{" "}
            <a href="mailto:werner.reitmeier@gmx.at?subject=Buchbestellung">werner.reitmeier@gmx.at</a>:
          </p>
          <ul>
            <li>Gewünschte Buchtitel und Stückzahl</li>
            <li>ÖGEF-Mitgliedschaft (für&nbsp;Ermäßigung)</li>
            <li>Name</li>
            <li>Adresse</li>
          </ul>
          <h1>Preisliste</h1>
          <ul>
            <li>
              <b>Insekten in Wien – Heuschrecken:</b>
              <br />
              29&nbsp;€&nbsp;(ÖGEF-Mitglieder:&nbsp;22&nbsp;€)
            </li>
            <li>
              <b>Insekten in Wien – Tagfalter:</b> <em>Vergriffen</em>
            </li>
            <li>Zusätzlich fallen Verpackungs- und Versandkosten an.</li>
          </ul>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Order;
