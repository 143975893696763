import React from "react";

import speciesImage from "./chorthippus_brunneus.jpg";
import audioFile from "./chorthippus_brunneus_002_APA.mp3";
import audioOscillogram from "./chorthippus_brunneus_002_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 13.328,
    audioDescription: <>Nahezu stets auf schütter bewachsenen oder offenen Bodenstellen sind die kurzen, im Abstand von wenigen Sekunden vorgetragenen „trrt“-Laute des Braunen Grashüpfers allgegenwärtig. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
  },
  audible: "A",
  daytime: "Tag",
  months: "Juni – September",
  to: "chorthippus_brunneus",
  german: "Brauner Grashüpfer",
  latin: "Chorthippus brunneus",
  family: "Feldheuschrecken (Acrididae)",
  first: "1915, „Wiener Stadtgebiet“",
  districts: "21 (1.–3., 5., 7.–23.)",
  description: "Als anspruchslose Rohbodenart ist der Braune Grashüpfer nahezu lückenlos in Wien anzutreffen, wobei er als Pionierart selbst frisch entstandene Habitate wie Baustellenbereiche oder neue Deponieflächen rasch besiedeln kann.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
