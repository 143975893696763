export const alphabetical = [
  {
    key: "pholidoptera_aptera",
    searchList: ["alpen-strauchschrecke", "pholidoptera aptera", "strauchschrecke", "aptera"],
    german: "Alpen-Strauchschrecke",
    latin: "Pholidoptera aptera",
  },
  {
    key: "miramella_alpina",
    searchList: ["alpine gebirgsschrecke", "miramella alpina", "gebirgsschrecke", "alpina"],
    german: "Alpine Gebirgsschrecke",
    latin: "Miramella alpina",
  },
  {
    key: "myrmecophilus_acervorum",
    searchList: ["ameisengrille", "myrmecophilus acervorum", "acervorum"],
    german: "Ameisengrille",
    latin: "Myrmecophilus acervorum",
  },
  {
    key: "oedipoda_caerulescens",
    searchList: ["blauflugelige odlandschrecke", "oedipoda caerulescens", "odlandschrecke", "caerulescens"],
    german: "Blauflügelige Ödlandschrecke",
    latin: "Oedipoda caerulescens",
  },
  {
    key: "sphingonotus_caerulans",
    searchList: ["blauflugelige sandschrecke", "sphingonotus caerulans", "sandschrecke", "caerulans"],
    german: "Blauflügelige Sandschrecke",
    latin: "Sphingonotus caerulans",
  },
  {
    key: "tetrix_bolivari",
    searchList: ["bolivars dornschrecke", "tetrix bolivari", "dornschrecke", "bolivari"],
    german: "Bolivars Dornschrecke",
    latin: "Tetrix bolivari",
  },
  {
    key: "chorthippus_brunneus",
    searchList: ["brauner grashupfer", "chorthippus brunneus", "grashupfer", "brunneus"],
    german: "Brauner Grashüpfer",
    latin: "Chorthippus brunneus",
  },
  {
    key: "omocestus_rufipes",
    searchList: ["buntbauchiger grashupfer", "omocestus rufipes", "grashupfer", "rufipes"],
    german: "Buntbäuchiger Grashüpfer",
    latin: "Omocestus rufipes",
  },
  {
    key: "omocestus_viridulus",
    searchList: ["bunter grashupfer", "omocestus viridulus", "grashupfer", "viridulus"],
    german: "Bunter Grashüpfer",
    latin: "Omocestus viridulus",
  },
  {
    key: "euchorthippus_declivus",
    searchList: ["dickkopf-grashupfer", "euchorthippus declivus", "grashupfer", "declivus"],
    german: "Dickkopf-Grashüpfer",
    latin: "Euchorthippus declivus",
  },
  {
    key: "mantis_religiosa",
    searchList: ["europaische gottesanbeterin", "mantis religiosa", "gottesanbeterin", "religiosa"],
    german: "Europäische Gottesanbeterin",
    latin: "Mantis religiosa",
  },
  {
    key: "locusta_migratoria",
    searchList: ["europaische wanderheuschrecke", "locusta migratoria", "wanderheuschrecke", "migratoria"],
    german: "Europäische Wanderheuschrecke",
    latin: "Locusta migratoria",
  },
  {
    key: "chorthippus_apricarius",
    searchList: ["feldgrashupfer", "chorthippus apricarius", "apricarius"],
    german: "Feldgrashüpfer",
    latin: "Chorthippus apricarius",
  },
  {
    key: "gryllus_campestris",
    searchList: ["feldgrille", "gryllus campestris", "campestris"],
    german: "Feldgrille",
    latin: "Gryllus campestris",
  },
  {
    key: "isophya_camptoxypha",
    searchList: ["fiebers plumpschrecke", "isophya camptoxypha", "plumpschrecke", "camptoxypha"],
    german: "Fiebers Plumpschrecke",
    latin: "Isophya camptoxypha",
  },
  {
    key: "xya_variegata",
    searchList: ["gefleckte grabschrecke", "xya variegata", "grabschrecke", "variegata"],
    german: "Gefleckte Grabschrecke",
    latin: "Xya variegata",
  },
  {
    key: "myrmeleotettix_maculatus",
    searchList: ["gefleckte keulenschrecke", "myrmeleotettix maculatus", "keulenschrecke", "maculatus"],
    german: "Gefleckte Keulenschrecke",
    latin: "Myrmeleotettix maculatus",
  },
  {
    key: "leptophyes_boscii",
    searchList: ["gelbstreifige zartschrecke", "leptophyes boscii", "zartschrecke", "boscii"],
    german: "Gelbstreifige Zartschrecke",
    latin: "Leptophyes boscii",
  },
  {
    key: "tetrix_undulata",
    searchList: ["gemeine dornschrecke", "tetrix undulata", "dornschrecke", "undulata"],
    german: "Gemeine Dornschrecke",
    latin: "Tetrix undulata",
  },
  {
    key: "meconema_thalassinum",
    searchList: ["gemeine eichenschrecke", "meconema thalassinum", "eichenschrecke", "thalassinum"],
    german: "Gemeine Eichenschrecke",
    latin: "Meconema thalassinum",
  },
  {
    key: "phaneroptera_falcata",
    searchList: ["gemeine sichelschrecke", "phaneroptera falcata", "sichelschrecke", "falcata"],
    german: "Gemeine Sichelschrecke",
    latin: "Phaneroptera falcata",
  },
  {
    key: "pseudochorthippus_parallelus",
    searchList: ["gemeiner grashupfer", "pseudochorthippus parallelus", "grashupfer", "parallelus"],
    german: "Gemeiner Grashüpfer",
    latin: "Pseudochorthippus parallelus",
  },
  {
    key: "leptophyes_albovittata",
    searchList: ["gestreifte zartschrecke", "leptophyes albovittata", "zartschrecke", "albovittata"],
    german: "Gestreifte Zartschrecke",
    latin: "Leptophyes albovittata",
  },
  {
    key: "diestrammena_asynamora",
    searchList: ["gewachshausschrecke", "diestrammena asynamora", "asynamora"],
    german: "Gewächshausschrecke",
    latin: "Diestrammena asynamora",
  },
  {
    key: "odontopodisma_decipiens",
    searchList: ["gewohnliche grunschrecke", "odontopodisma decipiens", "grunschrecke", "decipiens"],
    german: "Gewöhnliche Grünschrecke",
    latin: "Odontopodisma decipiens",
  },
  {
    key: "acrida_ungarica",
    searchList: ["gewohnliche nasenschrecke", "acrida ungarica", "nasenschrecke", "ungarica"],
    german: "Gewöhnliche Nasenschrecke",
    latin: "Acrida ungarica",
  },
  {
    key: "pholidoptera_griseoaptera",
    searchList: ["gewohnliche strauchschrecke", "pholidoptera griseoaptera", "strauchschrecke", "griseoaptera"],
    german: "Gewöhnliche Strauchschrecke",
    latin: "Pholidoptera griseoaptera",
  },
  {
    key: "platycleis_grisea",
    searchList: ["graue beisschrecke", "platycleis grisea", "beisschrecke", "grisea"],
    german: "Graue Beißschrecke",
    latin: "Platycleis grisea",
  },
  {
    key: "chrysochraon_dispar",
    searchList: ["grose goldschrecke", "chrysochraon dispar", "goldschrecke", "dispar"],
    german: "Große Goldschrecke",
    latin: "Chrysochraon dispar",
  },
  {
    key: "arcyptera_fusca",
    searchList: ["grose hockerschrecke", "arcyptera fusca", "hockerschrecke", "fusca"],
    german: "Große Höckerschrecke",
    latin: "Arcyptera fusca",
  },
  {
    key: "isophya_modestior",
    searchList: ["grose plumpschrecke", "isophya modestior", "plumpschrecke", "modestior"],
    german: "Große Plumpschrecke",
    latin: "Isophya modestior",
  },
  {
    key: "saga_pedo",
    searchList: ["grose sageschrecke", "saga pedo", "sageschrecke", "pedo"],
    german: "Große Sägeschrecke",
    latin: "Saga pedo",
  },
  {
    key: "ruspolia_nitidula",
    searchList: ["grose schiefkopfschrecke", "ruspolia nitidula", "schiefkopfschrecke", "nitidula"],
    german: "Große Schiefkopfschrecke",
    latin: "Ruspolia nitidula",
  },
  {
    key: "stenobothrus_lineatus",
    searchList: ["groser heidegrashupfer", "stenobothrus lineatus", "heidegrashupfer", "lineatus"],
    german: "Großer Heidegrashüpfer",
    latin: "Stenobothrus lineatus",
  },
  {
    key: "aiolopus_thalassinus",
    searchList: ["grune strandschrecke", "aiolopus thalassinus", "strandschrecke", "thalassinus"],
    german: "Grüne Strandschrecke",
    latin: "Aiolopus thalassinus",
  },
  {
    key: "tettigonia_viridissima",
    searchList: ["grunes heupferd", "tettigonia viridissima", "heupferd", "viridissima"],
    german: "Grünes Heupferd",
    latin: "Tettigonia viridissima",
  },
  {
    key: "gampsocleis_glabra",
    searchList: ["heideschrecke", "gampsocleis glabra", "glabra"],
    german: "Heideschrecke",
    latin: "Gampsocleis glabra",
  },
  {
    key: "acheta_domesticus",
    searchList: ["heimchen", "acheta domesticus", "domesticus"],
    german: "Heimchen",
    latin: "Acheta domesticus",
  },
  {
    key: "calliptamus_italicus",
    searchList: ["italienische schonschrecke", "calliptamus italicus", "schonschrecke", "italicus"],
    german: "Italienische Schönschrecke",
    latin: "Calliptamus italicus",
  },
  {
    key: "chorthippus_pullus",
    searchList: ["kiesbank-grashupfer", "chorthippus pullus", "grashupfer", "pullus"],
    german: "Kiesbank-Grashüpfer",
    latin: "Chorthippus pullus",
  },
  {
    key: "tessellana_veyseli",
    searchList: ["kleine beisschrecke", "tessellana veyseli", "beisschrecke", "veyseli"],
    german: "Kleine Beißschrecke",
    latin: "Tessellana veyseli",
  },
  {
    key: "euthystira_brachyptera",
    searchList: ["kleine goldschrecke", "euthystira brachyptera", "goldschrecke", "brachyptera"],
    german: "Kleine Goldschrecke",
    latin: "Euthystira brachyptera",
  },
  {
    key: "pezotettix_giornae",
    searchList: ["kleine knarrschrecke", "pezotettix giornae", "knarrschrecke", "giornae"],
    german: "Kleine Knarrschrecke",
    latin: "Pezotettix giornae",
  },
  {
    key: "stenobothrus_stigmaticus",
    searchList: ["kleiner heidegrashupfer", "stenobothrus stigmaticus", "heidegrashupfer", "stigmaticus"],
    german: "Kleiner Heidegrashüpfer",
    latin: "Stenobothrus stigmaticus",
  },
  {
    key: "tetrix_kraussi",
    searchList: ["kurzflugel-dornschrecke", "tetrix kraussi", "dornschrecke", "kraussi"],
    german: "Kurzflügel-Dornschrecke",
    latin: "Tetrix kraussi",
  },
  {
    key: "gryllodes_sigillatus",
    searchList: ["kurzflugelgrille", "gryllodes sigillatus", "sigillatus"],
    german: "Kurzflügelgrille",
    latin: "Gryllodes sigillatus",
  },
  {
    key: "metrioptera_brachyptera",
    searchList: ["kurzflugelige beisschrecke", "metrioptera brachyptera", "beisschrecke", "brachyptera"],
    german: "Kurzflügelige Beißschrecke",
    latin: "Metrioptera brachyptera",
  },
  {
    key: "conocephalus_dorsalis",
    searchList: ["kurzflugelige schwertschrecke", "conocephalus dorsalis", "schwertschrecke", "dorsalis"],
    german: "Kurzflügelige Schwertschrecke",
    latin: "Conocephalus dorsalis",
  },
  {
    key: "conocephalus_fuscus",
    searchList: ["langflugelige schwertschrecke", "conocephalus fuscus", "schwertschrecke", "fuscus"],
    german: "Langflügelige Schwertschrecke",
    latin: "Conocephalus fuscus",
  },
  {
    key: "tetrix_tenuicornis",
    searchList: ["langfuhler-dornschrecke", "tetrix tenuicornis", "dornschrecke", "tenuicornis"],
    german: "Langfühler-Dornschrecke",
    latin: "Tetrix tenuicornis",
  },
  {
    key: "barbitistes_serricauda",
    searchList: ["laubholz-sabelschrecke", "barbitistes serricauda", "sabelschrecke", "serricauda"],
    german: "Laubholz-Säbelschrecke",
    latin: "Barbitistes serricauda",
  },
  {
    key: "mecostethus_parapleurus",
    searchList: ["lauchschrecke", "mecostethus parapleurus", "parapleurus"],
    german: "Lauchschrecke",
    latin: "Mecostethus parapleurus",
  },
  {
    key: "gryllotalpa_gryllotalpa",
    searchList: ["maulwurfsgrille", "gryllotalpa gryllotalpa", "gryllotalpa"],
    german: "Maulwurfsgrille",
    latin: "Gryllotalpa gryllotalpa",
  },
  {
    key: "chorthippus_biguttulus",
    searchList: ["nachtigall-grashupfer", "chorthippus biguttulus", "grashupfer", "biguttulus"],
    german: "Nachtigall-Grashüpfer",
    latin: "Chorthippus biguttulus",
  },
  {
    key: "modicogryllus_frontalis",
    searchList: ["ostliche grille", "modicogryllus frontalis", "grille", "frontalis"],
    german: "Östliche Grille",
    latin: "Modicogryllus frontalis",
  },
  {
    key: "tettigonia_caudata",
    searchList: ["ostliches heupferd", "tettigonia caudata", "heupferd", "caudata"],
    german: "Östliches Heupferd",
    latin: "Tettigonia caudata",
  },
  {
    key: "xya_pfaendleri",
    searchList: ["pfandlers grabschrecke", "xya pfaendleri", "grabschrecke", "pfaendleri"],
    german: "Pfändlers Grabschrecke",
    latin: "Xya pfaendleri",
  },
  {
    key: "leptophyes_punctatissima",
    searchList: ["punktierte zartschrecke", "leptophyes punctatissima", "zartschrecke", "punctatissima"],
    german: "Punktierte Zartschrecke",
    latin: "Leptophyes punctatissima",
  },
  {
    key: "roeseliana_roeselii",
    searchList: ["roesels beisschrecke", "roeseliana roeselii", "beisschrecke", "roeselii"],
    german: "Roesels Beißschrecke",
    latin: "Roeseliana roeselii",
  },
  {
    key: "gomphocerippus_rufus",
    searchList: ["rote keulenschrecke", "gomphocerippus rufus", "keulenschrecke", "rufus"],
    german: "Rote Keulenschrecke",
    latin: "Gomphocerippus rufus",
  },
  {
    key: "psophus_stridulus",
    searchList: ["rotflugelige schnarrschrecke", "psophus stridulus", "schnarrschrecke", "stridulus"],
    german: "Rotflügelige Schnarrschrecke",
    latin: "Psophus stridulus",
  },
  {
    key: "omocestus_haemorrhoidalis",
    searchList: ["rotleibiger grashupfer", "omocestus haemorrhoidalis", "grashupfer", "haemorrhoidalis"],
    german: "Rotleibiger Grashüpfer",
    latin: "Omocestus haemorrhoidalis",
  },
  {
    key: "tetrix_subulata",
    searchList: ["sabeldornschrecke", "tetrix subulata", "subulata"],
    german: "Säbeldornschrecke",
    latin: "Tetrix subulata",
  },
  {
    key: "eupholidoptera_schmidti",
    searchList: ["schmidts grune strauchschrecke", "eupholidoptera schmidti", "grune", "strauchschrecke", "schmidti"],
    german: "Schmidts Grüne Strauchschrecke",
    latin: "Eupholidoptera schmidti",
  },
  {
    key: "stenobothrus_nigromaculatus",
    searchList: ["schwarzfleckiger heidegrashupfer", "stenobothrus nigromaculatus", "heidegrashupfer", "nigromaculatus"],
    german: "Schwarzfleckiger Heidegrashüpfer",
    latin: "Stenobothrus nigromaculatus",
  },
  {
    key: "ephippiger_ephippiger",
    searchList: ["steppen-sattelschrecke", "ephippiger ephippiger", "sattelschrecke", "ephippiger"],
    german: "Steppen-Sattelschrecke",
    latin: "Ephippiger ephippiger",
  },
  {
    key: "chorthippus_vagans",
    searchList: ["steppengrashupfer", "chorthippus vagans", "vagans"],
    german: "Steppengrashüpfer",
    latin: "Chorthippus vagans",
  },
  {
    key: "meconema_meridionale",
    searchList: ["sudliche eichenschrecke", "meconema meridionale", "eichenschrecke", "meridionale"],
    german: "Südliche Eichenschrecke",
    latin: "Meconema meridionale",
  },
  {
    key: "eumodicogryllus_bordigalensis",
    searchList: ["sudliche grille", "eumodicogryllus bordigalensis", "grille", "bordigalensis"],
    german: "Südliche Grille",
    latin: "Eumodicogryllus bordigalensis",
  },
  {
    key: "pholidoptera_fallax",
    searchList: ["sudliche strauchschrecke", "pholidoptera fallax", "strauchschrecke", "fallax"],
    german: "Südliche Strauchschrecke",
    latin: "Pholidoptera fallax",
  },
  {
    key: "pseudochorthippus_montanus",
    searchList: ["sumpfgrashupfer", "pseudochorthippus montanus", "montanus"],
    german: "Sumpfgrashüpfer",
    latin: "Pseudochorthippus montanus",
  },
  {
    key: "pteronemobius_heydenii",
    searchList: ["sumpfgrille", "pteronemobius heydenii", "heydenii"],
    german: "Sumpfgrille",
    latin: "Pteronemobius heydenii",
  },
  {
    key: "stethophyma_grossum",
    searchList: ["sumpfschrecke", "stethophyma grossum", "grossum"],
    german: "Sumpfschrecke",
    latin: "Stethophyma grossum",
  },
  {
    key: "tetrix_tuerki",
    searchList: ["turks dornschrecke", "tetrix tuerki", "dornschrecke", "tuerki"],
    german: "Türks Dornschrecke",
    latin: "Tetrix tuerki",
  },
  {
    key: "chorthippus_mollis",
    searchList: ["verkannter grashupfer", "chorthippus mollis", "grashupfer", "mollis"],
    german: "Verkannter Grashüpfer",
    latin: "Chorthippus mollis",
  },
  {
    key: "phaneroptera_nana",
    searchList: ["vierpunktige sichelschrecke", "phaneroptera nana", "sichelschrecke", "nana"],
    german: "Vierpunktige Sichelschrecke",
    latin: "Phaneroptera nana",
  },
  {
    key: "nemobius_sylvestris",
    searchList: ["waldgrille", "nemobius sylvestris", "sylvestris"],
    german: "Waldgrille",
    latin: "Nemobius sylvestris",
  },
  {
    key: "polysarcus_denticauda",
    searchList: ["wanstschrecke", "polysarcus denticauda", "denticauda"],
    german: "Wanstschrecke",
    latin: "Polysarcus denticauda",
  },
  {
    key: "decticus_verrucivorus",
    searchList: ["warzenbeiser", "decticus verrucivorus", "verrucivorus"],
    german: "Warzenbeißer",
    latin: "Decticus verrucivorus",
  },
  {
    key: "oecanthus_pellucens",
    searchList: ["weinhahnchen", "oecanthus pellucens", "pellucens"],
    german: "Weinhähnchen",
    latin: "Oecanthus pellucens",
  },
  {
    key: "chorthippus_albomarginatus",
    searchList: ["weisrandiger grashupfer", "chorthippus albomarginatus", "grashupfer", "albomarginatus"],
    german: "Weißrandiger Grashüpfer",
    latin: "Chorthippus albomarginatus",
  },
  {
    key: "tetrix_ceperoi",
    searchList: ["westliche dornschrecke", "tetrix ceperoi", "dornschrecke", "ceperoi"],
    german: "Westliche Dornschrecke",
    latin: "Tetrix ceperoi",
  },
  {
    key: "chorthippus_dorsatus",
    searchList: ["wiesengrashupfer", "chorthippus dorsatus", "dorsatus"],
    german: "Wiesengrashüpfer",
    latin: "Chorthippus dorsatus",
  },
  {
    key: "bicolorana_bicolor",
    searchList: ["zweifarbige beisschrecke", "bicolorana bicolor", "beisschrecke", "bicolor"],
    german: "Zweifarbige Beißschrecke",
    latin: "Bicolorana bicolor",
  },
  {
    key: "stenobothrus_crassipes",
    searchList: ["zwerg-heidegrashupfer", "stenobothrus crassipes", "heidegrashupfer", "crassipes"],
    german: "Zwerg-Heidegrashüpfer",
    latin: "Stenobothrus crassipes",
  },
  {
    key: "tettigonia_cantans",
    searchList: ["zwitscher-heupferd", "tettigonia cantans", "heupferd", "cantans"],
    german: "Zwitscher-Heupferd",
    latin: "Tettigonia cantans",
  },
]; 