import React from "react";

import "../css/AudioPlayer.scss";

// calculate tick values for time bar
export function generateTicks(duration) {
  const candidates = [0.1, 0.5, 1, 2, 5, 10, 20, 30, 60];
  let tickSize = 0;

  //no more than 5 ticks
  for (let i = 0; i < candidates.length; i++) {
    if (duration / candidates[i] <= 5) {
      tickSize = candidates[i];
      break;
    }
  }

  const tickCount = Math.floor(duration / tickSize);
  const tickPercentage = (tickSize / duration) * 100;

  let tickLabels = [];
  for (let i = 0; i < tickCount + 1; i++) {
    tickLabels.push(i * tickSize.toFixed(1) + "s");
  }

  return { tickCount, tickPercentage, tickLabels };
}

export function AudioTicks(props) {
  if (!props.audioTicks) {
    return <></>;
  }

  return (
    <div className="audioplayer__ticks-container">
      <div className="audioplayer__ticks">
        {props.audioTicks.tickLabels.map((label, index) => (
          <div
            key={label}
            style={{
              width: index === props.audioTicks.tickCount ? "auto" : props.audioTicks.tickPercentage + "%",
            }}
            className="audioplayer__tick"
          >
            {label}
          </div>
        ))}
      </div>
    </div>
  );
}
