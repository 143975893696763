import React from "react";

import speciesImage from "./chorthippus_mollis.jpg";
import audioFile from "./chorthippus_mollis.mp3";
import audioOscillogram from "./chorthippus_mollis.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 24.056,
    audioDescription: <>Die Gesangsstrophe erinnert an eine langsamer werdende und schließlich stehen bleibende Dampflokomotive. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
  },
  audible: "A",
  daytime: "Tag",
  months: "Juli – Oktober",
  to: "chorthippus_mollis",
  german: "Verkannter Grashüpfer",
  latin: "Chorthippus mollis",
  family: "Feldheuschrecken (Acrididae)",
  first: "25. 9. 1947, Kalksburg",
  districts: "18 (2., 3., 5., 9. – 23.)",
  description: "Der Verkannte Grashüpfer ist auf den Osten Österreichs konzentriert und in Wien in trockenen, grasigen Habitaten häufig. Die inneren Stadtbezirke werden jedoch weitgehend gemieden.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
