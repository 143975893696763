import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./stenobothrus_lineatus.jpg";
import audioFile1 from "./grosser_heidegrashuepfer_gesang.mp3";
import audioOscillogram1 from "./osc_gesang.png";
import audioFile2 from "./grosser_heidegrashuepfer_balz.mp3";
import audioOscillogram2 from "./osc_balz.png";

const data = {
  released: true,
  audioData: [
    {
      audioFile: audioFile1,
      audioOscillogram: audioOscillogram1,
      audioDuration: 15.558,
      audioDescription: <>Der leise aber dennoch gut hörbare, "waschmaschinenähnliche" Spontangesang besteht aus an- und abschwellenden Tönen. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
    },
    {
      audioFile: audioFile2,
      audioOscillogram: audioOscillogram2,
      audioDuration: 11.002,
      audioDescription: (
        <>
          Der Balzgesang ist aus sehr leisen, tickenden Einzeltönen aufgebaut. Dieser ist dem Spontangesang der <SpeciesLink to="metrioptera_brachyptera" text="Kurzflügeligen Beißschrecke" /> nicht unähnlich. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
        </>
      ),
    },
  ],
  audible: "A",
  daytime: "Tag",
  months: "Juni – September",
  to: "stenobothrus_lineatus",
  german: "Großer Heidegrashüpfer",
  latin: "Stenobothrus lineatus",
  family: "Feldheuschrecken (Acrididae)",
  first: "um 1850, „Liesing“",
  districts: "12 (2., 3., 5., 10., 11., 13., 14., 17., 19., 21.–23.)",
  description: "Als auf Magerwiesen sowie Trocken- und Halbtrockenrasen spezialisierte Art ist der Große Heidegrashüpfer in den Wiener Zentralbezirken kaum anzutreffen. Schwerpunkte seiner Verbreitung liegen im Wienerwald und in der Lobau.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
