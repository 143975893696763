import speciesImage from "./locusta_migratoria.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "E",
  daytime: "",
  months: "Juni – Oktober",
  to: "locusta_migratoria",
  german: "Europäische Wanderheuschrecke",
  latin: "Locusta migratoria",
  family: "Feldheuschrecken (Acrididae)",
  first: "August 1848, „in der Stadt“",
  districts: "7 (1., 3., 6., 8., 12.–14.)",
  description: "Historische Meldungen der Europäischen Wanderheuschrecke aus Wien betrafen hauptsächlich Einflüge großer Schwärme, von denen jedoch zuletzt Mitte des 18. Jahrhunderts berichtet wurde. Spätere Sichtungen betreffen nur noch Einzeltiere, die vermutlich aus Futtertierhaltungen entkommen sind.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Werner Reitmeier",
};

export default data;
