export const systematic = [
  {
    key: "family_phaneropteridae",
    german: "Sichelschrecken",
    latin: "Phaneropteridae",
  },
  {
    key: "phaneroptera_falcata",
    german: "Gemeine Sichelschrecke",
    latin: "Phaneroptera falcata",
  },
  {
    key: "phaneroptera_nana",
    german: "Vierpunktige Sichelschrecke",
    latin: "Phaneroptera nana",
  },
  {
    key: "leptophyes_punctatissima",
    german: "Punktierte Zartschrecke",
    latin: "Leptophyes punctatissima",
  },
  {
    key: "leptophyes_albovittata",
    german: "Gestreifte Zartschrecke",
    latin: "Leptophyes albovittata",
  },
  {
    key: "leptophyes_boscii",
    german: "Gelbstreifige Zartschrecke",
    latin: "Leptophyes boscii",
  },
  {
    key: "barbitistes_serricauda",
    german: "Laubholz-Säbelschrecke",
    latin: "Barbitistes serricauda",
  },
  {
    key: "isophya_camptoxypha",
    german: "Fiebers Plumpschrecke",
    latin: "Isophya camptoxypha",
  },
  {
    key: "isophya_modestior",
    german: "Große Plumpschrecke",
    latin: "Isophya modestior",
  },
  {
    key: "polysarcus_denticauda",
    german: "Wanstschrecke",
    latin: "Polysarcus denticauda",
  },
  {
    key: "family_meconematidae",
    german: "Eichenschrecken",
    latin: "Meconematidae",
  },
  {
    key: "meconema_thalassinum",
    german: "Gemeine Eichenschrecke",
    latin: "Meconema thalassinum",
  },
  {
    key: "meconema_meridionale",
    german: "Südliche Eichenschrecke",
    latin: "Meconema meridionale",
  },
  {
    key: "family_tettigoniidae",
    german: "Laubheuschrecken",
    latin: "Tettigoniidae",
  },
  {
    key: "conocephalus_fuscus",
    german: "Langflügelige Schwertschrecke",
    latin: "Conocephalus fuscus",
  },
  {
    key: "conocephalus_dorsalis",
    german: "Kurzflügelige Schwertschrecke",
    latin: "Conocephalus dorsalis",
  },
  {
    key: "ruspolia_nitidula",
    german: "Große Schiefkopfschrecke",
    latin: "Ruspolia nitidula",
  },
  {
    key: "tettigonia_viridissima",
    german: "Grünes Heupferd",
    latin: "Tettigonia viridissima",
  },
  {
    key: "tettigonia_cantans",
    german: "Zwitscher-Heupferd",
    latin: "Tettigonia cantans",
  },
  {
    key: "tettigonia_caudata",
    german: "Östliches Heupferd",
    latin: "Tettigonia caudata",
  },
  {
    key: "decticus_verrucivorus",
    german: "Warzenbeißer",
    latin: "Decticus verrucivorus",
  },
  {
    key: "gampsocleis_glabra",
    german: "Heideschrecke",
    latin: "Gampsocleis glabra",
  },
  {
    key: "platycleis_grisea",
    german: "Graue Beißschrecke",
    latin: "Platycleis grisea",
  },
  {
    key: "tessellana_veyseli",
    german: "Kleine Beißschrecke",
    latin: "Tessellana veyseli",
  },
  {
    key: "metrioptera_brachyptera",
    german: "Kurzflügelige Beißschrecke",
    latin: "Metrioptera brachyptera",
  },
  {
    key: "bicolorana_bicolor",
    german: "Zweifarbige Beißschrecke",
    latin: "Bicolorana bicolor",
  },
  {
    key: "roeseliana_roeselii",
    german: "Roesels Beißschrecke",
    latin: "Roeseliana roeselii",
  },
  {
    key: "pholidoptera_aptera",
    german: "Alpen-Strauchschrecke",
    latin: "Pholidoptera aptera",
  },
  {
    key: "pholidoptera_fallax",
    german: "Südliche Strauchschrecke",
    latin: "Pholidoptera fallax",
  },
  {
    key: "pholidoptera_griseoaptera",
    german: "Gewöhnliche Strauchschrecke",
    latin: "Pholidoptera griseoaptera",
  },
  {
    key: "eupholidoptera_schmidti",
    german: "Schmidts Grüne Strauchschrecke",
    latin: "Eupholidoptera schmidti",
  },
  {
    key: "ephippiger_ephippiger",
    german: "Steppen-Sattelschrecke",
    latin: "Ephippiger ephippiger",
  },
  {
    key: "saga_pedo",
    german: "Große Sägeschrecke",
    latin: "Saga pedo",
  },
  {
    key: "family_rhaphidophoridae",
    german: "Buckelschrecken",
    latin: "Rhaphidophoridae",
  },
  {
    key: "diestrammena_asynamora",
    german: "Gewächshausschrecke",
    latin: "Diestrammena asynamora",
  },
  {
    key: "family_gryllidea",
    german: "Grillenartige",
    latin: "Gryllidea",
  },
  {
    key: "gryllotalpa_gryllotalpa",
    german: "Maulwurfsgrille",
    latin: "Gryllotalpa gryllotalpa",
  },
  {
    key: "myrmecophilus_acervorum",
    german: "Ameisengrille",
    latin: "Myrmecophilus acervorum",
  },
  {
    key: "oecanthus_pellucens",
    german: "Weinhähnchen",
    latin: "Oecanthus pellucens",
  },
  {
    key: "nemobius_sylvestris",
    german: "Waldgrille",
    latin: "Nemobius sylvestris",
  },
  {
    key: "pteronemobius_heydenii",
    german: "Sumpfgrille",
    latin: "Pteronemobius heydenii",
  },
  {
    key: "gryllus_campestris",
    german: "Feldgrille",
    latin: "Gryllus campestris",
  },
  {
    key: "acheta_domesticus",
    german: "Heimchen",
    latin: "Acheta domesticus",
  },
  {
    key: "eumodicogryllus_bordigalensis",
    german: "Südliche Grille",
    latin: "Eumodicogryllus bordigalensis",
  },
  {
    key: "modicogryllus_frontalis",
    german: "Östliche Grille",
    latin: "Modicogryllus frontalis",
  },
  {
    key: "gryllodes_sigillatus",
    german: "Kurzflügelgrille",
    latin: "Gryllodes sigillatus",
  },
  {
    key: "family_tridactylidae",
    german: "Grabschrecken",
    latin: "Tridactylidae",
  },
  {
    key: "xya_pfaendleri",
    german: "Pfändlers Grabschrecke",
    latin: "Xya pfaendleri",
  },
  {
    key: "xya_variegata",
    german: "Gefleckte Grabschrecke",
    latin: "Xya variegata",
  },
  {
    key: "family_tetrigidae",
    german: "Dornschrecken",
    latin: "Tetrigidae",
  },
  {
    key: "tetrix_subulata",
    german: "Säbeldornschrecke",
    latin: "Tetrix subulata",
  },
  {
    key: "tetrix_bolivari",
    german: "Bolivars Dornschrecke",
    latin: "Tetrix bolivari",
  },
  {
    key: "tetrix_ceperoi",
    german: "Westliche Dornschrecke",
    latin: "Tetrix ceperoi",
  },
  {
    key: "tetrix_tuerki",
    german: "Türks Dornschrecke",
    latin: "Tetrix tuerki",
  },
  {
    key: "tetrix_undulata",
    german: "Gemeine Dornschrecke",
    latin: "Tetrix undulata",
  },
  {
    key: "tetrix_kraussi",
    german: "Kurzflügel-Dornschrecke",
    latin: "Tetrix kraussi",
  },
  {
    key: "tetrix_tenuicornis",
    german: "Langfühler-Dornschrecke",
    latin: "Tetrix tenuicornis",
  },
  {
    key: "family_arididae",
    german: "Feldheuschrecken",
    latin: "Acrididae",
  },
  {
    key: "calliptamus_italicus",
    german: "Italienische Schönschrecke",
    latin: "Calliptamus italicus",
  },
  {
    key: "miramella_alpina",
    german: "Alpine Gebirgsschrecke",
    latin: "Miramella alpina",
  },
  {
    key: "odontopodisma_decipiens",
    german: "Gewöhnliche Grünschrecke",
    latin: "Odontopodisma decipiens",
  },
  {
    key: "pezotettix_giornae",
    german: "Kleine Knarrschrecke",
    latin: "Pezotettix giornae",
  },
  {
    key: "acrida_ungarica",
    german: "Gewöhnliche Nasenschrecke",
    latin: "Acrida ungarica",
  },
  {
    key: "mecostethus_parapleurus",
    german: "Lauchschrecke",
    latin: "Mecostethus parapleurus",
  },
  {
    key: "stethophyma_grossum",
    german: "Sumpfschrecke",
    latin: "Stethophyma grossum",
  },
  {
    key: "psophus_stridulus",
    german: "Rotflügelige Schnarrschrecke",
    latin: "Psophus stridulus",
  },
  {
    key: "locusta_migratoria",
    german: "Europäische Wanderheuschrecke",
    latin: "Locusta migratoria",
  },
  {
    key: "oedipoda_caerulescens",
    german: "Blauflügelige Ödlandschrecke",
    latin: "Oedipoda caerulescens",
  },
  {
    key: "sphingonotus_caerulans",
    german: "Blauflügelige Sandschrecke",
    latin: "Sphingonotus caerulans",
  },
  {
    key: "aiolopus_thalassinus",
    german: "Grüne Strandschrecke",
    latin: "Aiolopus thalassinus",
  },
  {
    key: "arcyptera_fusca",
    german: "Große Höckerschrecke",
    latin: "Arcyptera fusca",
  },
  {
    key: "chrysochraon_dispar",
    german: "Große Goldschrecke",
    latin: "Chrysochraon dispar",
  },
  {
    key: "euthystira_brachyptera",
    german: "Kleine Goldschrecke",
    latin: "Euthystira brachyptera",
  },
  {
    key: "omocestus_viridulus",
    german: "Bunter Grashüpfer",
    latin: "Omocestus viridulus",
  },
  {
    key: "omocestus_rufipes",
    german: "Buntbäuchiger Grashüpfer",
    latin: "Omocestus rufipes",
  },
  {
    key: "omocestus_haemorrhoidalis",
    german: "Rotleibiger Grashüpfer",
    latin: "Omocestus haemorrhoidalis",
  },
  {
    key: "stenobothrus_lineatus",
    german: "Großer Heidegrashüpfer",
    latin: "Stenobothrus lineatus",
  },
  {
    key: "stenobothrus_nigromaculatus",
    german: "Schwarzfleckiger Heidegrashüpfer",
    latin: "Stenobothrus nigromaculatus",
  },
  {
    key: "stenobothrus_stigmaticus",
    german: "Kleiner Heidegrashüpfer",
    latin: "Stenobothrus stigmaticus",
  },
  {
    key: "stenobothrus_crassipes",
    german: "Zwerg-Heidegrashüpfer",
    latin: "Stenobothrus crassipes",
  },
  {
    key: "gomphocerippus_rufus",
    german: "Rote Keulenschrecke",
    latin: "Gomphocerippus rufus",
  },
  {
    key: "myrmeleotettix_maculatus",
    german: "Gefleckte Keulenschrecke",
    latin: "Myrmeleotettix maculatus",
  },
  {
    key: "chorthippus_apricarius",
    german: "Feldgrashüpfer",
    latin: "Chorthippus apricarius",
  },
  {
    key: "chorthippus_pullus",
    german: "Kiesbank-Grashüpfer",
    latin: "Chorthippus pullus",
  },
  {
    key: "chorthippus_vagans",
    german: "Steppengrashüpfer",
    latin: "Chorthippus vagans",
  },
  {
    key: "chorthippus_mollis",
    german: "Verkannter Grashüpfer",
    latin: "Chorthippus mollis",
  },
  {
    key: "chorthippus_brunneus",
    german: "Brauner Grashüpfer",
    latin: "Chorthippus brunneus",
  },
  {
    key: "chorthippus_biguttulus",
    german: "Nachtigall-Grashüpfer",
    latin: "Chorthippus biguttulus",
  },
  {
    key: "chorthippus_albomarginatus",
    german: "Weißrandiger Grashüpfer",
    latin: "Chorthippus albomarginatus",
  },
  {
    key: "chorthippus_dorsatus",
    german: "Wiesengrashüpfer",
    latin: "Chorthippus dorsatus",
  },
  {
    key: "pseudochorthippus_parallelus",
    german: "Gemeiner Grashüpfer",
    latin: "Pseudochorthippus parallelus",
  },
  {
    key: "pseudochorthippus_montanus",
    german: "Sumpfgrashüpfer",
    latin: "Pseudochorthippus montanus",
  },
  {
    key: "euchorthippus_declivus",
    german: "Dickkopf-Grashüpfer",
    latin: "Euchorthippus declivus",
  },
  {
    key: "family_mantodea",
    german: "Fangschrecken",
    latin: "Mantodea",
  },
  {
    key: "mantis_religiosa",
    german: "Europäische Gottesanbeterin",
    latin: "Mantis religiosa",
  },
];
 