import React from "react";

import speciesImage from "./stethophyma_grossum.jpg";
import audioFile from "./stethophyma_grossum_002_APA.mp3";
import audioOscillogram from "./stethophyma_grossum_002_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 3.505,
    audioDescription: <>Die relativ gut hörbaren „Knipslaute“ entstehen durch das Wegschleudern der hinteren Beinschiene und lassen zunächst nicht auf die Lautäußerung einer Heuschrecke schließen. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
  },
  audible: "B",
  daytime: "Tag",
  months: "Juli – September",
  to: "stethophyma_grossum",
  german: "Sumpfschrecke",
  latin: "Stethophyma grossum",
  family: "Feldheuschrecken (Acrididae)",
  first: "um 1880, „Prater“",
  districts: "5 (2., 17., 21.–23.)",
  description: "Die Sumpfschrecke ist eine Bewohnerin von ausgeprägten Feuchtlebensräumen und kommt in Wien nur an sehr wenigen Lokalitäten vor.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
