import speciesImage from "./myrmecophilus_acervorum.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "E",
  daytime: "",
  months: "März – November",
  to: "myrmecophilus_acervorum",
  german: "Ameisengrille",
  latin: "Myrmecophilus acervorum",
  family: "Ameisengrillen (Myrmecophilidae)",
  first: "um 1880, „Prater“",
  districts: "5 (2., 13., 14., 16., 22.)",
  description: "Obwohl von der Ameisengrille nur wenige Datensätze aus Wien vorliegen, ist sie mit Sicherheit deutlich häufiger, als die Verbreitungskarte suggeriert. Dies hängt mit ihrer speziellen Lebensweise in Ameisennestern zusammen, wo sie naturgemäß nicht leicht zu finden ist.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Markus Sehnal",
};

export default data;
