import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./myrmeleotettix_maculatus.jpg";
import audioFile from "./myrmeleotettix_maculatus_002_APA.mp3";
import audioOscillogram from "./myrmeleotettix_maculatus_002_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 12.652,
    audioDescription: (
      <>
        Der Gesang ähnelt jenem des <SpeciesLink to="stenobothrus_nigromaculatus" text="Schwarzfleckigen Heidegrashüpfers" after="," /> ist jedoch rascher, leiser/weicher und am Ende leicht abfallend. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Tag",
  months: "Juni – September",
  to: "myrmeleotettix_maculatus",
  german: "Gefleckte Keulenschrecke",
  latin: "Myrmeleotettix maculatus",
  family: "Feldheuschrecken (Acrididae)",
  first: "um 1880, „Mauer“",
  districts: "3 (14., 22., 23.)",
  description: "Als eine der seltensten Heuschreckenarten Wiens bewohnt die Gefleckte Keulenschrecke aktuell nur eine einzige Heißlände in der Unteren Lobau. Weitere, bislang unbekannte Vorkommen harren möglicherweise ihrer Entdeckung.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
