import speciesImage from "./xya_pfaendleri.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "E",
  daytime: "",
  months: "März – Oktober",
  to: "xya_pfaendleri",
  german: "Pfändlers Grabschrecke",
  latin: "Xya pfaendleri",
  family: "Grabschrecken (Tridactylidae)",
  first: "2005, Großenzersdorfer Arm/Obere Lobau",
  districts: "1 (22.)",
  description: "Ein aktuelles Vorkommen von Pfändlers Grabschrecke in Wien ist nicht bekannt. Im Jahre 2005 wurde sie an zwei Stellen am Großenzersdorfer Arm in der Oberen Lobau nachgewiesen, seither jedoch nicht mehr bestätigt.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
