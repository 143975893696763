import React from "react";

import speciesImage from "./gampsocleis_glabra.jpg";
import audioFile from "./gampsocleis_glabra_010_APA.mp3";
import audioOscillogram from "./gampsocleis_glabra_010_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 18.99,
    audioDescription: <>Der metallische Gesang ist weithin vernehmbar und wird meist von Sitzwarten aus vorgetragen. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
  },
  audible: "A",
  daytime: "Tag",
  months: "Juni – Juli",
  to: "gampsocleis_glabra",
  german: "Heideschrecke",
  latin: "Gampsocleis glabra",
  family: "Laubheuschrecken (Tettigoniidae)",
  first: "um 1850, Liesing",
  last: "um 1880, Liesing",
  districts: "1 (23.)",
  description: "Wiener Vorkommen der Heideschrecke sind nur historisch aus dem 19. Jahrhundert aus Liesing bekannt, seit über 100 Jahren gibt es keine Meldungen mehr.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Markus Sehnal",
};

export default data;
