import speciesImage from "./aiolopus_thalassinus.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "E",
  daytime: "",
  months: "Juli – Oktober",
  to: "aiolopus_thalassinus",
  german: "Grüne Strandschrecke",
  latin: "Aiolopus thalassinus",
  family: "Feldheuschrecken (Acrididae)",
  first: "um 1850, „Prater“",
  districts: "14 (2., 8.–14., 18.–23.)",
  description: "In den letzten Jahrzehnten nehmen Sichtungen der Grünen Strandschrecke in Wien deutlich zu. Früher hauptsächlich als Feuchtgebietsart geltend, findet man sie vermehrt auch auf flachgründigen Rohbodenstandorten.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Wolfgang Schweighofer",
};

export default data;
