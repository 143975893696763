import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./pholidoptera_aptera.jpg";
import audioFile from "./pholidoptera_aptera_007_APA.mp3";
import audioOscillogram from "./pholidoptera_aptera_007_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 12.647,
    audioDescription: (
      <>
        Die aneinandergereihten, recht lauten und „zwitschernden“ Einzeltöne führen meist zur raschen Reaktion und Antwort anderer Männchen im Nahbereich. Vom Klang her könnte man den Gesang für einen Vogel halten. Hintergrundstimme: <SpeciesLink to="tettigonia_viridissima" text="Grünes Heupferd" after="." /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Nacht/Tag",
  months: "Juni – August",
  to: "pholidoptera_aptera",
  german: "Alpen-Strauchschrecke",
  latin: "Pholidoptera aptera",
  family: "Laubheuschrecken (Tettigoniidae)",
  first: "um 1850, Kahlenberg",
  districts: "7 (13., 14., 16.–19., 23.)",
  description: "Als typische gehölz-assoziierte Art kommt die Alpen-Strauchschrecke in Wien ausschließlich im Wienerwald vor, wo sie vor allem krautige Bestände und Magerwiesen in Waldnähe bewohnt.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Werner Reitmeier",
};

export default data;
