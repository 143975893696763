import React from "react";

import SpeciesLink from "../../../components/SpeciesLink";
import { PU, P1, P2, P3, P4, P5, P1s, P3s, P4s, P5s } from "../../../components/P";

import "../../../css/content.scss";
import "../../../css/navigation.scss";
import "../../../css/symbols.scss";

import image1 from "./image1.jpg";
import image2 from "./image2.jpg";
import image3 from "./image3.jpg";
import image4 from "./image4.jpg";
import image5 from "./image5.jpg";
import rundweg from "./rundweg.jpg";
import pdf from "./iiw_heuschrecken_rundweg_donauinsel.pdf";

const description = (
  <>
    <div>
      <figure className="content__img-donauinsel-container">
        <img className="content__img content__img-donauinsel" src={rundweg} alt="Karte" />
        <figcaption className="content__img-author">Karte: ViennaGIS</figcaption>
      </figure>
      <h1>An Spätsommer­abenden mit dem Rad über die Donauinsel</h1>
    </div>
    <p>
      <b>Autorin:</b> Liesbeth Forsthuber
      <br />
      <b>Strecke:</b> 12,6&nbsp;km; Taschenlampe empfohlen
      <br />
      <b>Startpunkt: </b> U2 Donau­stadtbrücke{" "}
      <a className="symbol__map-marker" href="https://www.google.at/maps/place/Donaustadtbr%C3%BCcke/@48.2116587,16.4377609,17z/data=!3m1!4b1!4m5!3m4!1s0x476d00d2bfb60e87:0x5e54ce8791a1a8a3!8m2!3d48.2116551!4d16.4399496" rel="noopener noreferrer" target="_blank">
        Google Maps Link
      </a>
    </p>
    <a className="nav__button-large-download nav__button-large-download--halfpage" href={pdf} target="_blank" rel="noopener noreferrer">
      Buchkapitel als PDF herunterladen
    </a>
    <p>Die Donauinsel könnte man als einen großen, langgestreckten Park bezeichnen. Obwohl künstlich geschaffen, ist sie doch sehr abwechslungsreich in ihren Lebensräumen und weist für ihre Lage inmitten der Großstadt eine ansehnliche Artenvielfalt auf. Diese Radtour ist vor allem an spätsommerlichen Abenden und Nächten Mitte August bis Ende September ein spezielles (Hör-) Erlebnis. Man kann dabei die typischen dämmerungs- und nachtaktiven Arten, vor allem einige Grillenarten samt zwei „Exoten“, innerhalb kurzer Zeit kennenlernen.</p>
    <p>
      Wir beginnen unsere Tour am späten Nachmittag bei der U2-Station <PU t="Donaustadtbrücke" after="," /> von wo aus wir über die Neue Donau auf die Donauinsel gelangen. Über die asphaltierten Wege geht es nun donauabwärts. Am Weg tauchen immer wieder kleinräumige Habitate und unterschiedlich gemähte Wiesen auf, bei denen wir kurz anhalten. Auf Wiesen mit höherem Bewuchs finden wir oft die <SpeciesLink to="leptophyes_albovittata" text="Gestreifte Zartschrecke" after="." /> Auch kann man bei dieser Gelegenheit die auf Blättern sitzenden <SpeciesLink to="oecanthus_pellucens" text="Weinhähnchen" /> erspähen. An offenen, teilweise schottrigen Stellen, z.&nbsp;B. auf Baustellen oder Materiallagerplätzen, lohnt sich die Suche nach der auffälligen und mobilen <SpeciesLink to="aiolopus_thalassinus" text="Grünen Strandschrecke" after="." />
    </p>
    <figure>
      <img className="content__img content__img--labeled" src={image1} alt="" />
      <figcaption className="content__img-text">
        Auf selten gemähten Böschungen wie beim <P1s t="Ruderzentrum" /> sind auf kleinem Raum oft erstaunlich viele Arten zu finden. Foto:&nbsp;Liesbeth&nbsp;Forsthuber
      </figcaption>
    </figure>
    <p>
      Nach ca. 4&nbsp;km erreichen wir das Ruderzentrum nahe der <P1 t="Steinspornbrücke" after="." /> Auf dem Gelände mit den alten gemauerten, grasbewachsenen Zuschauertreppen tummeln sich <SpeciesLink to="calliptamus_italicus" text="Italienische Schönschrecke" after="," /> <SpeciesLink to="oedipoda_caerulescens" text="Blauflügelige Ödlandschrecke" /> und <SpeciesLink to="chorthippus_brunneus" text="Brauner Grashüpfer" after="." /> Etwas abseits zwischen den Wegen liegt eine meist ungemähte Böschung, auf welcher zudem <SpeciesLink to="euchorthippus_declivus" text="Dickkopf-Grashüpfer" after="," /> <SpeciesLink to="stenobothrus_lineatus" text="Großer Heidegrashüpfer" /> und <SpeciesLink to="platycleis_grisea" text="Graue Beißschrecke" /> singen. Auch <SpeciesLink to="mantis_religiosa" text="Gottesanbeterinnen" /> müssen versuchen, den vielen Radfahrern und durchlaufenden Fußgängern zu entkommen.
    </p>
    <p>
      Bei Einbruch der Dämmerung hören wir auf der Fahrt das unermüdlich von den Bäumen scheppernde <SpeciesLink to="tettigonia_viridissima" text="Grüne Heupferd" after="." /> Dazwischen ertönt immer wieder aus hochgrasigen Bereichen das sehr hochfrequente, durchgehende Sirren der inzwischen fast ebenso häufigen <SpeciesLink to="ruspolia_nitidula" text="Großen Schiefkopfschrecke" after="," /> die sich als Profiteurin des Klimawandels in kürzester Zeit im gesamten Siedlungsraum Wiens ausgebreitet hat. Nicht zu schnell an Gebüschen vorbeifahrend, bemerken wir immer wieder die kurzen „zrrt“-Laute der <SpeciesLink to="pholidoptera_griseoaptera" text="Gewöhnlichen Strauchschrecke" after="." /> Viel lauter, aber eher vereinzelt, erklingen ab jetzt wieder die unverkennbaren, durchdringenden Rufe des <SpeciesLink to="oecanthus_pellucens" text="Weinhähnchens" after="." />
    </p>
    <figure>
      <img className="content__img content__img--labeled" src={image2} alt="" />
      <figcaption className="content__img-text">
        Blick auf die 1983 erbaute <P3s t="Friedenspagode" after="," /> in deren Umgebung vereinzelt die <SpeciesLink to="gryllodes_sigillatus" text="Kurzflügelgrille" /> zu hören ist. Im Hintergrund das Kraftwerk Freudenau. Foto:&nbsp;Liesbeth&nbsp;Forsthuber
      </figcaption>
    </figure>
    <p>
      Weiter geht die Runde am Toten Grund vorbei zum Kraftwerk <P2 t="Freudenau" after="," /> wo bis in den Frühwinter hinein auf den umgebenden Wiesen bei Sonnenschein noch einzelne <SpeciesLink to="chorthippus_mollis" text="Verkannte Grashüpfer" /> zu hören sind. Über die Staumauer des Kraftwerks fahren wir an das rechte Donauufer und hier wieder zurück donauaufwärts am Wiener Containerhafen vorbei Richtung <P3 t="Friedenspagode" after="." /> Eine wärmeliebende Art, die zwischen vegetationslosen Schottersteinen der parallel zum Radweg verlaufenden Gleisanlagen lebt, ist die <SpeciesLink to="eumodicogryllus_bordigalensis" text="Südliche Grille" after="," /> die wir hier schon vereinzelt aus den Geräuschen des Hafens heraushören können. Sie singt in ratternden, durchgehend vorgetragenen Versen aus dem Gleisschotter und ist an vielen Bahnhöfen Wiens zu vernehmen. Ab nun müssen wir uns ganz auf unser Gehör konzentrieren. Wenn wir die oben genannten „Allerweltsarten“ ausblenden können, fällt uns sofort (wenn auch an immer anderer Stelle) ein besonderer, unbekannter Gesang auf: Es handelt sich um die <SpeciesLink to="gryllodes_sigillatus" text="Kurzflügelgrille" /> – eine aus Asien eingeschleppte Art, die im Freiland sehr selten, aber anscheinend aufgrund der klimatischen Bedingungen immer öfter überleben kann. Der Gesang ist nicht schwierig zu orten, aber nachdem das Tier bei Annäherung sofort zu singen aufhört und außerdem in Ritzen und Spalten sitzt, ist es meist ein Geduldspiel, sie zu Gesicht zu bekommen oder gar zu fotografieren. Diese tropische Grille sieht dem <SpeciesLink to="acheta_domesticus" text="Heimchen" /> recht ähnlich, welches hier stellenweise ebenfalls in großer Anzahl vorkommt und leichter zu entdecken ist. Die <SpeciesLink to="gryllodes_sigillatus" text="Kurzflügelgrille" /> ist aber etwas kleiner und ihr Gesang unterscheidet sich deutlich, er ist schneller und regelmäßiger.
    </p>
    <figure>
      <img className="content__img content__img--labeled" src={image3} alt="" />
      <figcaption className="content__img-text">
        Im Bereich eines von alten Pappeln bestandenen <P4s t="Parkplatzes" /> können in der Nacht drei unterschiedliche Grillenarten zugleich vernommen werden. Foto:&nbsp;Liesbeth&nbsp;Forsthuber
      </figcaption>
    </figure>
    <p>
      Nach der Pagode kommen wir zu einem Parkplatz, der zwischen den durch eine Mauer abgetrennten Schienen und der Donau <P4 t="liegt" after="." /> Das Ableuchten der dort stehenden alten Pappeln (an welchen die <SpeciesLink to="gryllodes_sigillatus" text="Kurzflügelgrille" /> im Gebiet erstmals nachgewiesen wurde) und der Wegränder kann sich auszahlen: Neben der <SpeciesLink to="gryllodes_sigillatus" text="Kurzflügelgrille" /> können <SpeciesLink to="acheta_domesticus" text="Heimchen" /> und deren Larven, vereinzelt <SpeciesLink to="eumodicogryllus_bordigalensis" text="Südliche Grillen" after="," /> <SpeciesLink to="meconema_meridionale" text="Südliche Eichenschrecken" /> sowie das ein oder andere Gelege der <SpeciesLink to="mantis_religiosa" text="Gottesanbeterin" /> im Lichtschein auftauchen.
    </p>
    <figure>
      <img className="content__img content__img--labeled" src={image4} alt="" />
      <figcaption className="content__img-text">
        In der Umgebung des Hotel <P5s t="Hilton" /> singt vor allem nach Sonnenuntergang <SpeciesLink to="eupholidoptera_schmidti" text="Schmidts Grüne Strauchschrecke" after="." /> Foto:&nbsp;Markus&nbsp;Sehnal
      </figcaption>
    </figure>
    <p>
      Um eine zweite sich derzeit etablierende Heuschreckenart kennenzulernen und das nächtliche Klangbild abzurunden, fahren wir donauaufwärts auf dem Radweg an Stadlauer- und Donaustadtbrücke vorbei und die Donaulände noch etwa 4&nbsp;km entlang. Nun kommen wir beim Hotel Hilton in einen Bereich, wo aus Hecken und Gebüschen ein Gesang zu vernehmen ist, der jenem der <SpeciesLink to="pholidoptera_griseoaptera" text="Gewöhnlichen Strauchschrecke" /> <P5 t="ähnelt" after="." /> Er wird von <SpeciesLink to="eupholidoptera_schmidti" text="Schmidts Grüner Strauchschrecke" /> vorgetragen, die erst in den 2010er-Jahren aus dem Mittelmeerraum nach Wien eingeschleppt wurde und sich zusehends ausbreitet. Es kann also spannend sein, sich ihren Gesang gut einzuprägen, um die weitere Entwicklung der Wiener Bestände dokumentieren zu können.
    </p>
    <p>
      Hier endet unsere abendliche Rundfahrt, die U2-Station <PU t="Stadion" /> ist in wenigen Minuten über den Roman-Köhler-Steg und die Dr.-Natterer-Gasse erreicht.
    </p>
    <figure>
      <img className="content__img content__img--labeled" src={image5} alt="" />
      <figcaption className="content__img-text">
        Obwohl weithin hörbar, bekommt man ein Männchen des <SpeciesLink to="oecanthus_pellucens" text="Weinhähnchens" /> nur selten zu Gesicht. Foto:&nbsp;Günther&nbsp;Wöss
      </figcaption>
    </figure>
  </>
);

export default description;
