// get all the data on species songs, hike information, etc

// Phaneropteridae
import phaneroptera_falcata from "./heuschrecken/01_phaneroptera_falcata/data.js";
import phaneroptera_nana from "./heuschrecken/02_phaneroptera_nana/data.js";
import leptophyes_punctatissima from "./heuschrecken/03_leptophyes_punctatissima/data.js";
import leptophyes_albovittata from "./heuschrecken/04_leptophyes_albovittata/data.js";
import leptophyes_boscii from "./heuschrecken/05_leptophyes_boscii/data.js";
import barbitistes_serricauda from "./heuschrecken/06_barbitistes_serricauda/data.js";
import isophya_camptoxypha from "./heuschrecken/07_isophya_camptoxypha/data.js";
import isophya_modestior from "./heuschrecken/08_isophya_modestior/data.js";
import polysarcus_denticauda from "./heuschrecken/09_polysarcus_denticauda/data.js";

// Meconematidae
import meconema_thalassinum from "./heuschrecken/10_meconema_thalassinum/data.js";
import meconema_meridionale from "./heuschrecken/11_meconema_meridionale/data.js";

// Tettigoniidae
import conocephalus_fuscus from "./heuschrecken/12_conocephalus_fuscus/data.js";
import conocephalus_dorsalis from "./heuschrecken/13_conocephalus_dorsalis/data.js";
import ruspolia_nitidula from "./heuschrecken/14_ruspolia_nitidula/data.js";
import tettigonia_viridissima from "./heuschrecken/15_tettigonia_viridissima/data.js";
import tettigonia_cantans from "./heuschrecken/16_tettigonia_cantans/data.js";
import tettigonia_caudata from "./heuschrecken/17_tettigonia_caudata/data.js";
import decticus_verrucivorus from "./heuschrecken/18_decticus_verrucivorus/data.js";
import gampsocleis_glabra from "./heuschrecken/19_gampsocleis_glabra/data.js";
import platycleis_grisea from "./heuschrecken/20_platycleis_grisea/data.js";
import tessellana_veyseli from "./heuschrecken/21_Tessellana_veyseli/data.js";
import metrioptera_brachyptera from "./heuschrecken/22_metrioptera_brachyptera/data.js";
import bicolorana_bicolor from "./heuschrecken/23_bicolorana_bicolor/data.js";
import roeseliana_roeselii from "./heuschrecken/24_roeseliana_roeselii/data.js";
import pholidoptera_aptera from "./heuschrecken/25_pholidoptera_aptera/data.js";
import pholidoptera_fallax from "./heuschrecken/26_pholidoptera_fallax/data.js";
import pholidoptera_griseoaptera from "./heuschrecken/27_pholidoptera_griseoaptera/data.js";
import eupholidoptera_schmidti from "./heuschrecken/28_eupholidoptera_schmidti/data.js";
import ephippiger_ephippiger from "./heuschrecken/29_ephippiger_ephippiger/data.js";
import saga_pedo from "./heuschrecken/30_saga_pedo/data.js";

// Rhaphidophoridae
import diestrammena_asynamora from "./heuschrecken/31_diestrammena_asynamora/data.js";

// Gryllidea
import gryllotalpa_gryllotalpa from "./heuschrecken/32_gryllotalpa_gryllotalpa/data.js";
import myrmecophilus_acervorum from "./heuschrecken/33_myrmecophilus_acervorum/data.js";
import oecanthus_pellucens from "./heuschrecken/34_oecanthus_pellucens/data.js";
import nemobius_sylvestris from "./heuschrecken/35_nemobius_sylvestris/data.js";
import pteronemobius_heydenii from "./heuschrecken/36_pteronemobius_heydenii/data.js";
import gryllus_campestris from "./heuschrecken/37_gryllus_campestris/data.js";
import acheta_domesticus from "./heuschrecken/38_acheta_domesticus/data.js";
import eumodicogryllus_bordigalensis from "./heuschrecken/39_eumodicogryllus_bordigalensis/data.js";
import modicogryllus_frontalis from "./heuschrecken/40_modicogryllus_frontalis/data.js";
import gryllodes_sigillatus from "./heuschrecken/41_gryllodes_sigillatus/data.js";

// Tridactylidae
import xya_pfaendleri from "./heuschrecken/42_xya_pfaendleri/data.js";
import xya_variegata from "./heuschrecken/43_xya_variegata/data.js";

// Tetrigidae
import tetrix_subulata from "./heuschrecken/44_tetrix_subulata/data.js";
import tetrix_bolivari from "./heuschrecken/45_tetrix_bolivari/data.js";
import tetrix_ceperoi from "./heuschrecken/46_tetrix_ceperoi/data.js";
import tetrix_tuerki from "./heuschrecken/47_tetrix_tuerki/data.js";
import tetrix_undulata from "./heuschrecken/48_tetrix_undulata/data.js";
import tetrix_kraussi from "./heuschrecken/49_tetrix_kraussi/data.js";
import tetrix_tenuicornis from "./heuschrecken/50_tetrix_tenuicornis/data.js";

// Acrididae
import calliptamus_italicus from "./heuschrecken/51_calliptamus_italicus/data.js";
import miramella_alpina from "./heuschrecken/52_miramella_alpina/data.js";
import odontopodisma_decipiens from "./heuschrecken/53_odontopodisma_decipiens/data.js";
import pezotettix_giornae from "./heuschrecken/54_pezotettix_giornae/data.js";
import acrida_ungarica from "./heuschrecken/55_acrida_ungarica/data.js";
import mecostethus_parapleurus from "./heuschrecken/56_mecostethus_parapleurus/data.js";
import stethophyma_grossum from "./heuschrecken/57_stethophyma_grossum/data.js";
import psophus_stridulus from "./heuschrecken/58_psophus_stridulus/data.js";
import locusta_migratoria from "./heuschrecken/59_locusta_migratoria/data.js";
import oedipoda_caerulescens from "./heuschrecken/60_oedipoda_caerulescens/data.js";
import sphingonotus_caerulans from "./heuschrecken/61_sphingonotus_caerulans/data.js";
import aiolopus_thalassinus from "./heuschrecken/62_aiolopus_thalassinus/data.js";
import arcyptera_fusca from "./heuschrecken/63_arcyptera_fusca/data.js";
import chrysochraon_dispar from "./heuschrecken/64_chrysochraon_dispar/data.js";
import euthystira_brachyptera from "./heuschrecken/65_euthystira_brachyptera/data.js";
import omocestus_viridulus from "./heuschrecken/66_omocestus_viridulus/data.js";
import omocestus_rufipes from "./heuschrecken/67_omocestus_rufipes/data.js";
import omocestus_haemorrhoidalis from "./heuschrecken/68_omocestus_haemorrhoidalis/data.js";
import stenobothrus_lineatus from "./heuschrecken/69_stenobothrus_lineatus/data.js";
import stenobothrus_nigromaculatus from "./heuschrecken/70_stenobothrus_nigromaculatus/data.js";
import stenobothrus_stigmaticus from "./heuschrecken/71_stenobothrus_stigmaticus/data.js";
import stenobothrus_crassipes from "./heuschrecken/72_stenobothrus_crassipes/data.js";
import gomphocerippus_rufus from "./heuschrecken/73_gomphocerippus_rufus/data.js";
import myrmeleotettix_maculatus from "./heuschrecken/74_myrmeleotettix_maculatus/data.js";
import chorthippus_apricarius from "./heuschrecken/75_chorthippus_apricarius/data.js";
import chorthippus_pullus from "./heuschrecken/76_chorthippus_pullus/data.js";
import chorthippus_vagans from "./heuschrecken/77_chorthippus_vagans/data.js";
import chorthippus_mollis from "./heuschrecken/78_chorthippus_mollis/data.js";
import chorthippus_brunneus from "./heuschrecken/79_chorthippus_brunneus/data.js";
import chorthippus_biguttulus from "./heuschrecken/80_chorthippus_biguttulus/data.js";
import chorthippus_albomarginatus from "./heuschrecken/81_chorthippus_albomarginatus/data.js";
import chorthippus_dorsatus from "./heuschrecken/82_chorthippus_dorsatus/data.js";
import pseudochorthippus_parallelus from "./heuschrecken/83_pseudochorthippus_parallelus/data.js";
import pseudochorthippus_montanus from "./heuschrecken/84_pseudochorthippus_montanus/data.js";
import euchorthippus_declivus from "./heuschrecken/85_euchorthippus_declivus/data.js";

// Mantodea
import mantis_religiosa from "./heuschrecken/86_mantis_religiosa/data.js";

import rundwegImage1 from "./rundwege/1_lainzer_tiergarten/image1.jpg";
import rundwegImage2 from "./rundwege/2_stammersdorf/image1.jpg";
import rundwegImage3 from "./rundwege/3_donauinsel/image1.jpg";
import rundwegImage4 from "./rundwege/4_obere_lobau/image1.jpg";

import rundwegDescription1 from "./rundwege/1_lainzer_tiergarten/description.js";
import rundwegDescription2 from "./rundwege/2_stammersdorf/description.js";
import rundwegDescription3 from "./rundwege/3_donauinsel/description.js";
import rundwegDescription4 from "./rundwege/4_obere_lobau_PLACEHOLDER/description.js";

import { speciesList1, familyList1 } from "./rundwege/1_lainzer_tiergarten/list.js";
import { speciesList2, familyList2 } from "./rundwege/2_stammersdorf/list.js";
import { speciesList3, familyList3 } from "./rundwege/3_donauinsel/list.js";
import { speciesList4, familyList4 } from "./rundwege/4_obere_lobau/list.js";

export const speciesData = {
  family_phaneropteridae: { german: "Sichelschrecken", latin: "Phaneropteridae" },
  phaneroptera_falcata,
  phaneroptera_nana,
  leptophyes_punctatissima,
  leptophyes_albovittata,
  leptophyes_boscii,
  barbitistes_serricauda,
  isophya_camptoxypha,
  isophya_modestior,
  polysarcus_denticauda,

  family_meconematidae: { german: "Eichenschrecken", latin: "Meconematidae" },
  meconema_thalassinum,
  meconema_meridionale,

  family_tettigoniidae: { german: "Laubheuschrecken", latin: "Tettigoniidae" },
  conocephalus_fuscus,
  conocephalus_dorsalis,
  ruspolia_nitidula,
  tettigonia_viridissima,
  tettigonia_cantans,
  tettigonia_caudata,
  decticus_verrucivorus,
  gampsocleis_glabra,
  platycleis_grisea,
  tessellana_veyseli,
  metrioptera_brachyptera,
  bicolorana_bicolor,
  roeseliana_roeselii,
  pholidoptera_aptera,
  pholidoptera_fallax,
  pholidoptera_griseoaptera,
  eupholidoptera_schmidti,
  ephippiger_ephippiger,
  saga_pedo,

  family_rhaphidophoridae: { german: "Buckelschrecken", latin: "Rhaphidophoridae" },
  diestrammena_asynamora,

  family_gryllidea: { german: "Grillenartige", latin: "Gryllidea" },
  gryllotalpa_gryllotalpa,
  myrmecophilus_acervorum,
  oecanthus_pellucens,
  nemobius_sylvestris,
  pteronemobius_heydenii,
  gryllus_campestris,
  acheta_domesticus,
  eumodicogryllus_bordigalensis,
  modicogryllus_frontalis,
  gryllodes_sigillatus,

  family_tridactylidae: { german: "Grabschrecken", latin: "Tridactylidae" },
  xya_pfaendleri,
  xya_variegata,

  family_tetrigidae: { german: "Dornschrecken", latin: "Tetrigidae" },
  tetrix_subulata,
  tetrix_bolivari,
  tetrix_ceperoi,
  tetrix_tuerki,
  tetrix_undulata,
  tetrix_kraussi,
  tetrix_tenuicornis,

  family_arididae: { german: "Feldheuschrecken", latin: "Acrididae" },
  calliptamus_italicus,
  miramella_alpina,
  odontopodisma_decipiens,
  pezotettix_giornae,
  acrida_ungarica,
  mecostethus_parapleurus,
  stethophyma_grossum,
  psophus_stridulus,
  locusta_migratoria,
  oedipoda_caerulescens,
  sphingonotus_caerulans,
  aiolopus_thalassinus,
  arcyptera_fusca,
  chrysochraon_dispar,
  euthystira_brachyptera,
  omocestus_viridulus,
  omocestus_rufipes,
  omocestus_haemorrhoidalis,
  stenobothrus_lineatus,
  stenobothrus_nigromaculatus,
  stenobothrus_stigmaticus,
  stenobothrus_crassipes,
  gomphocerippus_rufus,
  myrmeleotettix_maculatus,
  chorthippus_apricarius,
  chorthippus_pullus,
  chorthippus_vagans,
  chorthippus_mollis,
  chorthippus_brunneus,
  chorthippus_biguttulus,
  chorthippus_albomarginatus,
  chorthippus_dorsatus,
  pseudochorthippus_parallelus,
  pseudochorthippus_montanus,
  euchorthippus_declivus,

  family_mantodea: { german: "Fangschrecken", latin: "Mantodea" },
  mantis_religiosa,
};

export const hikes = [
  {
    to: "1",
    image1: rundwegImage1,
    title: "Durch den südlichen Lainzer Tiergarten",
    short: "Lainzer Tiergarten",
    description: rundwegDescription1,
    speciesList: speciesList1,
    familyList: familyList1,
  },
  {
    to: "2",
    image1: rundwegImage2,
    title: "Durch die Weinberge bei Stammersdorf",
    short: "Stammersdorf",
    description: rundwegDescription2,
    speciesList: speciesList2,
    familyList: familyList2,
  },
  {
    to: "3",
    image1: rundwegImage3,
    title: "An Spätsommer­abenden mit dem Rad über die Donauinsel",
    short: "Donauinsel",
    description: rundwegDescription3,
    speciesList: speciesList3,
    familyList: familyList3,
  },
  {
    to: "4",
    image1: rundwegImage4,
    title: "Durch die Obere Lobau",
    short: "Obere Lobau",
    description: rundwegDescription4,
    speciesList: speciesList4,
    familyList: familyList4,
  },
];

export const hikesWithoutLobau = [
  {
    to: "1",
    image1: rundwegImage1,
    title: "Durch den südlichen Lainzer Tiergarten",
    short: "Lainzer Tiergarten",
    description: rundwegDescription1,
    speciesList: speciesList1,
    familyList: familyList1,
  },
  {
    to: "2",
    image1: rundwegImage2,
    title: "Durch die Weinberge bei Stammersdorf",
    short: "Stammersdorf",
    description: rundwegDescription2,
    speciesList: speciesList2,
    familyList: familyList2,
  },
  {
    to: "3",
    image1: rundwegImage3,
    title: "An Spätsommer­abenden mit dem Rad über die Donauinsel",
    short: "Donauinsel",
    description: rundwegDescription3,
    speciesList: speciesList3,
    familyList: familyList3,
  },
];
