import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./nemobius_sylvestris.jpg";
import audioFile from "./nemobius_sylvestris_006_APA.mp3";
import audioOscillogram from "./nemobius_sylvestris_006_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 22.73,
    audioDescription: (
      <>
        Der Gesang besteht aus leisen, wohlklingenden „Morselauten“, die etwas an ein weit entferntes <SpeciesLink to="oecanthus_pellucens" text="Weinhähnchen" /> erinnern. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "B",
  daytime: "Tag/Nacht",
  months: "Juli – September",
  to: "nemobius_sylvestris",
  german: "Waldgrille",
  latin: "Nemobius sylvestris",
  family: "Käfergrillen (Trigonidiidae)",
  first: "um 1820, „Kahlengebirge“",
  districts: "8 (13., 14., 16., 17., 19., 21.–23.)",
  description: "Der Verbreitungsschwerpunkt der Waldgrille umfasst die  Wienerwaldbezirke, wo sie vor allem trockene Waldbestände besiedelt, aber auch in Gärten vorkommt, sofern ein ausreichender Gehölzanteil vorhanden ist.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
