import speciesImage from "./pezotettix_giornae.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "D",
  daytime: "",
  months: "Juli – September",
  to: "pezotettix_giornae",
  german: "Kleine Knarrschrecke",
  latin: "Pezotettix giornae",
  family: "Feldheuschrecken (Acrididae)",
  first: "19.7.2011, Zentralverschiebebahnhof Wien-Kledering",
  districts: "4 (10.–12., 14.)",
  description: "Die erst seit 2011 für Wien bekannte Kleine Knarrschrecke bevorzugt unterschiedliche krautige Ruderalflächen mit einem hohen Maß an Trockenheit. Vermutlich vom Verschiebebahnhof Wien-Kledering ausgehend, konnte sie sich entlang von Bahngleisen in mehrere Gebiete Wiens ausbreiten.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Markus Sehnal",
};

export default data;
