import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./pholidoptera_fallax.jpg";
import audioFile from "./pholidoptera_fallax_014_APA.mp3";
import audioOscillogram from "./pholidoptera_fallax_014_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 14.555,
    audioDescription: (
      <>
        Der Gesang ist recht ähnlich dem der <SpeciesLink to="pholidoptera_griseoaptera" text="Gewöhnlichen Strauchschrecke" /> und besteht aus einzelnen scharfen „zrrt“-Lauten. Hintergrundstimmen: <SpeciesLink to="gryllus_campestris" text="Feldgrille" after="," /> <SpeciesLink to="tettigonia_cantans" text="Zwitscher-Heupferd" after="," /> <SpeciesLink to="tettigonia_viridissima" text="Grünes Heupferd" after="." /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Nacht/Tag",
  months: "Juni – September",
  to: "pholidoptera_fallax",
  german: "Südliche Strauchschrecke",
  latin: "Pholidoptera fallax",
  family: "Laubheuschrecken (Tettigoniidae)",
  first: "1859, Weidlingau und Hütteldorf",
  districts: "3 (13., 14., 23.)",
  description: "Als anspruchsvollste der drei Strauchschrecken-Arten bewohnt die Südliche Strauchschrecke vor allem extensive Mähwiesen und Halbtrockenrasen. Die einzigen Wiener Vorkommen befinden sich im südlichen Bereich des Wienerwaldes.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Werner Reitmeier",
};

export default data;
