import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./eupholidoptera_schmidti.jpg";
import audioFile from "./eupholidoptera_schmidti_011_APA.mp3";
import audioOscillogram from "./eupholidoptera_schmidti_011_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 17.687,
    audioDescription: (
      <>
        Die Art produziert recht markante, laute und scharfe Einzeltöne (wie eine Mischung aus <SpeciesLink to="pholidoptera_griseoaptera" text="Gewöhnlicher Strauchschrecke" /> und <SpeciesLink to="ephippiger_ephippiger" text="Steppen-Sattelschrecke" /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok ). Hintergrundstimmen: <SpeciesLink to="nemobius_sylvestris" text="Waldgrille" after="," /> <SpeciesLink to="pholidoptera_griseoaptera" text="Gewöhnliche Strauchschrecke" after="." /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Nacht",
  months: "Juli – Oktober",
  to: "eupholidoptera_schmidti",
  german: "Schmidts Grüne Strauchschrecke",
  latin: "Eupholidoptera schmidti",
  family: "Laubheuschrecken (Tettigoniidae)",
  first: "20.7.2017, 2. Bezirk, Hotel Hilton",
  districts: "3 (2., 18., 22.)",
  districtsYear: "2020",
  description: "Dieser jüngste Neuzugang der Wiener Heuschreckenfauna wurde ursprünglich nur in den Gebüschen am südlichen Donauufer nahe dem Ferry-Dusika-Stadion entdeckt und befindet sich derzeit in Ausbreitung. Die Art wurde eingeschleppt.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Lorin Timaeus",
};

export default data;
