import speciesImage from "./tetrix_tuerki.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "E",
  daytime: "",
  months: "Mai – September",
  to: "tetrix_tuerki",
  german: "Türks Dornschrecke",
  latin: "Tetrix tuerki",
  family: "Dornschrecken (Tetrigidae)",
  first: "um 1859, „Prater“",
  last: "um 1930, „Prater“",
  districts: "4 (1., 20., 21., 22.)",
  description: "Die erste Donauregulierung und ihre Folgen sorgten früh für das Aussterben von Türks Dornschrecke in Wien. Hier lebte sie auf natürlichen Sand- und Schotterbänken der Donau. Sie ist die einzige Heuschreckenart, die aus der Bundeshauptstadt erstbeschrieben wurde – der Holotypus stammt aus dem Prater.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
