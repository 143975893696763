import speciesImage from "./meconema_meridionale.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "D",
  daytime: "Nacht",
  months: "Juli – November",
  to: "meconema_meridionale",
  german: "Südliche Eichenschrecke",
  latin: "Meconema meridionale",
  family: "Eichenschrecken (Meconematidae)",
  first: "2.11.1953, Schönbrunn",
  districts: "22 (2.–23.)",
  description: "Die Südliche Eichenschrecke ist aus fast allen Bezirken nachgewiesen, doch erscheint ihr Verbreitungsbild zumindest in den Randbezirken lückig. Dies hängt vor allem mit ihrer versteckten Lebensweise in Bäumen und Büschen zusammen, die meist nur Zufallsbeobachtungen zulässt. Eichenschrecken haben keine Stridulationsorgane, können aber zur Kommunikation mit den Hinterbeinen auf Blätter oder Äste trommeln. Dies ist jedoch auch mit einem gängigen Ultraschall-Detektor nicht hörbar.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Markus Sehnal",
};

export default data;
