import React from "react";

import speciesImage from "./platycleis_grisea.jpg";
import audioFile from "./platycleis_grisea_011_Tag_APA.mp3";
import audioOscillogram from "./platycleis_grisea_011_Tag_APA.png";
import audioFile2 from "./platycleis_grisea_005_APA.mp3";
import audioOscillogram2 from "./platycleis_grisea_005_APA.png";

const data = {
  released: true,
  audioData: [
    {
      audioFile: audioFile,
      audioOscillogram: audioOscillogram,
      audioDuration: 20.534,
      audioDescription: <>Taggesang. Die leisen, aber dennoch gut wahrnehmbaren Serien von feinsilbigen Kratzlauten mit tagsüber deutlich rascheren Strophen als nachts kann man vor allem in trockenen Lebensräumen hören. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
    },
    {
      audioFile: audioFile2,
      audioOscillogram: audioOscillogram2,
      audioDuration: 10.479,
      audioDescription: <>Nachtgesang. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
    },
  ],
  audible: "C",
  daytime: "Tag/Nacht",
  months: "Juli – September",
  to: "platycleis_grisea",
  german: "Graue Beißschrecke",
  latin: "Platycleis grisea",
  family: "Laubheuschrecken (Tettigoniidae)",
  first: "um 1820, „Türkenschanze“",
  districts: "20 (2., 3., 5., 7.–23.)",
  description: "Die in Wien weit verbreitete Graue Beißschrecke ist im Stadtgebiet nahezu auf jeder Ruderalfläche mit einem gewissen Maß an hochwüchsiger und lückiger Vegetation zu finden.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
