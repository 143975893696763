import React from "react";
import { Link, Redirect, useParams } from "react-router-dom";

import Header from "./Header";
import AudioPlayer from "./AudioPlayer";
import Footer from "./Footer";

import "../css/content.scss";
import "../css/navigation.scss";
import "../css/symbols.scss";

function AudioSection(props) {
  const audible = props.data.audible;
  const audioData = props.data.audioData;
  if (audible === "E") {
    return <></>;
  } else if (!audioData) {
    return <p>Derzeit keine Audioaufnahme vorhanden.</p>;
  } else {
    if (Array.isArray(audioData)) {
      return (
        <>
          {audioData.map((song) => (
            <AudioPlayer key={song.audioDescription} audioData={song} />
          ))}
        </>
      );
    } else {
      return <AudioPlayer audioData={audioData} />;
    }
  }
}

function DaytimeSymbol(props) {
  switch (props.daytime) {
    case "Nacht/Tag":
      return <img className="symbol__night-day" alt={props.daytime} />;
    case "Tag/Nacht":
      return <img className="symbol__day-night" alt={props.daytime} />;
    case "Tag":
      return <img className="symbol__day" alt={props.daytime} />;
    case "Nacht":
      return <img className="symbol__night" alt={props.daytime} />;
    default:
      return <>"props.daytime"</>;
  }
}

function audibleDescription(letter) {
  switch (letter) {
    case "A":
      return "gut";
    case "B":
      return "mäßig";
    case "C":
      return "Ultraschall-Detektor empfohlen";
    case "D":
      return "kaum hörbar";
    case "E":
      return "keine Lautäußerungen";
    default:
      return letter;
  }
}

function Species(props) {
  let { species } = useParams();
  if (!props.speciesData[species] || !props.speciesData[species].released) {
    return <Redirect to="/heuschrecken" />;
  }
  const data = props.speciesData[species];

  return (
    <>
      <Header
        title={`${data.german} – Insekten in Wien`}
        logoTextSmall="Insekten in Wien"
        logoTextLarge="Heuschrecken"
        logoLink="/heuschrecken"
        links={[
          { to: "/heuschrecken/liste", active: false, text: "Arten" },
          { to: "/heuschrecken/rundwege", active: true, text: "Rundwege" },
        ]}
      />

      <div className="content__container">
        <div className="content">
          <Link to="/heuschrecken/liste" className="nav__button">
            Artenliste
          </Link>
          <figure>
            <img className="content__img" src={data.speciesImage} alt={data.german} />
            <figcaption className="content__img-author">{data.speciesImageAuthor}</figcaption>
          </figure>
          <h1>
            <div>{data.german}</div>
            <div className="content__text-species-latin">{data.latin}</div>
          </h1>
          <p>
            <b>Familie:</b> {data.family}
            {data.first ? (
              <>
                <br />
                <b>Erstfund für Wien:</b> {data.first}
              </>
            ) : (
              ""
            )}
            {data.only ? (
              <>
                <br /> <b>Einziger Fund für Wien:</b> {data.only}
              </>
            ) : (
              ""
            )}
            {data.last ? (
              <>
                <br />
                <b>Letztfund für Wien:</b> {data.last}
              </>
            ) : (
              ""
            )}
            <br />
            <b>Bezirke (Stand {data.districtsYear ? data.districtsYear : "2017"}):</b> {data.districts}
            {data.audible ? (
              <>
                <br />
                <b>Akustisch wahrnehmbar:</b> {audibleDescription(data.audible)}
              </>
            ) : (
              ""
            )}
            {data.months ? (
              <>
                <br />
                <b>Zeitraum:</b> {data.months}
                {data.daytime ? (
                  <>
                    , <DaytimeSymbol daytime={data.daytime} />
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
          </p>
          <p>{data.description}</p>
          <AudioSection data={data} />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Species;
