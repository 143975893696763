import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./pseudochorthippus_montanus.jpg";
import audioFile from "./pseudochorthippus_montanus_002_APA.mp3";
import audioOscillogram from "./pseudochorthippus_montanus_002_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 17.271,
    audioDescription: (
      <>
        Die Gesangsstruktur ist ident mit jeder des <SpeciesLink to="pseudochorthippus_parallelus" text="Gemeinen Grashüpfers" /> (gleichmäßig aneinandergereihte Einzeltöne), doch werden die Verse deutlich langsamer als bei diesem vorgetragen. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Tag",
  months: "Juli – August",
  to: "pseudochorthippus_montanus",
  german: "Sumpfgrashüpfer",
  latin: "Pseudochorthippus montanus",
  family: "Feldheuschrecken (Acrididae)",
  first: "19.9.1890, Ober-St.-Veit",
  districts: "4 (13., 21.–23.)",
  description: "Der auf Feucht- und Sumpfwiesen spezialisierte Sumpfgrashüpfer ist in Wien aktuell nur von einem Fundpunkt einer Quellflur auf der Eichwiese im 23. Bezirk bekannt; die wenigen historischen Vorkommen scheinen alle erloschen zu sein.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
