import React from "react";
import { Link, Redirect, useParams } from "react-router-dom";

import Header from "./Header";
import SpeciesList from "./SpeciesList";
import Footer from "./Footer";

import "../css/content.scss";
import "../css/navigation.scss";

function Hike(props) {
  const { id } = useParams();
  let data = {};
  if (id !== "1" && id !== "2" && id !== "3") {
    return <Redirect to="/heuschrecken/rundwege" />;
  } else {
    data = props.hikes[parseInt(id) - 1];
  }

  return (
    <>
      <Header
        title={`${data.short} – Insekten in Wien`}
        logoTextSmall="Insekten in Wien"
        logoTextLarge="Heuschrecken"
        logoLink="/heuschrecken"
        links={[
          { to: "/heuschrecken/liste", active: true, text: "Arten" },
          { to: "/heuschrecken/rundwege", active: false, text: "Rundwege" },
        ]}
      />
      <div className="content__container">
        <div className="content">
          <Link
            to="/heuschrecken/rundwege"
            className="nav__button"
          >
            Rundwege Übersicht
          </Link>
          {data.description}
          <h1>Artenliste:</h1>
          {data.speciesList ? (
            <SpeciesList
              speciesIncluded={data.speciesList}
              familiesIncluded={data.familyList}
            />
          ) : (
            ""
          )}
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Hike;
