import React from "react";

import speciesImage from "./conocephalus_fuscus.jpg";
import audioFile from "./conocephalus_fuscus_BD_004_APA.mp3";
import audioOscillogram from "./conocephalus_fuscus_BD_004_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 20.401633,
    audioDescription: <>Ultraschalldetektor-Aufnahme. Die gleichmäßig vorgetragenen und oft lange anhaltenden Strophen erinnern rhythmisch entfernt an eine Nähmaschine. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
  },
  audible: "C",
  daytime: "Tag/Nacht",
  months: "Juli – September",
  to: "conocephalus_fuscus",
  german: "Langflügelige Schwertschrecke",
  latin: "Conocephalus fuscus",
  family: "Laubheuschrecken (Tettigoniidae)",
  first: "um 1850, Liesing",
  districts: "20 (1.–3., 6., 7., 9.–23.)",
  description: "In Wien besiedelt die Langflügelige Schwertschrecke vor allem die Randbezirke und unbebauten Landstriche, doch dringt sie vielerorts auch in die innerstädtischen Bereiche vor, solange hochwüchsige Brachen und „Gstett’n“ in ausreichendem Maße vorhanden sind. Auch Feuchtgebiete zählen zu ihren Vorzugslebensräumen.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
