import React from "react";

import speciesImage from "./eumodicogryllus_bordigalensis.jpg";
import audioFile from "./eumodicogryllus_bordigalensis_022_APA.mp3";
import audioOscillogram from "./eumodicogryllus_bordigalensis_022_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 45.289,
    audioDescription: <>Der Gesang ertönt oft aus Gleiskörpern und Bodenritzen und besteht aus fortlaufenden, stotternden Versen. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
  },
  audible: "A",
  daytime: "Nacht/Tag",
  months: "Mai – September",
  to: "eumodicogryllus_bordigalensis",
  german: "Südliche Grille",
  latin: "Eumodicogryllus bordigalensis",
  family: "Echte Grillen (Gryllidae)",
  first: "22.8.2002, 10. Bezirk, Neilreichgasse",
  districts: "12 (1., 2., 9.–12., 14., 16., 20.–23.)",
  description: "Die Südliche Grille ist stark in Ausbreitung begriffen und wird selbst in der Innenstadt zunehmend registriert, wobei sie durch ihren markanten Gesang auf sich aufmerksam macht. Optisch werden die Tiere nur selten entdeckt.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Florin Rutschmann",
};

export default data;
