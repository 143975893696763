import React from "react";
import { Link } from "react-router-dom";

import "../css/content.scss";
import "../css/Footer.scss";

function Footer(props) {
  return (
    <footer>
      <div className="content__container">
        <div className="footer">
          <p className="footer__smallprint">© 2023 Österreichische Gesellschaft für Entomofaunistik, Wien</p>
          <Link
            to="/impressum"
            className="footer__smallprint footer__smallprint--link"
          >
            Impressum
          </Link>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
