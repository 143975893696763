import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./chorthippus_apricarius.jpg";
import audioFile from "./chorthippus_apricarius_003_APA.mp3";
import audioOscillogram from "./chorthippus_apricarius_003_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 28.905,
    audioDescription: (
      <>
        Der markante, an eine landwirtschaftliche Sprinkleranlage erinnernde Gesang kann am ehesten mit den Lauten des <SpeciesLink to="chorthippus_mollis" text="Verkannten Grashüpfers" /> verwechselt werden, ist jedoch konstanter, schneller und etwas feiner. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Tag",
  months: "Juli – August",
  to: "chorthippus_apricarius",
  german: "Feldgrashüpfer",
  latin: "Chorthippus apricarius",
  family: "Feldheuschrecken (Acrididae)",
  first: "um 1880, Mauer und Rodaun",
  districts: "16 (2., 3., 5., 10.–14., 16–23.)",
  description: "Der deutsche Name dieser unscheinbar aussehenden Grashüpferart ist sehr zutreffend: Höherwüchsige Feldraine zählen zu den präferierten Lebensräumen des Feldgrashüpfers. So ist er in nicht allzu dicht bebauten Gebieten Wiens regelmäßig zu finden.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Wolfgang Schweighofer",
};

export default data;
