import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./chorthippus_vagans.jpg";
import audioFile from "./chorthippus_vagans_003_APA.mp3";
import audioOscillogram from "./chorthippus_vagans_003_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 6.432,
    audioDescription: (
      <>
        Die Verse bestehen aus recht auffälligen, relativ lauten und rasch aneinandergereihten Einzeltönen (meist 10–20) und ähneln dem Gesang des <SpeciesLink to="pseudochorthippus_parallelus" text="Gemeinen Grashüpfers" after="." /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Tag",
  months: "August – September",
  to: "chorthippus_vagans",
  german: "Steppengrashüpfer",
  latin: "Chorthippus vagans",
  family: "Feldheuschrecken (Acrididae)",
  first: ": August 1994, Wilhelminenberg",
  districts: "5 (13., 16., 19., 22., 23.)",
  description: "Der Steppengrashüpfer ist in Wien im Osten vom Ölhafendamm in der Lobau und im Westen von nur wenigen Lokalitäten aus dem Wienerwald bekannt. Er ist – auch wenn sein Name anderes vermuten ließe – eine Charakterart thermophiler, lichter Waldbestände und stellt hier recht anspruchsvolle Anforderungen an seinen Lebensraum.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
