import React from "react";
import { Link } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";

import "../css/content.scss";
import "../css/navigation.scss";

function Error404(props) {
  let url = window.location.href;

  return (
    <>
      <Header title="404 - Seite nicht gefunden" logoTextSmall="&nbsp;" logoTextLarge="Insekten in Wien" logoLink="/" links={[]} />
      <div className="content__container">
        <div className="content">
          <Link to="/" className="nav__button">
            zur Startseite
          </Link>
          <h1>Fehler 404: Nicht gefunden</h1>
          <p>
            Die Seite <b>{url}</b> wurde nicht gefunden!
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Error404;
