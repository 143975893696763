import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./bicolorana_bicolor.jpg";
import audioFile from "./bicolorana_bicolor_004_APA.mp3";
import audioOscillogram from "./bicolorana_bicolor_004_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 12.667,
    audioDescription: (
      <>
        Der Gesang besteht tagsüber bzw. bei Sonnenschein aus durchgehenden, schnellen Reihe hoher Silben. Im Unterschied zu <SpeciesLink to="roeseliana_roeselii" text="Roesels Beißschrecke" /> kann man die einzelnen Silben akustisch gut auflösen. Bei kühlerer Witterung oder nachts deutlich anders klingend mit 3–5 Sekunden andauernden Tonreihen (Aufnahme: Taggesang bei Sonnenschein). Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Tag/Nacht",
  months: "Juni – August",
  to: "bicolorana_bicolor",
  german: "Zweifarbige Beißschrecke",
  latin: "Bicolorana bicolor",
  family: "Laubheuschrecken (Tettigoniidae)",
  first: "1836, Kahlenberg",
  districts: "14 (2., 3., 10.–14., 16., 17., 19.–23.)",
  description: "In Brachen und Trockenwiesen ist die Zweifarbige Beißschrecke eine der häufigsten Langfühlerschrecken Wiens. Sie weist nur im dicht bebauten Siedlungsraum größere Verbreitungslücken auf.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Matthias Kropf",
};

export default data;
