import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./euthystira_brachyptera.jpg";
import audioFile from "./euthystira_brachyptera_004_APA.mp3";
import audioOscillogram from "./euthystira_brachyptera_004_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 4.578,
    audioDescription: (
      <>
        Die Lautäußerungen sind kürzer, höherfrequent und etwas leiser als die der <SpeciesLink to="chrysochraon_dispar" text="Großen Goldschrecke" after="." /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "B",
  daytime: "Tag",
  months: "Juni – August",
  to: "euthystira_brachyptera",
  german: "Kleine Goldschrecke",
  latin: "Euthystira brachyptera",
  family: "Feldheuschrecken (Acrididae)",
  first: "1908, Neustift am Walde",
  districts: "12 (2., 3., 10., 11., 13., 14., 16., 17., 19., 21.–23.)",
  description: "Die Kleine Goldschrecke ist vor allem eine Art vertikal gut strukturierter Gras- und Krautbestände mit verbrachender Tendenz. Ihre beiden Schwerpunkte in Wien liegen in den Wienerwald-Randzonen und der Lobau.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
