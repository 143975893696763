import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./isophya_modestior.jpg";
import audioFile from "./isophya_modestior_003_APA.mp3";
import audioOscillogram from "./isophya_modestior_003_APA.png";
import audioFile2 from "./isophya_modestior_BD_004_APA.mp3";
import audioOscillogram2 from "./isophya_modestior_BD_004_APA.png";

const data = {
  released: true,
  audioData: [
    {
      audioFile: audioFile,
      audioOscillogram: audioOscillogram,
      audioDuration: 16.221762,
      audioDescription: (
        <>
          Die gleichbleibenden Einzelverse mit längeren Abständen (je nach Witterung etwa 5–15 Sekunden) können am ehesten mit der in Wien nicht vorkommenden Pieniner Plumpschrecke (<i>Isophya pienensis</i>) verwechselt werden. Hintergrundstimmen: <SpeciesLink to="gryllus_campestris" text="Feldgrille" after=";" /> Nachtigall. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
        </>
      ),
    },
    {
      audioFile: audioFile2,
      audioOscillogram: audioOscillogram2,
      audioDuration: 18.598864,
      audioDescription: <>Ultraschalldetektor-Aufnahme. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
    },
  ],
  audible: "C",
  daytime: "Nacht",
  months: "Juni – Juli",
  to: "isophya_modestior",
  german: "Große Plumpschrecke",
  latin: "Isophya modestior",
  family: "Sichelschrecken (Phaneropteridae)",
  first: "10.8.1999, 14. Bezirk, Salzwiese/Kolbeterberg",
  districts: "2 (14., 23.)",
  description: (
    <>
      Die Große Plumpschrecke ist in Wien wesentlich seltener als <SpeciesLink to="isophya_camptoxypha" text="Fiebers Plumpschrecke" /> und wurde bisher nur auf zwei Wiesenkomplexen im 14. und 23. Bezirk gefunden. Sie bewohnt hier spät gemähte Magerwiesen.
    </>
  ),
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Werner Reitmeier",
};

export default data;
