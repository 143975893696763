import speciesImage from "./sphingonotus_caerulans.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "D",
  daytime: "Tag",
  months: "Juli – Oktober",
  to: "sphingonotus_caerulans",
  german: "Blauflügelige Sandschrecke",
  latin: "Sphingonotus caerulans",
  family: "Feldheuschrecken (Acrididae)",
  first: "um 1820, „Donauauen“",
  districts: "9 (2., 3., 9.–11., 13., 19., 21., 22.)",
  description: "Zwar tritt die hochmobile Blauflügelige Sandschrecke meist in geringer Individuenzahl auf, doch ist sie auf anthropogenen Schotter- oder Kiesflächen bei genauer Suche regelmäßig anzutreffen. Verbreitungsschwerpunkte in Wien lassen sich kaum ausmachen, die Funde sind weit verstreut. ",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
