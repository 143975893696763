import React from "react";

import speciesImage from "./gryllodes_sigillatus.jpg";
import audioFile from "./gryllodes_sigillatus.mp3";
import audioOscillogram from "./gryllodes_sigillatus.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 22.105,
    audioDescription: <>Die Gesangsstrophe besteht aus einer koninuierlichen Reihe hoher Töne (ca. 6 „Anschläge“ pro Sekunde), die ohne Pause minutenlang geäußert werden. Tonaufnahme:&nbsp;Julia&nbsp;Bauder</>,
  },
  audible: "A",
  daytime: "Nacht/Tag",
  months: "ganzjährig",
  to: "gryllodes_sigillatus",
  german: "Kurzflügelgrille",
  latin: "Gryllodes sigillatus",
  family: "Echte Grillen (Gryllidae)",
  first: "Mai 1932, Holzwarenfabrik im 12. Bezirk",
  districts: "5 (1., 2., 9., 12., 13.)",
  description: "Obwohl die Kurzflügelgrille eine eingebürgerte Art ist, kann sie zumindest in Gebäuden mit entsprechendem Klima eigenständige Populationen aufbauen, wie etwa im Tiergarten Schönbrunn. Funde in Wien sind demnach verstreut und selten.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Lorin Timaeus",
};

export default data;
