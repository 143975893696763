import React from "react";
import { Link } from "react-router-dom";

import { speciesData } from "../data/data.js";

import "../css/content.scss";
import "../css/navigation.scss";
import "../css/symbols.scss";

// replace regex for <Link>:
//(|\{" "\})\n +<Link (|className="[^"]+" )to="/heuschrecken/([^"+]_[^"+]+)"[^>]+>\n +(.+)\n +</Link>(|\{" "\})\n +
//$1<SpeciesLink to="$3" text="$4"/>$5
// replace {" "} in a separate step (auto-formatting should take care of it)

function SpeciesLink(props) {
  // with speaker icon
  if (speciesData[props.to].audioData) {
    if (props.after) {
      return (
        <span className="nowrap">
          <Link key={speciesData[props.to].latin} className="symbol__speaker" to={"/heuschrecken/" + speciesData[props.to].to} target="_blank">
            {props.text ? props.text : speciesData[props.to].german}
          </Link>
          {props.after}
        </span>
      );
    } else {
      return (
        <Link key={speciesData[props.to].latin} className="symbol__speaker" to={"/heuschrecken/" + speciesData[props.to].to} target="_blank">
          {props.text ? props.text : speciesData[props.to].german}
        </Link>
      );
    }
  }

  // without speaker icon
  if (props.after) {
    return (
      <span className="nowrap">
        <Link key={speciesData[props.to].latin} to={"/heuschrecken/" + speciesData[props.to].to} target="_blank">
          {props.text ? props.text : speciesData[props.to].german}
        </Link>
        {props.after}
      </span>
    );
  } else {
    return (
      <Link key={speciesData[props.to].latin} to={"/heuschrecken/" + speciesData[props.to].to} target="_blank">
        {props.text ? props.text : speciesData[props.to].german}
      </Link>
    );
  }
}

export default SpeciesLink;
