import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./chrysochraon_dispar.jpg";
import audioFile from "./chrysochraon_dispar_007_APA.mp3";
import audioOscillogram from "./chrysochraon_dispar_007_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 17.015,
    audioDescription: (
      <>
        Der Gesang ist jenem des <SpeciesLink to="pseudochorthippus_parallelus" text="Gemeinen Grashüpfers" /> nicht unähnlich und in seinem Klang voller als jener der <SpeciesLink to="euthystira_brachyptera" text="Kleinen Goldschrecke" after="." /> Hintergrundstimmen: <SpeciesLink to="pseudochorthippus_parallelus" text="Gemeiner Grashüpfer" after="," /> <SpeciesLink to="bicolorana_bicolor" text="Zweifarbige Beißschrecke" after=";" /> Goldammer. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Tag/Nacht",
  months: "Juni – August",
  to: "chrysochraon_dispar",
  german: "Große Goldschrecke",
  latin: "Chrysochraon dispar",
  family: "Feldheuschrecken (Acrididae)",
  first: "17.7.1890, Ober-St.-Veit",
  districts: "14 (2., 3., 10.–14., 16.–19., 21.–23.)",
  description: "Der Wienerwald und die Lobau sind die beiden Kerngebiete der Großen Goldschrecke in Wien. Vermehrt wird sie jedoch auch in dicht- und hochwüchsigen Brachflächen im locker bebauten Siedlungsraum gefunden.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Werner Reitmeier",
};

export default data;
