import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./chorthippus_dorsatus.jpg";
import audioFile from "./chorthippus_dorsatus_004_APA.mp3";
import audioOscillogram from "./chorthippus_dorsatus_004_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 11.994,
    audioDescription: (
      <>
        Der „startende Motor“ des Wiesengrashüpfers ist unverkennbar: Die ersten Verse klingen wie beim <SpeciesLink to="pseudochorthippus_parallelus" text="Gemeinen Grashüpfer" after="," /> zeigen aber am Ende den arttypischen Schwirrvers. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Tag",
  months: "August – September",
  to: "chorthippus_dorsatus",
  german: "Wiesengrashüpfer",
  latin: "Chorthippus dorsatus",
  family: "Feldheuschrecken (Acrididae)",
  first: "27.8.1904, Cobenzl",
  districts: "18 (2., 3., 8.–23.)",
  description: "Als typische und häufige Grünlandart kommt der Wiesengrashüpfer auf trockenen bis feuchten Wiesen vor und ist seinem breiten Habitatspektrum entsprechend flächendeckend in Wien verbreitet.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
