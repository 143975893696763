import speciesImage from "./tetrix_tenuicornis.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "E",
  daytime: "",
  months: "März – September",
  to: "tetrix_tenuicornis",
  german: "Langfühler-Dornschrecke",
  latin: "Tetrix tenuicornis",
  family: "Dornschrecken (Tetrigidae)",
  first: "4.4.1890, Ober-St.-Veit",
  districts: "14 (2., 3., 10.–19., 21.–23.)",
  description: "Bei der Langfühler-Dornschrecke handelt es sich um die häufigste Dornschreckenart Wiens, deren Verbreitung in der Realität vermutlich wesentlich weniger Lücken zeigt als auf der Verbreitungskarte. Schwerpunkte liegen im Wienerwald und in Donaunähe.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Markus Sehnal",
};

export default data;
