import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./meconema_thalassinum.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "D",
  daytime: "Nacht",
  months: "Juli – Oktober",
  to: "meconema_thalassinum",
  german: "Gemeine Eichenschrecke",
  latin: "Meconema thalassinum",
  family: "Eichenschrecken (Meconematidae)",
  first: "9.8.1891, Ober-St.-Veit",
  districts: "15 (1., 2., 4., 8., 10.–14., 16.–19., 22., 23.)",
  description: (
    <>
      Aktuelle Funde der Gemeinen Eichenschrecke sind dünn gesät. Da die Art jedoch (wie auch die <SpeciesLink to="meconema_meridionale" text="Südliche Eichenschrecke" after=")" /> versteckt in Bäumen lebt, wird der tatsächliche Bestand wohl um einiges höher sein als der bislang bekannte. Eichenschrecken haben keine Stridulationsorgane, können aber zur Kommunikation mit den Hinterbeinen auf Blätter oder Äste trommeln. Dies ist jedoch auch mit einem gängigen Ultraschall-Detektor nicht hörbar.
    </>
  ),
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Markus Sehnal",
};

export default data;
