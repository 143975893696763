import React from "react";

import speciesImage from "./decticus_verrucivorus.jpg";
import audioFile from "./decticus_verrucivorus_002_APA.mp3";
import audioOscillogram from "./decticus_verrucivorus_002_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 21.82,
    audioDescription: <>Die Strophe beginnt mit metallisch klingenden Einzeltönen, die in immer kürzeren Zeitabständen vorgetragen werden und sich letztlich zu einem oft minutenlang vorgetragenen Ticken aufbauen. Die Männchen beenden ihre Gesänge bei Annäherung recht bald und lassen sich dabei häufig von ihrer Sitzwarte auf den Boden fallen. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
  },
  audible: "A",
  daytime: "Tag",
  months: "Juni – August",
  to: "decticus_verrucivorus",
  german: "Warzenbeißer",
  latin: "Decticus verrucivorus",
  family: "Laubheuschrecken (Tettigoniidae)",
  first: "1908, Dreimarkstein",
  districts: "8 (13., 14., 16., 17., 19., 21.–23.)",
  description: "Der imposante Warzenbeißer ist eine der größten Heuschreckenarten Wiens und bewohnt vor allem Magerwiesen in den Wienerwald-Bezirken. Abseits davon sind die Nachweise spärlich.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
