import React, { useState, useRef } from "react";
import { Link } from "react-router-dom";

import { speciesData } from "../data/data.js";
import { alphabetical } from "../data/search/alphabetical.js";
import { systematic } from "../data/search/systematic.js";

import "../css/content.scss";
import "../css/navigation.scss";

function replaceSpecialCharacters(string) {
  string = string.toLowerCase();
  string = string.replace("ö", "o");
  string = string.replace("ä", "a");
  string = string.replace("ü", "u");
  string = string.replace("ß", "s");
  return string;
}

// function makeAlphabeticalList(speciesData) {
//   let alphabetical = [];
//   for (const key in speciesData) {
//     if (key.indexOf("family") === -1) {
//       const searchStringGerman = replaceSpecialCharacters(speciesData[key].german);
//       const searchStringLatin = speciesData[key].latin.toLowerCase();

//       let searchList = [searchStringGerman, searchStringLatin];

//       let searchStringGermanWords = searchStringGerman.replace("-", " ").split(" ");
//       if (searchStringGermanWords.length > 1) {
//         searchStringGermanWords = searchStringGermanWords.slice(1);
//         searchList = searchList.concat(searchStringGermanWords);
//       }

//       let searchStringLatinWords = searchStringLatin.replace("-", " ").split(" ");
//       if (searchStringLatinWords.length > 1) {
//         searchStringLatinWords = searchStringLatinWords.slice(1);
//         searchList = searchList.concat(searchStringLatinWords);
//       }

//       let alphabeticalEntry = {
//         key: key,
//         searchList: searchList,
//         german: speciesData[key].german,
//         latin: speciesData[key].latin,
//       };
//       alphabetical.push(alphabeticalEntry);
//     }
//   }
//   alphabetical.sort((a, b) => b.searchList[0] < a.searchList[0]);
//   return alphabetical;
// }

// function makeSystematicList(speciesData) {
//   let systematic = [];
//   for (const key in speciesData) {
//     let systematicEntry = {
//       key: key,
//       german: speciesData[key].german,
//       latin: speciesData[key].latin,
//     };
//     systematic.push(systematicEntry);
//   }
//   return systematic;
// }

function SpeciesList(props) {
  const [searching, setSearching] = useState(false);
  const [list, setList] = useState(systematic);
  const [foundCount, setFoundCount] = useState(0);

  const input = useRef(null);

  // displays a single species or family name if included (empty include list means all included)
  function displayEntry(key) {
    if (key.indexOf("family") !== -1) {
      // display family name
      if (props.familiesIncluded.length > 0 && props.familiesIncluded.indexOf(key) === -1) {
        return "";
      }

      return (
        <h2 key={speciesData[key].latin} className="content__h2-list">
          {speciesData[key].german} ({speciesData[key].latin})
        </h2>
      );
    } else if (speciesData[key].released) {
      // display species name
      if (props.speciesIncluded.length > 0 && props.speciesIncluded.indexOf(key) === -1) {
        return "";
      }

      if (speciesData[key].audioData) {
        // with speaker icon
        return (
          <Link key={speciesData[key].latin} className="nav__button-large nav__button-large--audio" to={"/heuschrecken/" + speciesData[key].to}>
            {speciesData[key].german}
            <div className="content__smalltext">
              <i>{speciesData[key].latin}</i>
            </div>
          </Link>
        );
      } else {
        // without speaker icon
        return (
          <Link key={speciesData[key].latin} className="nav__button-large nav__button-large--audio nav__button-large--audio-no-speaker" to={"/heuschrecken/" + speciesData[key].to}>
            {speciesData[key].german}
            <div className="content__smalltext">
              <i>{speciesData[key].latin}</i>
            </div>
          </Link>
        );
      }
    } else {
      return "";
    }
  }

  // checks whether:
  // - species is included for this SpeciesList (empty speciesIncluded means all included)
  // - species searchList contains searchString
  function searchEntry(entry, searchString) {
    if (props.speciesIncluded.length > 0 && props.speciesIncluded.indexOf(entry.key) === -1) {
      return false;
    }
    searchString = replaceSpecialCharacters(input.current.value);
    if (!entry.searchList || !searchString) {
      return false;
    }
    for (const searchEntry in entry.searchList) {
      if (entry.searchList[searchEntry].startsWith(searchString)) {
        return true;
      }
    }
    return false;
  }

  // performs search for input value for each entry in alphabetical list
  function search() {
    if (!input.current.value) {
      setSearching(false);
      setList(systematic);
      setFoundCount(0);
      return;
    }
    const searchList = alphabetical.filter((entry) => searchEntry(entry, input.current.value));

    setSearching(true);
    setFoundCount(searchList.length);
    setList(searchList);
  }

  return (
    <>
      <div>
        <input className="nav__search-input" type="text" placeholder="Suche" ref={input} onChange={search} />
        {searching ? (
          <button
            className="nav__search-cancel"
            onClick={() => {
              input.current.value = "";
              setSearching(false);
              setList(systematic);
              setFoundCount(0);
            }}
          />
        ) : (
          ""
        )}
        {searching ? <h2 className="content__h2-list">Suchergebnisse ({foundCount}):</h2> : ""}
      </div>
      {list.map((entry) => displayEntry(entry.key))}
    </>
  );
}

export default SpeciesList;
