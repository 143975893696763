import React from "react";

import speciesImage from "./modicogryllus_frontalis.jpg";
import audioFile from "./modicogryllus_frontalis_001_APA.mp3";
import audioOscillogram from "./modicogryllus_frontalis_001_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 21.307,
    audioDescription: <>Der leise Gesang der Östlichen Grille wird offenbar meist nur während der Balz und beim Aufeinandertreffen zweier Männchen vorgetragen. Hintergrundstimmen: Grünfink, Buchfink, Mönchsgrasmücke, Amsel, Aaskrähe, Feldsperling. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
  },
  audible: "B",
  daytime: "Tag/Nacht",
  months: "Mai – Juni",
  to: "modicogryllus_frontalis",
  german: "Östliche Grille",
  latin: "Modicogryllus frontalis",
  family: "Echte Grillen (Gryllidae)",
  first: "um 1880, Simmering",
  districts: "5 (10., 11., 14., 21., 22.)",
  description: "Als seltenste Grillenart Wiens kommt die Östliche Grille aktuell nur noch in der Lobau vor, wo sie vor allem Trockenrasen und lückig-sandige Böden bewohnt.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Werner Reitmeier",
};

export default data;
