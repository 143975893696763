import React from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

import "../css/content.scss";
import "../css/navigation.scss";

function Impressum(props) {
  return (
    <>
      <Header title="Impressum – Insekten in Wien" logoTextSmall="&nbsp;" logoTextLarge="Insekten in Wien" logoLink="/" links={[]} />
      <div className="content__container">
        <div className="content">
          <Link to="/" className="nav__button">
            zur Startseite
          </Link>
          <h1>Impressum</h1>
          <p>
            Österreichische Gesellschaft für Entomofaunistik (ÖGEF)
            <br />
            c/o Naturhistorisches Museum Wien
            <br />
            Burgring 7
            <br />
            1010 Wien
            <br />
            Österreich
            <br />
            <a href="http://www.oegef.at/" target="_blank" rel="noopener noreferrer">
              www.oegef.at
            </a>
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Impressum;
