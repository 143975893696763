import React from "react";

import speciesImage from "./tettigonia_viridissima.jpg";
import audioFile from "./tettigonia_viridissima.mp3";
import audioOscillogram from "./tettigonia_viridissima.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 47.179,
    audioDescription: <>In lauen Sommernächten ist das laute, durchgehende Scheppern des Grünen Heupferdes auch im verbauten Stadtgebiet ein stetiger Begleiter. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
  },
  audible: "A",
  daytime: "Nacht/Tag",
  months: "Juli – September",
  to: "tettigonia_viridissima",
  german: "Grünes Heupferd",
  latin: "Tettigonia viridissima",
  family: "Laubheuschrecken (Tettigoniidae)",
  first: "1908; Neustift am Walde, Dreimarkstein und „Wien“",
  districts: "23 (1. – 23.)",
  description: "Das bekannte Grüne Heupferd ist die am weitesten verbreitete Heuschreckenart Wiens und kommt flächendeckend vor. Den umfangreichen Datenbestand verdanken wir dem lauten Gesang, der an Sommerabenden überall in der Stadt zu hören ist.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
