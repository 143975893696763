import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./oecanthus_pellucens.jpg";
import audioFile from "./oecanthus_pellucens.mp3";
import audioOscillogram from "./oecanthus_pellucens.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 44.784,
    audioDescription: (
      <>
        Das Art lässt ein melancholisch anmutendes „Trüüüü Trüüüü“ ertönen, das im Hochsommer und Herbst bis tief in die Nacht vorgetragen wird. Hintergrundstimme: <SpeciesLink to="tettigonia_viridissima" text="Grünes Heupferd" after="." /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Nacht/Tag",
  months: "Juli – September",
  to: "oecanthus_pellucens",
  german: "Weinhähnchen",
  latin: "Oecanthus pellucens",
  family: "Blütengrillen (Oecanthidae)",
  first: "um 1820, Leopoldsberg",
  districts: "23 (1. – 23.)",
  description: "Das Weinhähnchen ist optisch unauffällig und lässt beim Anblick eher keine Grille vermuten. Umso markanter ist sein nächtens oft stundenlang vorgetragener, melancholischer Gesang. Selbst aus Ziersträuchern in der Innenstadt hört man es ab Juli immer wieder stridulieren – seine Verbreitung in Wien ist nahezu flächendeckend.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Florin Rutschmann",
};

export default data;
