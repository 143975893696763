import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./conocephalus_dorsalis.jpg";
import audioFile from "./conocephalus_dorsalis_BD_001_APA.mp3";
import audioOscillogram from "./conocephalus_dorsalis_BD_001_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 43.319,
    audioDescription: (
      <>
        Ultraschalldetektor-Aufnahme. Der Gesang ist dem der <SpeciesLink to="conocephalus_fuscus" text="Langflügeligen Schwertschrecke" /> sehr ähnlich, doch mit regelmäßig eingebauten, deutlich anschwellenden Teilen. Diese erinnern an ein Gas gebendes Motorrad. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "C",
  daytime: "Tag/Nacht",
  months: "Juli – August",
  to: "conocephalus_dorsalis",
  german: "Kurzflügelige Schwertschrecke",
  latin: "Conocephalus dorsalis",
  family: "Laubheuschrecken (Tettigoniidae)",
  first: "31.9.1988, Wienflussbecken",
  districts: "3 (13., 14., 22.)",
  description: "Als in Wien sehr seltene Heuschreckenart ist die hygrophile und hochspezialisierte Kurzflügelige Schwertschrecke von nur zwei Standorten bekannt (Wienflussbecken und Obere Lobau).",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
