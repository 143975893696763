import React from "react";

import speciesImage from "./calliptamus_italicus.jpg";
import audioFile from "./calliptamus_italicus_001_APA.mp3";
import audioOscillogram from "./calliptamus_italicus_001_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 10.733,
    audioDescription: <>Balzlaute eines um ein Weibchen werbendes Männchen. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
  },
  audible: "D",
  daytime: "",
  months: "Juli – September",
  to: "calliptamus_italicus",
  german: "Italienische Schönschrecke",
  latin: "Calliptamus italicus",
  family: "Feldheuschrecken (Acrididae)",
  first: "um 1820, „Kahlengebirge“",
  districts: "21 (2.–5., 7.–23.)",
  description: "In den letzten Jahrzehnten nahmen die Bestände der Italienischen Schönschrecke deutlich zu, sodass sie mittlerweile auf Rohbodenstandorten in teils enormen Individuendichten auftritt.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
