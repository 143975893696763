import React from "react";

import speciesImage from "./tetrix_bolivari.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "E",
  daytime: "",
  months: "April – September",
  to: "tetrix_bolivari",
  german: "Bolivars Dornschrecke",
  latin: "Tetrix bolivari",
  family: "Dornschrecken (Tetrigidae)",
  first: "um 1880, „Wien“",
  districts: "4 (2., 13., 14., 17.)",
  description: <>Von dieser in den letzten zehn Jahren gut dokumentierten Dornschreckenart sind aktuell nur wenige Vorkommen aus Wien bekannt, die sich hauptsächlich im Westen der Stadt befinden. Gezieltes Nachsuchen in geeigneten Habitaten (v.&nbsp;a. an Gewässerufern mit Verlandungsbereichen) sollte aber weitere Fundpunkte bringen.</>,
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
