import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./isophya_camptoxypha.jpg";
import audioFile from "./isophya_camptoxypha_022_APA.mp3";
import audioOscillogram from "./isophya_camptoxypha_022_APA.png";
import audioFile2 from "./isophya_camptoxypha_BD_020_APA.mp3";
import audioOscillogram2 from "./isophya_camptoxypha_BD_020_APA.png";

const data = {
  released: true,
  audioData: [
    {
      audioFile: audioFile,
      audioOscillogram: audioOscillogram,
      audioDuration: 11.833266,
      audioDescription: (
        <>
          Der Gesang besteht aus rasch aneinandergereihten Folgen meist mit einem „Gegenton“ bzw. „Nachklick“. Er kann am ehesten mit der in Wien nicht vorkommenden Gemeinen Plumpschrecke (<i>Isophya kraussii</i>) verwechselt werden. Hintergrundstimme: <SpeciesLink to="gryllus_campestris" text="Feldgrille" after="." /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
        </>
      ),
    },
    {
      audioFile: audioFile2,
      audioOscillogram: audioOscillogram2,
      audioDuration: 17.580106,
      audioDescription: <>Ultraschalldetektor-Aufnahme. Tonaufnahme:&nbsp;Alexander&nbsp;Panrok</>,
    },
  ],
  audible: "C",
  daytime: "Nacht",
  months: "Juni – Juli",
  to: "isophya_camptoxypha",
  german: "Fiebers Plumpschrecke",
  latin: "Isophya camptoxypha",
  family: "Sichelschrecken (Phaneropteridae)",
  first: "um 1880, Neustift am Walde",
  districts: "4 (13., 14., 19., 23.)",
  description: "Die Wiener Vorkommen von Fiebers Plumpschrecke beschränken sich auf wenige Wiesen im Wienerwald. In erster Linie bewohnt sie hier extensive Mähwiesen mit später Mahd, Hochstaudenfluren und Krautsäume.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Werner Reitmeier",
};

export default data;
