import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./arcyptera_fusca.jpg";
import audioFile from "./grosse_hoeckerschrecke.mp3";
import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 8.422,
    audioDescription: (
      <>
        Der beeindruckende Gesang ist laut und unverkennbar. Er wird sowohl von Sitzwarten als auch während des Herumspazierens vorgetragen und erinnert stark an das Aufziehen und Losfahren eines aufziehbaren Spielzeugautos. Hintergrundstimmen: <SpeciesLink to="bicolorana_bicolor" text="Zweifarbige Beißschrecke" after="," /> <SpeciesLink to="pseudochorthippus_parallelus" text="Gemeiner Grashüpfer" after="," /> <SpeciesLink to="psophus_stridulus" text="Rotflügelige Schnarrschrecke" after="," /> <SpeciesLink to="decticus_verrucivorus" text="Warzenbeißer" after="." /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "A",
  daytime: "Tag",
  months: "Juni – August",
  to: "arcyptera_fusca",
  german: "Große Höckerschrecke",
  latin: "Arcyptera fusca",
  family: "Feldheuschrecken (Acrididae)",
  first: "um 1820, Dornbach",
  districts: "5 (13., 14., 16., 17., 23.)",
  description: "Als „bunt wie ein Papagei“ bezeich­nete der österreichische Entomologe Emil Hölzel die Große Höckerschrecke, die in der Tat eine der spektakulärsten Kurzfühlerschrecken Österreichs ist. Die anspruchsvolle Art wärmebegünstigter Magerwiesen ist in Wien inzwischen eine ausgesprochene Rarität und aktuell nur noch von sehr wenigen Wienerwaldwiesen gemeldet.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Günther Wöss",
};

export default data;
