import speciesImage from "./leptophyes_boscii.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "D",
  daytime: "Tag/Nacht",
  months: "Juli – August",
  to: "leptophyes_boscii",
  german: "Gelbstreifige Zartschrecke",
  latin: "Leptophyes boscii",
  family: "Sichelschrecken (Phaneropteridae)",
  first: "20.7.2015, nahe Scheiblingstein",
  districts: "1 (14.)",
  description: "Von der Gelbstreifigen Zartschrecke gibt es bis heute in Wien nur zwei Fundlokalitäten im Wienerwald, wo die Art in geringen Dichten auf Krautbeständen angetroffen wurde.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Werner Reitmeier",
};

export default data;
