import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./leptophyes_albovittata.jpg";
import audioFile from "./leptophyes_albovittata_001_Tag_Sekunde_2_und_8_APA.mp3";
import audioOscillogram from "./leptophyes_albovittata_001_Tag_Sekunde_2_und_8_APA.png";

const data = {
  released: true,
  audioData: {
    audioFile: audioFile,
    audioOscillogram: audioOscillogram,
    audioDuration: 9.325554,
    audioDescription: (
      <>
        Die kurzen, unauffälligen und extrem leisen Einzeltöne sind nur unter allerbesten Voraussetzungen hörbar (selbst mit Ultraschall-Detektor schwierig). In dieser Aufnahme ist die Gestreifte Zartschrecke nur bei Sekunde 2 und 8 zu hören, im Hintergrund hört man die <SpeciesLink to="pholidoptera_fallax" text="Südliche Strauchschrecke" after="." /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
      </>
    ),
  },
  audible: "D",
  daytime: "Tag/Nacht",
  months: "Juli – September",
  to: "leptophyes_albovittata",
  german: "Gestreifte Zartschrecke",
  latin: "Leptophyes albovittata",
  family: "Sichelschrecken (Phaneropteridae)",
  first: "August 1870, Hütteldorf",
  districts: "13 (2., 4., 10., 11., 13., 14., 16.–19., 21.–23.)",
  description: "Vor allem in den äußeren Bezirken Wiens findet man die Gestreifte Zartschrecke in Hochstaudenfluren und ähnlichen krautigen Flächen, wo sie sich oft regungslos auf Blättern und Blüten aufhält.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Alexander Panrok",
};

export default data;
