import React from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";

import "../css/content.scss";
import segelfalter from "../data/tagfalter/segelfalter.jpg";

import tagfalterBuch from "../data/buch_iiw_tagfalter_seitlich.jpg";

function Tagfalter(props) {
  return (
    <>
      <Header
        title="Insekten in Wien – Tagfalter"
        logoTextSmall="Insekten in Wien"
        logoTextLarge="Tagfalter"
        logoLink="/"
        links={[]}
      />
      <div className="content__container">
        <div className="content">
          <Link
            to="/"
            className="nav__button"
          >
            zur Buchreihe
          </Link>
          <img
            className="content__img-book"
            src={tagfalterBuch}
            alt=""
          />
          <h1>Insekten in Wien – Tagfalter</h1>
          <p>
            <b>Helmut&nbsp;Höttinger, Manfred&nbsp;Pendl, Martin&nbsp;Wiemers & Andreas&nbsp;Pospisil</b>
            <br />
            2013 • 349&nbsp;Seiten • Hardcover, 21&nbsp;×&nbsp;27&nbsp;cm
            <br />
            ISBN 978-3-9503548-0-5
            <br />
            <em>Vergriffen</em>
          </p>
          {/* <div className="nav__orderbutton-wrapper">
            <Link
              className="nav__orderbutton"
              to="/bestellung"
            >
              Buch bestellen
            </Link>
          </div> */}
          <p>
            In Wien wurden bisher 137 der 215 in Österreich nachgewiesenen Tagfalterarten nachgewiesen, im Zeitraum 1989 bis 2011 immerhin
            noch 105 Arten. Mehr als 33.000 Fundmeldungen wurden in einer Datenbank zusammengetragen und bilden die Grundlage für die
            ausführlichen Artsteckbriefe, Verbreitungskarten und Flugzeitdiagramme.
          </p>
          <p>
            Die Texte sind allgemeinverständlich verfasst und durch eine Vielzahl aussagekräftiger Farbfotos illustriert. Sie informieren
            über Erkennungsmerkmale, Verbreitung, besiedelte Lebensräume, Ökologie, Gefährdung und Schutz aller Arten.
          </p>
          <p>
            Besonderen Wert haben die vier Autoren auf konkret umsetzbare Vorschläge für Schutz- und Pflegemaßnahmen gelegt, die zum Teil
            auch auf kleinen Flächen (z.&nbsp;B. im eigenen Garten) verwirklicht werden können. Die Nennung von „Tagfalter-Hotspots“, also
            Gebieten mit hoher Artenvielfalt, regt zu eigenen Erkundungen bei Wanderungen und Exkursionen an.
          </p>
          <p>
            Das Buch ist für alle Naturinteressierte auch weit über das Stadtgebiet hinaus eine Fundgrube interessanter Informationen und
            eine Bereicherung jeder Bibliothek.
          </p>
          <figure>
            <img
              className="content__img content__img--labeled"
              src={segelfalter}
              alt="Segelfalter"
            />
            <figcaption className="content__img-text">
              Der auffällige Segelfalter ist in Wien weit verbreitet. Foto: Helmut Höttinger
            </figcaption>
          </figure>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Tagfalter;
