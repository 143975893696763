import speciesImage from "./xya_variegata.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "E",
  daytime: "",
  months: "März – Oktober",
  to: "xya_variegata",
  german: "Gefleckte Grabschrecke",
  latin: "Xya variegata",
  family: "Grabschrecken (Tridactylidae)",
  first: "3.7.1999, Neuessling",
  districts: "2 (21., 22.)",
  description: "Der erste Wiener Nachweis der Gefleckten Grabschrecke in einer Sandgrube am östlichen Stadtrand war zugleich der österreichische Erstnachweis. Seither wurde sie an drei weiteren Standorten in Wien gefunden, typischerweise immer an sandigen Gewässerufern.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Markus Sehnal",
};

export default data;
