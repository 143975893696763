import React from "react";
import SpeciesLink from "../../../components/SpeciesLink";

import speciesImage from "./tessellana_veyseli.jpg";
import audioFile from "./tessellana_veyseli_006_APA.mp3";
import audioOscillogram from "./tessellana_veyseli_006_APA.png";
import audioFile2 from "./tessellana_veyseli_BD_003_APA.mp3";
import audioOscillogram2 from "./tessellana_veyseli_BD_003_APA.png";

const data = {
  released: true,
  audioData: [
    {
      audioFile: audioFile,
      audioOscillogram: audioOscillogram,
      audioDuration: 13.27,
      audioDescription: (
        <>
          Die sehr leisen, unregelmäßig gereihten Serien von Kratzlauten sind fast nur mithilfe eines Ultraschall-Detektors zu hören (Aufnahme: Nachtgesang). Hintergrundstimmen: <SpeciesLink to="oecanthus_pellucens" text="Weinhähnchen" after="," /> <SpeciesLink to="tettigonia_viridissima" text="Grünes Heupferd" after="." /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
        </>
      ),
    },
    {
      audioFile: audioFile2,
      audioOscillogram: audioOscillogram2,
      audioDuration: 12.647,
      audioDescription: (
        <>
          Ultraschalldetektor-Aufnahme. Hintergrundstimme: <SpeciesLink to="platycleis_grisea" text="Graue Beißschrecke" after="." /> Tonaufnahme:&nbsp;Alexander&nbsp;Panrok
        </>
      ),
    },
  ],
  audible: "C",
  daytime: "Tag/Nacht",
  months: "Juli – August",
  to: "tessellana_veyseli",
  german: "Kleine Beißschrecke",
  latin: "Tessellana veyseli",
  family: "Laubheuschrecken (Tettigoniidae)",
  first: "um 1850, Laaer Berg",
  districts: "10 (10.–14., 17., 19., 21.–23.)",
  description: "Die Kleine Beißschrecke hat ihre beiden Wiener Verbreitungsschwerpunkte im Norden und Süden der Stadt. Sie bewohnt hier vor allem vertikal strukturierte Ruderalflächen und Brachen.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Markus Sehnal",
};

export default data;
