import React from "react";
import { Link } from "react-router-dom";

import Header from "./Header";
import Footer from "./Footer";
import SpeciesList from "./SpeciesList";
import SpeciesLink from "./SpeciesLink";

import "../css/content.scss";
import "../css/navigation.scss";

import chorthippus_biguttulus from "../data/heuschrecken/chorthippus_biguttulus.jpg";

function SpeciesListIntro(props) {
  return (
    <>
      <Header
        title="Die Heuschreckenarten Wiens – Insekten in Wien"
        logoTextSmall="Insekten in Wien"
        logoTextLarge="Heuschrecken"
        logoLink="/heuschrecken"
        links={[
          { to: "/heuschrecken/liste", active: false, text: "Arten" },
          { to: "/heuschrecken/rundwege", active: true, text: "Rundwege" },
        ]}
      />

      <div className="content__container">
        <div className="content">
          <Link to="/heuschrecken" className="nav__button">
            Buch-Übersicht
          </Link>
          <figure>
            <img className="content__img content__img--labeled" src={chorthippus_biguttulus} alt="Nachtigall-Grashüpfer" />
            <figcaption className="content__img-text">
              Nachtigall-Grashüpfer (<i>Chorthippus biguttulus</i>). Foto:&nbsp;Günther&nbsp;Wöss
            </figcaption>
          </figure>
          <h1>Die Heuschreckenarten Wiens</h1>
          <p>Mit 85 nachgewiesenen Arten beherbergt Wien etwa zwei Drittel der österreichischen Heuschreckenfauna oder vergleichsweise zehn Arten mehr als das größte deutsche Bundesland Bayern. Die Gründe für diese Artenvielfalt ergeben sich aus landschaftlichen und klimatischen Faktoren. </p>
          <p>So verfügt Wien für eine Großstadt über einen außergewöhnlich hohen und abwechslungsreichen Anteil an Naturräumen, wie z.&nbsp;B. den Wienerwald im Westen oder die Lobau als Teil des Nationalparks Donau-Auen im Osten. Zusätzlich fungieren die zahlreichen unverbauten Flächen von der „Gstett’n“ bis hin zum Park als wichtige, vernetzende Lebensräume, die für viele Arten als „Trittsteine“ von Bedeutung sind. </p>
          <p>
            Ein weiterer wesentlicher Faktor ergibt sich aus der besonderen geografischen Lage Wiens, die grob formuliert als Schnittstelle zwischen „Alpen und Steppe“ zu verstehen ist. So ist es in Wien z.&nbsp;B. möglich, eine Steppenart wie den <SpeciesLink to="stenobothrus_crassipes" text="Zwerg-Heidegrashüpfer" /> in unmittelbarer Nähe zur <SpeciesLink to="pholidoptera_aptera" text="Alpen-Strauchschrecke" /> zu finden.
          </p>
          <p>
            Wir wollen Ihnen hier in weiterer Folge alle in Wien vorkommenden Heuschreckenarten sowie die einzige Fangschreckenart (<SpeciesLink to="mantis_religiosa" text="Gottesanbeterin" after=")" /> in kurzen, charakteristischen Steckbriefen vorstellen. Zusätzlich kann man sich die artspezifischen Lautäußerungen der lautproduzierenden Arten anhören, was für die Artbestimmung sehr hilfreich sein kann.
          </p>
          <p>
            Die Gesänge der Heuschrecken sind ab dem Spätfrühling (<SpeciesLink to="gryllus_campestris" text="Feldgrille" after="," /> <SpeciesLink to="polysarcus_denticauda" text="Wanstschrecke" after="," /> Plumpschrecken) mit Schwerpunkt auf den Sommermonaten bis in den Frühherbst hinein zu fast allen Tages- und Nachtzeiten unsere akustischen Begleiter im Freien – sei es in Gärten und Parks, in der Innenstadt oder auf Wiesen. Da diese Lautäußerungen artspezifisch sind, können geschulte Ohren in kurzer Zeit einen Großteil des Arteninventars eines Lebensraumes erfassen, ohne optisch nach den Tieren suchen zu müssen. Der Hauptzweck ist das Anlocken von Weibchen durch den sogenannten Spontangesang der Männchen, doch gibt es auch andere Gesangstypen wie den Werbegesang oder den Rivalengesang.
          </p>
          <p>Etwa 75&nbsp;% der in Wien vorkommenden Arten können Gesänge produzieren, die je nach Spezies unterschiedlich laut sind und in manchen Fällen mit „freiem Ohr“ nicht mehr oder nur sehr vage wahrgenommen werden können. Mittels eines Ultraschall-Detektors (welcher üblicherweise für Fledermäuse verwendet wird), werden jedoch auch die meisten dieser Arten hör- und somit bestimmbar.</p>
          <h1>Artenliste</h1>
          <SpeciesList speciesIncluded={[]} familiesIncluded={[]} />
        </div>
      </div>
      <Footer />
    </>
  );
}

export default SpeciesListIntro;
