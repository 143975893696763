import speciesImage from "./tetrix_kraussi.jpg";
// import audioFile from "./song1.mp3";
// import audioOscillogram from "./osc1.png";

const data = {
  released: true,
  // audioData: {
  //   audioFile: audioFile,
  //   audioOscillogram: audioOscillogram,
  //   audioDuration: -9999999999,
  //   audioDescription: "",
  // },
  audible: "E",
  daytime: "",
  months: "März – September",
  to: "tetrix_kraussi",
  german: "Kurzflügel-Dornschrecke",
  latin: "Tetrix kraussi",
  family: "Dornschrecken (Tetrigidae)",
  first: "um 1900, Hütteldorf",
  districts: "4 (13., 14., 21., 23.)",
  description: "Die wenigen Wiener Fundpunkte der Kurzflügel-Dornschrecke befinden sich in trockenwarmen Hanglagen des Wienerwaldes, wo hauptsächlich Waldränder und Kahlschläge mit viel Offenboden bewohnt werden.",
  speciesImage: speciesImage,
  speciesImageAuthor: "Foto: Markus Sehnal",
};

export default data;
